import {
  GET_CUSTOMER_RECOMMENDATION_LIST_REQUEST,
  GET_CUSTOMER_RECOMMENDATION_LIST_SUCCESS,
  GET_CUSTOMER_RECOMMENDATION_LIST_FAILED,
  GET_CUSTOMER_RECOMMENDATION_DETAILS_REQUEST,
  GET_CUSTOMER_RECOMMENDATION_DETAILS_SUCCESS,
  GET_CUSTOMER_RECOMMENDATION_DETAILS_FAILED,
  GET_CUSTOMER_RECOMMENDATION_MENU_LIST_REQUEST,
  GET_CUSTOMER_RECOMMENDATION_MENU_LIST_SUCCESS,
  GET_CUSTOMER_RECOMMENDATION_MENU_LIST_FAILED,
  GET_CUSTOMER_RECOMMENDATION_MENU_DETAILS_REQUEST,
  GET_CUSTOMER_RECOMMENDATION_MENU_DETAILS_SUCCESS,
  GET_CUSTOMER_RECOMMENDATION_MENU_DETAILS_FAILED,
  GET_SERVICER_MATCHING_SCORE_LIST_REQUEST,
  GET_SERVICER_MATCHING_SCORE_LIST_SUCCESS,
  GET_SERVICER_MATCHING_SCORE_LIST_FAILED,
  GET_MENU_MATCHING_SCORE_LIST_REQUEST,
  GET_MENU_MATCHING_SCORE_LIST_SUCCESS,
  GET_MENU_MATCHING_SCORE_LIST_FAILED,
  GET_SERVICER_SEGMENTATION_LIST_REQUEST,
  GET_SERVICER_SEGMENTATION_LIST_SUCCESS,
  GET_SERVICER_SEGMENTATION_LIST_FAILED,
  GET_SERVICER_SEGMENTATION_DETAILS_REQUEST,
  GET_SERVICER_SEGMENTATION_DETAILS_SUCCESS,
  GET_SERVICER_SEGMENTATION_DETAILS_FAILED,
  GET_CUSTOMER_SEGMENTATION_LIST_REQUEST,
  GET_CUSTOMER_SEGMENTATION_LIST_SUCCESS,
  GET_CUSTOMER_SEGMENTATION_LIST_FAILED,
  GET_CUSTOMER_SEGMENTATION_DETAILS_REQUEST,
  GET_CUSTOMER_SEGMENTATION_DETAILS_SUCCESS,
  GET_CUSTOMER_SEGMENTATION_DETAILS_FAILED,
  GET_DISTRIBUTE_R_AND_F_REQUEST,
  GET_DISTRIBUTE_R_AND_F_SUCCESS,
  GET_DISTRIBUTE_R_AND_F_FAILED,
  GET_DISTRIBUTE_MONETARY_OVER_RF_REQUEST,
  GET_DISTRIBUTE_MONETARY_OVER_RF_SUCCESS,
  GET_DISTRIBUTE_MONETARY_OVER_RF_FAILED
} from './constants';

export const getCustomerRecommendationListRequest = (payload) => {
  return { type: GET_CUSTOMER_RECOMMENDATION_LIST_REQUEST, payload };
};

export const getCustomerRecommendationListSuccess = (payload) => {
  return { type: GET_CUSTOMER_RECOMMENDATION_LIST_SUCCESS, payload };
};

export const getCustomerRecommendationListFailed = () => {
  return { type: GET_CUSTOMER_RECOMMENDATION_LIST_FAILED };
};

export const getCustomerRecommendationDetailsRequest = (payload) => {
  return { type: GET_CUSTOMER_RECOMMENDATION_DETAILS_REQUEST, payload };
};

export const getCustomerRecommendationDetailsSuccess = (payload) => {
  return { type: GET_CUSTOMER_RECOMMENDATION_DETAILS_SUCCESS, payload };
};

export const getCustomerRecommendationDetailsFailed = () => {
  return { type: GET_CUSTOMER_RECOMMENDATION_DETAILS_FAILED };
};

export const getCustomerRecommendationMenuListRequest = (payload) => {
  return { type: GET_CUSTOMER_RECOMMENDATION_MENU_LIST_REQUEST, payload };
}

export const getCustomerRecommendationMenuListSuccess = (payload) => {
  return { type: GET_CUSTOMER_RECOMMENDATION_MENU_LIST_SUCCESS, payload };
}

export const getCustomerRecommendationMenuListFailed = () => {
  return { type: GET_CUSTOMER_RECOMMENDATION_MENU_LIST_FAILED };
}

export const getCustomerRecommendationMenuDetailsRequest = (payload) => {
  return { type: GET_CUSTOMER_RECOMMENDATION_MENU_DETAILS_REQUEST, payload };
}

export const getCustomerRecommendationMenuDetailsSuccess = (payload) => {
  return { type: GET_CUSTOMER_RECOMMENDATION_MENU_DETAILS_SUCCESS, payload };
}

export const getCustomerRecommendationMenuDetailsFailed = () => {
  return { type: GET_CUSTOMER_RECOMMENDATION_MENU_DETAILS_FAILED };
}

export const getServicerMatchingScoreListRequest = (payload) => {
  return { type: GET_SERVICER_MATCHING_SCORE_LIST_REQUEST, payload };
}

export const getServicerMatchingScoreListSuccess = (payload) => {
  return { type: GET_SERVICER_MATCHING_SCORE_LIST_SUCCESS, payload }
}

export const getServicerMatchingScoreListFailed = () => {
  return { type: GET_SERVICER_MATCHING_SCORE_LIST_FAILED };
}

export const getMenuMatchingScoreListRequest = (payload) => {
  return { type: GET_MENU_MATCHING_SCORE_LIST_REQUEST, payload };
}

export const getMenuMatchingScoreListSuccess = (payload) => {
  return { type: GET_MENU_MATCHING_SCORE_LIST_SUCCESS, payload };
}

export const getMenuMatchingScoreListFailed = () => {
  return { type: GET_MENU_MATCHING_SCORE_LIST_FAILED };
}

export const getServicerSegmentationListRequest = (payload) => {
  return { type: GET_SERVICER_SEGMENTATION_LIST_REQUEST, payload }
}

export const getServicerSegmentationListSuccess = (payload) => {
  return { type: GET_SERVICER_SEGMENTATION_LIST_SUCCESS, payload }
}

export const getServicerSegmentationListFailed = () => {
  return { type: GET_SERVICER_SEGMENTATION_LIST_FAILED }
}

export const getServicerSegmentationDetailsRequest = (payload) => {
  return { type: GET_SERVICER_SEGMENTATION_DETAILS_REQUEST, payload }
}

export const getServicerSegmentationDetailsSuccess = (payload) => {
  return { type: GET_SERVICER_SEGMENTATION_DETAILS_SUCCESS, payload }
}

export const getServicerSegmentationDetailsFailed = () => {
  return { type: GET_SERVICER_SEGMENTATION_DETAILS_FAILED }
}

export const getCustomerSegmentationListRequest = (payload) => {
  return { type: GET_CUSTOMER_SEGMENTATION_LIST_REQUEST, payload }
}

export const getCustomerSegmentationListSuccess = (payload) => {
  return { type: GET_CUSTOMER_SEGMENTATION_LIST_SUCCESS, payload }
}

export const getCustomerSegmentationListFailed = () => {
  return { type: GET_CUSTOMER_SEGMENTATION_LIST_FAILED }
}

export const getCustomerSegmentationDetailsRequest = (payload) => {
  return { type: GET_CUSTOMER_SEGMENTATION_DETAILS_REQUEST, payload }
}

export const getCustomerSegmentationDetailsSuccess = (payload) => {
  return { type: GET_CUSTOMER_SEGMENTATION_DETAILS_SUCCESS, payload }
}

export const getCustomerSegmentationDetailsFailed = () => {
  return { type: GET_CUSTOMER_SEGMENTATION_DETAILS_FAILED }
}

export const getDistributeRFRequest = (payload) => {
  return { type: GET_DISTRIBUTE_R_AND_F_REQUEST, payload }
}

export const getDistributeRFSuccess = (payload) => {
  return { type: GET_DISTRIBUTE_R_AND_F_SUCCESS, payload }
}

export const getDistributeRFFailed = () => {
  return { type: GET_DISTRIBUTE_R_AND_F_FAILED }
}

export const getDistributeMonetaryOverRFRequest = (payload) => {
  return { type: GET_DISTRIBUTE_MONETARY_OVER_RF_REQUEST, payload }
}

export const getDistributeMonetaryOverRFSuccess = (payload) => {
  return { type: GET_DISTRIBUTE_MONETARY_OVER_RF_SUCCESS, payload }
}

export const getDistributeMonetaryOverRFFailed = () => {
  return { type: GET_DISTRIBUTE_MONETARY_OVER_RF_FAILED }
}