import React from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import helper from 'utils/helpers';

const PublicRoutes = ({ Component, ...props }) => {

  const allowAcceptPrivateArr = [
    '/guest/user/invite'
  ];

  if (!helper.isGuest() && !allowAcceptPrivateArr.includes(window.location.pathname)) {
    return <Navigate to='/' />;
  }

  return (
    <Component {...props} />
  );
};

PublicRoutes.propTypes = {
  Component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired
};

export default PublicRoutes;
