import React, { forwardRef, useState, memo } from 'react';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import { Upload, Form, message } from 'antd';
import { LoadingOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { UploadIcon } from 'assets/images';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import Helper from 'utils/helpers';
import '../style.less';

const UploadImage = forwardRef(
  ({ label, type = 'ANNOUNCEMENT', className = '', ...props }, ref) => {
    const [field, meta, helper] = useField(props);
    const { smallSize, largeSize } = props;
    const isError = meta.error && meta.touched;
    const [isLoading, setIsloading] = useState(false);
    const image = (!_isEmpty(_get(field.value, 'url'))) ? [{ url: _get(field.value, 'url') }] : [];
    const beforeUpload = (file) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
      }
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        message.error('Image must smaller than 5MB!');
      }
      return isJpgOrPng && isLt5M;
    };

    const handleRemove = (file) => {
      helper.setValue(undefined);
    };
    const handleChange = image => {
      helper.setValue({ ...image, url: image.payload.large });
    };

    const toBase64 = file => new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
      });

    const customRequest = async option => {
      const { file } = option;
        let imageContent = await toBase64(file);
        if (imageContent.indexOf(",")) {
            const data = imageContent.split(',');
            imageContent = data[1];
        }
      setIsloading(true);
        const resData = {
            type: type,
            mimeType: file.type,
            fileName: file.name,
            smallSize: smallSize,
            largeSize: largeSize,
            fileContent: imageContent,
        };
      const image = await Helper.uploadFile(resData);
      if (image) {
        setIsloading(false);
        handleChange(image);
      }
    };
    return (
      <div className={`input-container upload-image-container ${className}`}>
        <Form.Item
          label={label}
          validateStatus={isError ? 'error' : ''}
          help={(
            <div className="helper-wrapper">
              <div className="error-text">
                {isError && <><InfoCircleOutlined className="info-icon" type="info-circle" />{meta.error}</>}
              </div>
            </div>
          )}
        >
            <Upload
              listType="picture-card"
              fileList={image}
              showUploadList={false}
              beforeUpload={beforeUpload}
              onRemove={handleRemove}
              customRequest={customRequest}
            >
              {isLoading ? <LoadingOutlined /> : (
                !_isEmpty(image)
                  ? (image.map((img) => <img className="uploaded-image" key={img.url} alt="" src={img.url} />))
                  : <img src={UploadIcon} alt="" />
              )}
            </Upload>
        </Form.Item>
      </div>
    );
  },
);

UploadImage.displayName = 'UploadImage';
UploadImage.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string
};

UploadImage.defaultProps = {
  smallSize: "220x160",
  largeSize: "768x500"
};

export default memo(UploadImage);

