import { put, takeLeading, call } from 'redux-saga/effects';
import _ from 'lodash';

import helpers from 'utils/helpers';
import request from 'utils/api';
import { safe } from 'providers/GeneralProvider/saga';
import {
  GET_BIG_QUERY_CREATE_JOB_REQUEST,
  GET_BIG_QUERY_VIEWS_LIST_REQUEST
} from './constants';
import {
  getBigQueryCreateJobSuccess,
  getBigQueryCreateJobFailed,
  getBigQueryViewListSuccess,
  getBigQueryViewListFailed
} from './actions';

export function* handleBigQueryCreateJobRequest(input) {
  const requestBody = helpers.deepCloneObject(_.get(input, 'payload', {}));

  const result = yield call(request, '/bigquery/createJob', 'post', requestBody);
  yield put(getBigQueryCreateJobSuccess(_.get(result, 'payload')));
}

export function* onGetBigQueryCreateJobMError(error) {
  yield put(getBigQueryCreateJobFailed(error))
}


export function* handleBigQueryGetViewListRequest(input) {
  const requestBody = helpers.deepCloneObject(_.get(input, 'payload', {}));

  const result = yield call(request, '/bigquery/views', 'post', requestBody);
  yield put(getBigQueryViewListSuccess(_.get(result, 'payload')));
}

export function* onGetBigQueryGetViewListMError(error) {
  yield put(getBigQueryViewListFailed(error))
}

export default function* watchBigQuery() {
 
  yield takeLeading (
    GET_BIG_QUERY_CREATE_JOB_REQUEST,
    safe(handleBigQueryCreateJobRequest, onGetBigQueryCreateJobMError)
  )

  yield takeLeading (
    GET_BIG_QUERY_VIEWS_LIST_REQUEST,
    safe(handleBigQueryGetViewListRequest, onGetBigQueryGetViewListMError)
  )
}
