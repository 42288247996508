import React, { forwardRef, memo, useState } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { Input, Form, InputNumber, Checkbox } from 'antd';
import MaskedInput from 'antd-mask-input';
import { InfoCircleOutlined } from '@ant-design/icons';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import './style.less';
import constants from 'utils/constants';


const formItemLayout = {
  labelCol: {
    sm: { span: 6 }
  },
  wrapperCol: {
    sm: {
      span: 17,
      offset: 1
    }
  }
};

const phoneInputStyle = {
  large: {
    height: 40,
    width: '100%',
    fontSize: constants.FONT_SIZE,
    borderRadius: 2
  },
  medium: {
    height: 33,
    width: '100%',
    fontSize: constants.FONT_SIZE,
    borderRadius: 2
  }
};

const TextInput = forwardRef(
  ({
    label,
    maxLength,
    min,
    inputType,
    size = 'large',
    allowClear = true,
    className = '',
    vertical = true,
    message = '',
    description,
    ...props
  }, ref) => {
    const [field, meta, helper] = useField(props);
    const [focus, setFocus] = useState(false);
    const isError = ((meta.touched || focus) && meta.error) || message;
    const renderInput = () => {
      if (inputType === 'password') {
        return <Input.Password
          maxLength={maxLength}
          size={size}
          allowClear={allowClear}
          ref={ref}
          {...field}
          {...props}
        />;
      } else if (inputType === 'textarea') {
        return (
          <Input.TextArea
            maxLength={maxLength}
            allowClear={allowClear}
            {...field}
            {...props}
            ref={ref}
            onChange={(e) => helper.setValue(e.target.value)}
          />
        );
      } else if (inputType === 'search') {
        return <Input.Search size={size} maxLength={maxLength} {...field} {...props} ref={ref} />;
      } else if (inputType === 'maskInput') {
        return <MaskedInput maxLength={maxLength} {...field} {...props} ref={ref} style={{ height: '40px' }} />;
      } else if (inputType === 'number') {
        return <InputNumber min={min} size={size} {...props} ref={ref} onChange={data => helper.setValue(data)}
          style={{ width: '100%', fontSize: constants.FONT_SIZE }} />;
      } else if (inputType === 'phone') {
        return <PhoneInput
          country={'jp'}
          inputStyle={{ ...phoneInputStyle[size] }}
          {...field}
          onChange={data => helper.setValue(data)}
          onFocus={() => setFocus(true)}
          isValid={isError ? false : true}
        />;
      }
      return <Input maxLength={maxLength} size={size} allowClear={allowClear} {...field} {...props} ref={ref} />;
    };
    return (
      <div className={`input-field ${className} ${vertical ? 'vertical' : ''}`}>
        <Form.Item
          {...(!vertical ? formItemLayout : {})}
          label={label}
          validateStatus={isError ? 'error' : ''}
          help={(
            <>
              <div className="helper-wrapper">
                {isError &&
                  <div className="error-text">
                    <InfoCircleOutlined className="info-icon" type="info-circle" />
                    {`${meta.error || message}`}
                  </div>}
                {/* {maxLength && <div className="max-length-text">{_get(field.value, 'length', 0)}/{maxLength}</div>} */}
              </div>
              <p className='explain'>
                {description}
              </p>
            </>
          )}
        >
          {renderInput()}
        </Form.Item>
      </div >
    );
  },
);
TextInput.displayName = 'TextInput';

TextInput.propTypes = {
  label: PropTypes.string,
  maxLength: PropTypes.number,
  min: PropTypes.number,
  inputType: PropTypes.string,
  size: PropTypes.string,
  allowClear: PropTypes.bool,
  className: PropTypes.string,
  vertical: PropTypes.bool,
  message: PropTypes.string,
  description: PropTypes.string
};

export default memo(TextInput);
