import {
  GET_BIG_QUERY_CREATE_JOB_REQUEST,
  GET_BIG_QUERY_CREATE_JOB_SUCCESS,
  GET_BIG_QUERY_CREATE_JOB_FAILED,
  GET_BIG_QUERY_VIEWS_LIST_REQUEST,
  GET_BIG_QUERY_VIEWS_LIST_SUCCESS,
  GET_BIG_QUERY_VIEWS_LIST_FAILED
} from './constants';

export const getBigQueryCreateJobRequest = (payload) => {
  return { type: GET_BIG_QUERY_CREATE_JOB_REQUEST, payload };
};

export const getBigQueryCreateJobSuccess = (payload) => {
  return { type: GET_BIG_QUERY_CREATE_JOB_SUCCESS, payload };
};

export const getBigQueryCreateJobFailed = (payload) => {
  return { type: GET_BIG_QUERY_CREATE_JOB_FAILED, payload };
};

export const getBigQueryViewListRequest = (payload) => {
  return { type: GET_BIG_QUERY_VIEWS_LIST_REQUEST, payload };
};

export const getBigQueryViewListSuccess = (payload) => {
  return { type: GET_BIG_QUERY_VIEWS_LIST_SUCCESS, payload };
};

export const getBigQueryViewListFailed = (payload) => {
  return { type: GET_BIG_QUERY_VIEWS_LIST_FAILED, payload };
};