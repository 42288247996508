import axios from 'axios';
import _ from 'lodash';
import i18n from 'i18n';

import helpers from './helpers';
import { API_URL } from './config';
import constants from 'utils/constants';
import {
  Warning
} from 'components/common/Helper';

const getAxiosInstance = async(isDownload) => {
  const token = helpers.getAuthToken() || helpers.getGuestToken();
  const refreshToken = helpers.getRefreshToken();
  const headers = {
    ...(token ? { Authorization: `Bearer ${token}` } : {}),
    ...(refreshToken ? { 'x-refresh-token': refreshToken } : {}),
    'Accept-Language': helpers.getLocale()
  };

  const axiosInstance = axios.create({
    baseURL: API_URL,
    headers
  });

  axiosInstance.interceptors.response.use(
    (response) => {
      if (!window.location.pathname.includes('/login')
        && (parseInt(_.get(response, 'data.code', 0)) === 401)) {
        const responseStatus = _.get(response, 'data.payload.status');
        switch (responseStatus) {
          case constants.LOGIN_STATUS.DELETED:
            helpers.clearLocalStorage();
            window.location = '/guest/user/unavailable';
            return null;
          case constants.LOGIN_STATUS.PRODUCT_NOT_FOUND:
            Warning({
              title: i18n.t('content.user.userDeleteByProduct', { product: helpers.getProductName() }),
              onOk: () => {
                window.location = '/';
              }
            });
            return null;
          default:
            Warning({
              title: i18n.t('apiResponseMessage.invalidRequest', { product: helpers.getProductName() }),
              onOk: () => {
                window.location = '/';
              }
            });
            return null;
        }
      }

      const arr = [200, 201];
      if (arr.indexOf(response.status) !== -1) {
        if (isDownload) {
          return response;
        }
        return response.data;
      }

      return Promise.reject(response);
    },
    (error) => {
      if (_.get(error, 'response.status') === 401) {
        helpers.clearLocalStorage();
        window.location = '/';
      }
      if (error.message && error.message !== 'Network Error') {
        const code = _.get(error, 'response.data.code', null);
        const statusText = _.get(error, 'response.statusText');
        if (code) {
          const errorObj = _.get(error, 'response.data', {});
          return Promise.reject(errorObj);
        }
        return Promise.reject(statusText || i18n.t('message.errorUndefined'));
      } else {
        return Promise.reject(i18n.t('message.noNetwork'));
      }
    },
  );

  return axiosInstance;
};

const request = async(url, method = 'get', data = {}, options = {}, isDownload) => {
  try {
    const API = await getAxiosInstance(isDownload);
    _.set(options, 'params.lang', helpers.getLocale());
    _.set(options, 'params.product', helpers.getProductId());

    if (!['post', 'put', 'patch'].includes(method)) {
      return API[method](url, options);
    }
    return API[method](url, data, options);
  } catch (error) {
    return Promise.reject(error);
  }
};

export default request;
