import {
  CHECK_AUTH0_INFO_REQUEST,
  CHECK_AUTH0_INFO_SUCCESS,
  CHECK_AUTH0_INFO_FAILED,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILED
} from './constants';

export const checkAuth0InfoRequest = (payload) => {
  return { type: CHECK_AUTH0_INFO_REQUEST, payload };
};

export const checkAuth0InfoSuccess = (payload) => {
  return { type: CHECK_AUTH0_INFO_SUCCESS, payload };
};

export const checkAuth0InfoFailed = () => {
  return { type: CHECK_AUTH0_INFO_FAILED };
};

export const logoutRequest = (payload) => {
  return { type: LOGOUT_REQUEST, payload };
};

export const logoutSuccess = (payload) => {
  return { type: LOGOUT_SUCCESS, payload };
};

export const logoutFailed = () => {
  return { type: LOGOUT_FAILED };
};