export const GET_POINT_API_CONFIG_SECRET_KEY_REQUEST = '@PointApi/GET_POINT_API_CONFIG_SECRET_KEY_REQUEST';
export const GET_POINT_API_CONFIG_SECRET_KEY_SUCCESS = '@PointApi/GET_POINT_API_CONFIG_SECRET_KEY_SUCCESS';
export const GET_POINT_API_CONFIG_SECRET_KEY_FAILED = '@PointApi/GET_POINT_API_CONFIG_SECRET_KEY_FAILED';

export const GET_POINT_API_EXPIRY_DURATION_REQUEST = '@PointApi/GET_POINT_API_EXPIRY_DURATION_REQUEST';
export const GET_POINT_API_EXPIRY_DURATION_SUCCESS = '@PointApi/GET_POINT_API_EXPIRY_DURATION_SUCCESS';
export const GET_POINT_API_EXPIRY_DURATION_FAILED = '@PointApi/GET_POINT_API_EXPIRY_DURATION_FAILED';

export const UPDATE_POINT_API_EXPIRY_DURATION_REQUEST = '@PointApi/UPDATE_POINT_API_EXPIRY_DURATION_REQUEST';
export const UPDATE_POINT_API_EXPIRY_DURATION_SUCCESS = '@PointApi/UPDATE_POINT_API_EXPIRY_DURATION_SUCCESS';
export const UPDATE_POINT_API_EXPIRY_DURATION_FAILED = '@PointApi/UPDATE_POINT_API_EXPIRY_DURATION_FAILED';

export const GET_POINT_API_EXCHANGE_RATE_REQUEST = '@PointApi/GET_POINT_API_EXCHANGE_RATE_REQUEST';
export const GET_POINT_API_EXCHANGE_RATE_SUCCESS = '@PointApi/GET_POINT_API_EXCHANGE_RATE_SUCCESS';
export const GET_POINT_API_EXCHANGE_RATE_FAILED = '@PointApi/GET_POINT_API_EXCHANGE_RATE_FAILED';

export const UPDATE_POINT_API_EXCHANGE_RATE_REQUEST = '@PointApi/UPDATE_POINT_API_EXCHANGE_RATE_REQUEST';
export const UPDATE_POINT_API_EXCHANGE_RATE_SUCCESS = '@PointApi/UPDATE_POINT_API_EXCHANGE_RATE_SUCCESS';
export const UPDATE_POINT_API_EXCHANGE_RATE_FAILED = '@PointApi/UPDATE_POINT_API_EXCHANGE_RATE_FAILED';

export const GET_POINT_API_TRANSFER_RATE_REQUEST = '@PointApi/GET_POINT_API_TRANSFER_RATE_REQUEST';
export const GET_POINT_API_TRANSFER_RATE_SUCCESS = '@PointApi/GET_POINT_API_TRANSFER_RATE_SUCCESS';
export const GET_POINT_API_TRANSFER_RATE_FAILED = '@PointApi/GET_POINT_API_TRANSFER_RATE_FAILED';

export const UPDATE_POINT_API_TRANSFER_RATE_REQUEST = '@PointApi/UPDATE_POINT_TRANSFER_RATE_REQUEST';
export const UPDATE_POINT_API_TRANSFER_RATE_SUCCESS = '@PointApi/UPDATE_POINT_API_TRANSFER_RATE_SUCCESS';
export const UPDATE_POINT_API_TRANSFER_RATE_FAILED = '@PointApi/UPDATE_POINT_API_TRANSFER_RATE_FAILED';

export const GET_POINT_API_TOTAL_POINT_SEARCH_REQUEST = '@PointApi/GET_POINT_API_TOTAL_POINT_SEARCH_REQUEST';
export const GET_POINT_API_TOTAL_POINT_SEARCH_SUCCESS = '@PointApi/GET_POINT_API_TOTAL_POINT_SEARCH_SUCCESS';
export const GET_POINT_API_TOTAL_POINT_SEARCH_FAILED = '@PointApi/GET_POINT_API_TOTAL_POINT_SEARCH_FAILED';

export const GET_POINT_API_POINT_HISTORY_SEARCH_REQUEST = '@PointApi/GET_POINT_API_POINT_HISTORY_SEARCH_REQUEST';
export const GET_POINT_API_POINT_HISTORY_SEACRH_SUCCESS = '@PointApi/GET_POINT_API_POINT_HISTORY_SEACRH_SUCCESS';
export const GET_POINT_API_POINT_HISTORY_SEARCH_FAILED = '@PointApi/GET_POINT_API_POINT_HISTORY_SEARCH_FAILED';

export const GET_POINT_API_CSV_TOTAL_POINT_DAILY_REPORT_REQUEST = '@PointApi/GET_POINT_API_CSV_TOTAL_POINT_DAILY_REPORT_REQUEST';
export const GET_POINT_API_CSV_TOTAL_POINT_DAILY_REPORT_SUCCESS = '@PointApi/GET_POINT_API_CSV_TOTAL_POINT_DAILY_REPORT_SUCCESS';
export const GET_POINT_API_CSV_TOTAL_POINT_DAILY_REPORT_FAILED = '@PointApi/GET_POINT_API_CSV_TOTAL_POINT_DAILY_REPORT_FAILED';

export const GET_POINT_API_CSV_TOTAL_POINT_DAILY_DOWNLOAD_REQUEST = '@PointApi/GET_POINT_API_CSV_TOTAL_POINT_DAILY_DOWNLOAD_REQUEST';
export const GET_POINT_API_CSV_TOTAL_POINT_DAILY_DOWNLOAD_SUCCESS = '@PointApi/GET_POINT_API_CSV_TOTAL_POINT_DAILY_DOWNLOAD_SUCCESS';
export const GET_POINT_API_CSV_TOTAL_POINT_DAILY_DOWNLOAD_FAILED = '@PointApi/GET_POINT_API_CSV_TOTAL_POINT_DAILY_DOWNLOAD_FAILED';
