import cryptoJS from 'crypto-js';

const secret = process.env.REACT_APP_KEY;

export default {
  decryptAES: (cipherText, key = secret) => {
    return cryptoJS.AES.decrypt(cipherText, key).toString(cryptoJS.enc.Utf8);
  },

  encryptAES: (cipherText, key = secret) => {
    return cryptoJS.AES.encrypt(cipherText.toString(), key);
  }
};