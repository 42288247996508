import produce from 'immer';
import _ from 'lodash';

import {
  ERROR,
  RESET_SEND_SCHEDULE,
  RESET_TARGET_TEMPLATE_RESULT,
  RESET_TARGER_LIST_TEMPLATE,
  GET_TARGER_LIST_TEMPLATE_REQUEST,
  GET_TARGER_LIST_TEMPLATE_SUCCESS,
  GET_TARGER_LIST_TEMPLATE_FAILED,
  RUN_TARGET_TEMPLATE_REQUEST,
  RUN_TARGET_TEMPLATE_SUCCESS,
  RUN_TARGET_TEMPLATE_FAILED,
  GET_FILTER_VALUE_REQUEST,
  GET_FILTER_VALUE_SUCCESS,
  SAVE_TARGET_REQUEST,
  SAVE_TARGET_SUCCESS,
  SAVE_TARGET_FAILED,
  EXPORT_REQUEST,
  EXPORT_SUCCESS,
  EXPORT_FAILED,
  GET_TARGET_LIST_SPOOL_LOG_REQUEST,
  GET_TARGET_LIST_SPOOL_LOG_SUCCESS,
  GET_TARGET_LIST_SPOOLED_REQUEST,
  GET_TARGET_LIST_SPOOLED_SUCCESS,
  GET_TARGET_LIST_SPOOLED_FAILED,
  EXPORT_TARGET_SPOOL_LOG_DETAIL_REQUEST,
  EXPORT_TARGET_SPOOL_LOG_DETAIL_SUCCESS,
  EXPORT_TARGET_SPOOL_LOG_DETAIL_FAILED,
  EXPORT_TARGET_SPOOL_LOG_LIST_FAILED,
  EXPORT_TARGET_SPOOL_LOG_LIST_REQUEST,
  EXPORT_TARGET_SPOOL_LOG_LIST_SUCCESS,
  CREATE_NEW_SENDER_REQUEST,
  CREATE_NEW_SENDER_SUCCESS,
  CREATE_NEW_SENDER_FAILED,
  GET_SENDER_LIST_REQUEST,
  GET_SENDER_LIST_SUCCESS,
  GET_SENDER_LIST_FAILED,
  DELETE_SENDER_REQUEST,
  DELETE_SENDER_SUCCESS,
  DELETE_SENDER_FAILED,
  CREATE_SINGLE_SEND_REQUEST,
  CREATE_SINGLE_SEND_SUCCESS,
  CREATE_SINGLE_SEND_FAILED,
  GET_SENDER_SELECT_LIST_SUCCESS,
  GET_SINGLE_SEND_LIST_REQUEST,
  GET_SINGLE_SEND_LIST_SUCCESS,
  GET_SINGLE_SEND_LIST_FAILED,
  DELETE_SINGLE_SEND_REQUEST,
  DELETE_SINGLE_SEND_SUCCESS,
  DELETE_SINGLE_SEND_FAILED,
  GET_TARGET_LIST_EMAIL_TEMPLATE_REQUEST,
  GET_TARGET_LIST_EMAIL_TEMPLATE_SUCCESS,
  CREATE_EMAIL_TEMPLATE_REQUEST,
  CREATE_EMAIL_TEMPLATE_SUCCESS,
  CREATE_EMAIL_TEMPLATE_FAILED,
  UPDATE_EMAIL_TEMPLATE_REQUEST,
  UPDATE_EMAIL_TEMPLATE_SUCCESS,
  DELETE_EMAIL_TEMPLATE_REQUEST,
  CANCEL_LOADING_TABLE_EMAIL_TEMPLATE,
  GET_DETAIL_EMAIL_TEMPLATE_SUCCESS,
  GET_DETAIL_EMAIL_TEMPLATE_SCHEDULE_SUCCESS,
  GET_COPY_EMAIL_TEMPLATE_SUCCESS,
  GET_EDIT_EMAIL_TEMPLATE_SUCCESS,
  GET_DETAIL_EMAIL_TEMPLATE_REQUEST,
  RESET_EMAIL_DETAIL_TEMPLATE,
  GET_SEND_SCHEDULE_LIST_REQUEST,
  GET_SEND_SCHEDULE_LIST_SUCCESS,
  CREATE_SEND_SCHEDULE_REQUEST,
  CREATE_SEND_SCHEDULE_SUCCESS,
  GET_DETAIL_SEND_SCHEDULE_REQUEST,
  GET_COPY_SEND_SCHEDULE_SUCCESS,
  GET_DETAIL_SEND_SCHEDULE_SUCCESS,
  CREATE_SEND_SCHEDULE_FAILED,
  RESET_EMAIL_DETAIL_TEMPLATE_SCHEDULE
} from './constants';

const initialState = {
  isLoading: false,
  targetListTemplate: [],
  targetTemplateResult: {
    loading: false,
    data: [],
    pagination: {},
    allowedFilterFields: [],
    byRunSearch: false,
    extra_conditions: []
  },
  filterOptionsByKey: {},
  targetSave: {},
  logId: null,
  exportTargetList: {
    loading: false,
    exported: false
  },
  targetListSpoolLog: {
    pagination: {},
    data: []
  },
  targetListSpooled: {
    loading: false,
    pagination: {},
    data: []
  },
  sendScheduleList:{
    loading: false,
    pagination: {},
    data: []
  },
  targetListEmailTemplate: {
    loading: false,
    pagination: {},
    data: []
  },
  detailEmailTemplate: {
    data: {},
    type: ''
  },
  detailEmailTemplateSchedule: {
    data: {},
    type: ''
  },
  detailSendSchedule: {
    data: {},
    type: ''
  },
  exportTargetLogList: {
    loading: false
  },
  exportTargetLogDetail: {
    loading: false
  },
  createNewSender: {
    loading: false,
    created: false,
    data: {},
    errors: {}
  },
  senderList: {
    loading: false,
    data: [],
    pagination: {},
    error: false
  },
  senderDelete: {
    deleted: false,
    loading: false,
    data: {}
  },
  createSingleSend: {
    loading: false,
    created: false,
    data: {},
    error: {}
  },
  createEmailTemplate: {
    loading: false,
    created: false,
    data: {},
    error: {}
  },
  createSendSchedule: {
    loading: false,
    created: false,
    data: {},
    error: {}
  },
  actionEvent:{
    loading: false
  },
  senderSelectList: [],
  singleSendList: {
    loading: false,
    pagination: {},
    data: []
  },
  singleSendDelete: {
    deleted: false,
    loading: false,
    deleting_id: null
  }
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {

    case ERROR:
      state.isLoading = false;
      return state;

    case GET_TARGER_LIST_TEMPLATE_REQUEST:
      state.isLoading = true;
      state.targetListTemplate = [];
      state.targetTemplateResult = {
        loading: false,
        data: null,
        pagination: {},
        allowedFilterFields: [],
        byRunSearch: false
      };
      state.exportTargetList.exported = false;
      return state;

    case GET_TARGER_LIST_TEMPLATE_SUCCESS:
      state.isLoading = false;
      state.targetListTemplate = action.payload;
      return state;
    case RESET_TARGER_LIST_TEMPLATE:
      state.targetListTemplate = [];
      return state;
    case RESET_SEND_SCHEDULE:
      state.detailSendSchedule = {
        data: {},
        type: ''
      }
      return state;

    case RESET_TARGET_TEMPLATE_RESULT:
      state.targetTemplateResult = {}
      return state;

    case GET_DETAIL_SEND_SCHEDULE_REQUEST:
      state.actionEvent = {loading: true};
      return state;

   case GET_COPY_SEND_SCHEDULE_SUCCESS:
      state.detailSendSchedule.data = action.payload;
      state.detailSendSchedule.type = 'copy';
      state.actionEvent = {loading: false};
      return state;
   case GET_DETAIL_SEND_SCHEDULE_SUCCESS:
      state.detailSendSchedule.data = action.payload;
      state.detailSendSchedule.type = 'detail';
      state.actionEvent = {loading: false};
      return state;

    case GET_EDIT_EMAIL_TEMPLATE_SUCCESS:
      state.detailEmailTemplate.data = action.payload;
      state.detailEmailTemplate.type = 'edit';
      state.actionEvent = {loading: false};
      return state;

    case GET_COPY_EMAIL_TEMPLATE_SUCCESS:
      state.detailEmailTemplate.data = action.payload;
      state.detailEmailTemplate.type = 'copy';
      state.actionEvent = {loading: false};
      return state;

    case RESET_EMAIL_DETAIL_TEMPLATE:
      state.detailEmailTemplate = {}
      return state;
    case RESET_EMAIL_DETAIL_TEMPLATE_SCHEDULE:
      state.detailEmailTemplateSchedule = {}
      return state;

    case GET_DETAIL_EMAIL_TEMPLATE_SUCCESS:
      state.detailEmailTemplate.data = action.payload;
      state.detailEmailTemplate.type = 'detail';
      state.actionEvent = {loading: false};
      return state;

    case GET_DETAIL_EMAIL_TEMPLATE_SCHEDULE_SUCCESS:
      state.detailEmailTemplateSchedule.data = action.payload;
      state.detailEmailTemplateSchedule.type = 'detail';
      state.actionEvent = {loading: false};
      return state;

    case GET_TARGER_LIST_TEMPLATE_FAILED:
      state.isLoading = false;
      return state;

    case RUN_TARGET_TEMPLATE_REQUEST:
      if (_.get(action, 'payload.isRunSearch')) {
        state.filterOptionsByKey = {};
      }

      state.targetSave = {};
      state.exportTargetList = {
        exported: false,
        loading: false
      };
      Object.assign(state.targetTemplateResult, {
        ...state.targetTemplateResult,
        loading: true,
        data: _.get(action, 'payload.isRunSearch') ? null : [],
        pagination: {},
        allowedFilterFields: [],
        byRunSearch: _.get(action, 'payload.isRunSearch'),
        typeCase:'request'
      });
      return state;

    case RUN_TARGET_TEMPLATE_SUCCESS:
      Object.assign(state.targetTemplateResult, {
        ...state.targetTemplateResult,
        loading: false,
        allowedFilterFields: _.filter(_.get(action, 'payload.allowed_filter_fields', []), item => item !== 'email'),
        data: _.get(action, 'payload.data', state.targetTemplateResult.byRunSearch ? null : []),
        pagination: {
          total: _.get(action, 'payload.total_items'),
          page: _.get(action, 'payload.current_page')
        },
        typeCase:'success',
        extra_conditions: _.get(action, 'payload.extra_conditions')
      });
      state.targetSave = {
        allowSave: !_.isEmpty(_.get(action, 'payload.data', []))
      };
      return state;

    case RUN_TARGET_TEMPLATE_FAILED:
      Object.assign(state.targetTemplateResult, {
        ...state.targetTemplateResult,
        loading: false,
        typeCase:'failed'
      });
      return state;

    case GET_FILTER_VALUE_REQUEST:
      state.filterOptionsByKey = [];
      return state;

    case GET_FILTER_VALUE_SUCCESS:
      state.filterOptionsByKey = _.get(action, 'payload', []);
      return state;

    case SAVE_TARGET_REQUEST:
      state.targetSave.loading = true;
      return state;

    case SAVE_TARGET_SUCCESS:
      state.targetSave = _.get(action, 'payload', {});
      state.logId = _.get(action, 'payload.id');
      return state;

    case SAVE_TARGET_FAILED:
      state.targetSave = _.get(action, 'payload', {});
      return state;

    case EXPORT_REQUEST:
      state.exportTargetList = {
        exported: false,
        loading: true
      };
      return state;

    case EXPORT_SUCCESS:
      state.exportTargetList = {
        exported: true,
        loading: false
      };
      state.targetSave = {};
      return state;

    case EXPORT_FAILED:
      state.exportTargetList = {
        exported: false,
        loading: false
      };
      return state;

    case GET_TARGET_LIST_SPOOL_LOG_REQUEST:
      state.isLoading = true;
      state.targetListSpoolLog = {
        pagination: {},
        data: []
      };
      return state;

    case GET_TARGET_LIST_SPOOL_LOG_SUCCESS:
      state.isLoading = false;
      state.targetListSpoolLog = {
        data: _.get(action, 'payload.data', []),
        pagination: _.get(action, 'payload.pagination', {})
      };
      return state;

    case GET_TARGET_LIST_SPOOLED_REQUEST:
      state.targetListSpooled = {
        loading: true,
        pagination: {},
        data: []
      };
      return state;

    case GET_TARGET_LIST_SPOOLED_SUCCESS:
      state.targetListSpooled = {
        loading: false,
        pagination: _.get(action, 'payload.pagination', {}),
        data: _.get(action, 'payload.data', [])
      };
      return state;

    case GET_TARGET_LIST_SPOOLED_FAILED:
      _.set(state, 'targetListSpooled.loading', false);
      return state;

    case EXPORT_TARGET_SPOOL_LOG_LIST_REQUEST:
      _.set(state, 'exportTargetLogList.loading', true);
      return state;

    case EXPORT_TARGET_SPOOL_LOG_LIST_SUCCESS:
      _.set(state, 'exportTargetLogList.loading', false);
      return state;

    case EXPORT_TARGET_SPOOL_LOG_LIST_FAILED:
      _.set(state, 'exportTargetLogList.loading', false);
      return state;

    case EXPORT_TARGET_SPOOL_LOG_DETAIL_REQUEST:
      _.set(state, 'exportTargetLogDetail.loading', true);
      return state;

    case EXPORT_TARGET_SPOOL_LOG_DETAIL_SUCCESS:
      _.set(state, 'exportTargetLogDetail.loading', false);
      return state;

    case EXPORT_TARGET_SPOOL_LOG_DETAIL_FAILED:
      _.set(state, 'exportTargetLogDetail.loading', false);
      return state;

    case CREATE_NEW_SENDER_REQUEST:
      state.createNewSender.loading = true;
      state.createNewSender.created = false;
      return state;

    case CREATE_NEW_SENDER_SUCCESS:
      state.createNewSender = {
        loading: false,
        created: true,
        data: _.get(action, 'payload', {}),
        errors: {}
      };
      return state;

    case CREATE_NEW_SENDER_FAILED:
      state.createNewSender.loading = false;
      state.createNewSender.errors = _.get(action, 'payload', {});
      return state;

    case GET_SENDER_LIST_REQUEST:
      state.senderList = {
        loading: true,
        pagination: {},
        data: [],
        error: false
      };
      return state;

    case GET_SENDER_LIST_SUCCESS:
      state.senderList = {
        loading: false,
        pagination: _.get(action, 'payload.pagination', {}),
        data: _.get(action, 'payload.data', [])
      };
      return state;

    case GET_SENDER_LIST_FAILED:
      state.senderList.loading = false;
      state.senderList.error = true;
      return state;

    case DELETE_SENDER_REQUEST:
      state.senderDelete = {
        deleted: false,
        loading: true,
        data: {},
        deleting_ids: _.get(action, 'payload.ids', [])
      };
      return state;

    case DELETE_SENDER_SUCCESS:
      state.senderDelete = {
        deleted: true,
        loading: false,
        data: _.get(action, 'payload', {}),
        deleting_ids: []
      };
      return state;

    case DELETE_SENDER_FAILED:
      state.senderDelete.loading = false;
      state.senderDelete.deleting_ids = [];
      return state;

    case CREATE_SINGLE_SEND_REQUEST:
      state.createSingleSend = {
        loading: true,
        created: false,
        data: {},
        error: {}
      };
      return state;

    case CREATE_SINGLE_SEND_SUCCESS:
      state.createSingleSend = {
        loading: false,
        created: true,
        data: _.get(action, 'payload', {}),
        error: {}
      };
      return state;

    case CREATE_SINGLE_SEND_FAILED:
      state.createSingleSend.loading = false;
      state.createSingleSend.created = false;
      state.createSingleSend.error = _.get(action, 'payload', {});
      return state;

    case GET_SEND_SCHEDULE_LIST_REQUEST:
      state.isLoading = true;
      state.sendScheduleList = {
        pagination: {},
        data: []
      };
      return state;

    case GET_SEND_SCHEDULE_LIST_SUCCESS:
      state.isLoading = false;
      state.sendScheduleList = {
        data: _.get(action, 'payload.data', []),
        pagination: _.get(action, 'payload.pagination', {})
      };
      return state;

    case GET_TARGET_LIST_EMAIL_TEMPLATE_REQUEST:
      state.isLoading = true;
      state.targetListEmailTemplate = {
        pagination: {},
        data: []
      };
      return state;

    case GET_TARGET_LIST_EMAIL_TEMPLATE_SUCCESS:
      state.isLoading = false;
      state.targetListEmailTemplate = {
        data: _.get(action, 'payload.data', []),
        pagination: _.get(action, 'payload.pagination', {})
      };
      return state;

    case CREATE_EMAIL_TEMPLATE_REQUEST:
      state.createEmailTemplate = {
        loading: true,
        created: false,
      };
      return state;

    case CREATE_EMAIL_TEMPLATE_SUCCESS:
      state.createEmailTemplate = {
        loading: false,
        created: true,
      };
      return state;
    case CREATE_SEND_SCHEDULE_REQUEST:
      state.createSendSchedule = {
        loading: true,
        created: false,
      };
      return state;

    case CREATE_SEND_SCHEDULE_SUCCESS:
      state.createSendSchedule = {
        loading: false,
        created: true,
      };
      return state;
    case CREATE_EMAIL_TEMPLATE_FAILED:
      state.createEmailTemplate = {
        loading: false,
        created: false,
      };
      return state;
    case CREATE_SEND_SCHEDULE_FAILED:
      state.createSendSchedule = {
        loading: false,
        created: false,
      };
      return state;

    case UPDATE_EMAIL_TEMPLATE_REQUEST:
      state.createEmailTemplate = {
        loading: true,
        created: false,
      };
      return state;

    case UPDATE_EMAIL_TEMPLATE_SUCCESS:
      state.createEmailTemplate = {
        loading: false,
        created: true,
      };
      return state;
    case DELETE_EMAIL_TEMPLATE_REQUEST:
      state.actionEvent = {loading: true};
      return state;

    case GET_DETAIL_EMAIL_TEMPLATE_REQUEST:
      state.actionEvent = {loading: true};
      return state;

    case CANCEL_LOADING_TABLE_EMAIL_TEMPLATE:
      state.actionEvent = {loading: false};
      return state;
    case GET_SENDER_SELECT_LIST_SUCCESS:
      state.senderSelectList = _.map(_.get(action, 'payload', []), item => ({
        id: item.id,
        sender_id: item.sender_id,
        from_email: item.from_email,
        nickname: item.nickname
      }));
      return state;

    case GET_SINGLE_SEND_LIST_REQUEST:
      state.singleSendList = {
        loading: true,
        data: [],
        pagination: {}
      };
      return state;

    case GET_SINGLE_SEND_LIST_SUCCESS:
      state.singleSendList = {
        loading: false,
        data: _.get(action, 'payload.data', []),
        pagination: _.get(action, 'payload.pagination', {})
      };
      return state;

    case GET_SINGLE_SEND_LIST_FAILED:
      state.singleSendList.loading = false;
      return state;

    case DELETE_SINGLE_SEND_REQUEST:
      state.singleSendDelete = {
        deleted: false,
        loading: true,
        deleting_id: _.get(action, 'payload.single_send_id')
      };
      return state;

    case DELETE_SINGLE_SEND_SUCCESS:
      state.singleSendDelete = {
        deleted: true,
        loading: false,
        deleting_id: null
      };
      return state;

    case DELETE_SINGLE_SEND_FAILED:
      state.singleSendDelete.loading = false;
      state.singleSendDelete.deleting_id = null;
      return state;

    default:
      return state;
  }
});

export default reducer;