import { put, takeLeading, call } from 'redux-saga/effects';
import _ from 'lodash';

import helpers from 'utils/helpers';
import request from 'utils/api';
import { safe } from 'providers/GeneralProvider/saga';
import {
  GET_CUSTOMER_RECOMMENDATION_LIST_REQUEST,
  GET_CUSTOMER_RECOMMENDATION_DETAILS_REQUEST,
  GET_CUSTOMER_RECOMMENDATION_MENU_LIST_REQUEST,
  GET_CUSTOMER_RECOMMENDATION_MENU_DETAILS_REQUEST,
  GET_SERVICER_MATCHING_SCORE_LIST_REQUEST,
  GET_MENU_MATCHING_SCORE_LIST_REQUEST,
  GET_SERVICER_SEGMENTATION_LIST_REQUEST,
  GET_SERVICER_SEGMENTATION_DETAILS_REQUEST,
  GET_CUSTOMER_SEGMENTATION_LIST_REQUEST,
  GET_CUSTOMER_SEGMENTATION_DETAILS_REQUEST,
  GET_DISTRIBUTE_R_AND_F_REQUEST,
  GET_DISTRIBUTE_MONETARY_OVER_RF_REQUEST
} from './constants';
import {
  getCustomerRecommendationListSuccess,
  getCustomerRecommendationListFailed,
  getCustomerRecommendationDetailsSuccess,
  getCustomerRecommendationDetailsFailed,
  getCustomerRecommendationMenuListSuccess,
  getCustomerRecommendationMenuListFailed,
  getCustomerRecommendationMenuDetailsSuccess,
  getCustomerRecommendationMenuDetailsFailed,
  getServicerMatchingScoreListFailed,
  getServicerMatchingScoreListSuccess,
  getMenuMatchingScoreListSuccess,
  getMenuMatchingScoreListFailed,
  getServicerSegmentationListSuccess,
  getServicerSegmentationListFailed,
  getServicerSegmentationDetailsSuccess,
  getServicerSegmentationDetailsFailed,
  getCustomerSegmentationListSuccess,
  getCustomerSegmentationListFailed,
  getCustomerSegmentationDetailsSuccess,
  getCustomerSegmentationDetailsFailed,
  getDistributeRFSuccess,
  getDistributeRFFailed,
  getDistributeMonetaryOverRFSuccess,
  getDistributeMonetaryOverRFFailed
} from './actions';

export function* handleGetCustomerRecommendationList(input) {
  const requestBody = helpers.deepCloneObject(_.get(input, 'payload', {}));

  if (!_.get(requestBody, 'sorts')) {
    requestBody.sorts = [];
  }

  requestBody.sorts.push({
    field: 'customer_id',
    type: 'asc'
  });

  const result = yield call(request, '/ai/list_recommendation_customer', 'post', requestBody);
  yield put(getCustomerRecommendationListSuccess(_.get(result, 'payload')));
}

export function* onGetCustomerRecommendationListError() {
  yield put(getCustomerRecommendationListFailed());
}

export function* handleGetCustomerRecommendationDetails(input) {
  const requestBody = helpers.deepCloneObject(_.get(input, 'payload', {}));

  if (!_.get(requestBody, 'sorts')) {
    requestBody.sorts = [];
  }

  requestBody.sorts.push({
    field: 'rank',
    type: 'asc'
  });

  const result = yield call(request, '/ai/recommendation_customer_detail', 'post', requestBody);
  yield put(getCustomerRecommendationDetailsSuccess(_.get(result, 'payload')));
}

export function* onGetCustomerRecommendationDetailsError() {
  yield put(getCustomerRecommendationDetailsFailed());
}

export function* handleGetCustomerRecommendationMenuList(input) {
  const requestBody = helpers.deepCloneObject(_.get(input, 'payload', {}));

  if (!_.get(requestBody, 'sorts')) {
    requestBody.sorts = [];
  }

  requestBody.sorts.push({
    field: 'customer_id',
    type: 'asc'
  });

  const result = yield call(request, '/ai/list_recommendation_menu', 'post', requestBody);
  yield put(getCustomerRecommendationMenuListSuccess(_.get(result, 'payload')));
}

export function* onGetRecommendationMenuListError() {
  yield put(getCustomerRecommendationMenuListFailed());
}

export function* handleGetCustomerRecommendationMenuDetails(input) {
  const requestBody = helpers.deepCloneObject(_.get(input, 'payload', {}));

  if (!_.get(requestBody, 'sorts')) {
    requestBody.sorts = [];
  }

  requestBody.sorts.push({
    field: 'rank',
    type: 'asc'
  });

  const result = yield call(request, '/ai/recommendation_menu_detail', 'post', requestBody);
  yield put(getCustomerRecommendationMenuDetailsSuccess(_.get(result, 'payload')));
}

export function* onGetCustomerRecommendationMenuDetailsError() {
  yield put(getCustomerRecommendationMenuDetailsFailed());
}

export function* handleServicerMatchingScoreList(input) {
  const requestBody = helpers.deepCloneObject(_.get(input, 'payload', {}));
  if (!_.get(requestBody, 'sorts')) {
    requestBody.sorts = [];
  }

  requestBody.sorts.push({
    field: 'score',
    type: 'desc'
  });

  const result = yield call(request, '/ai/list_servicer_matching_score', 'post', requestBody);
  yield put(getServicerMatchingScoreListSuccess(_.get(result, 'payload')));
}

export function* onGetServicerMatchingScoreListError() {
  yield put(getServicerMatchingScoreListFailed());
}

export function* handleMenuMatchingScoreList(input) {
  const requestBody = helpers.deepCloneObject(_.get(input, 'payload', {}));

  if (!_.get(requestBody, 'sorts')) {
    requestBody.sorts = [];
  }

  requestBody.sorts.push({
    field: 'score',
    type: 'desc'
  });

  const result = yield call(request, '/ai/list_menu_matching_score', 'post', requestBody);
  yield put(getMenuMatchingScoreListSuccess(_.get(result, 'payload')));
}

export function* onGetMenuMatchingScoreError() {
  yield put(getMenuMatchingScoreListFailed());
}

export function* handleGetServicerSegmentationList(input) {
  const requestBody = helpers.deepCloneObject(_.get(input, 'payload', {}));

  if (!_.get(requestBody, 'sorts')) {
    requestBody.sorts = [];
  }

  requestBody.sorts.push({
    field: 'total',
    type: 'desc'
  });

  const result = yield call(request, '/ai/servicer_segmentation_summarize', 'post', requestBody);
  yield put(getServicerSegmentationListSuccess(_.get(result, 'payload')));
}

export function* onGetServicerSegmentationListError() {
  yield put(getServicerSegmentationListFailed())
}

export function* handleGetServicerSegmentationDetails(input) {
  const requestBody = helpers.deepCloneObject(_.get(input, 'payload', {}));

  if (!_.get(requestBody, 'sorts')) {
    requestBody.sorts = [];
  }

  requestBody.sorts.push({
    field: 'servicer_id',
    type: 'asc'
  });

  const result = yield call(request, 'ai/servicer_segmentation_detail', 'post', requestBody);
  yield put(getServicerSegmentationDetailsSuccess(_.get(result, 'payload')));
}

export function* onGetServicerSegmentationDetailsError() {
  yield put(getServicerSegmentationDetailsFailed())
}

export function* handleGetCustomerSegmentationList(input) {
  const requestBody = helpers.deepCloneObject(_.get(input, 'payload', {}));

  if (!_.get(requestBody, 'sorts')) {
    requestBody.sorts = [];
  }

  requestBody.sorts.push({
    field: 'total',
    type: 'desc'
  });

  const result = yield call(request, '/ai/list_customer_segmentation', 'post', requestBody);
  yield put(getCustomerSegmentationListSuccess(_.get(result, 'payload')));
}

export function* onGetCustomerSegmentationListError() {
  yield put(getCustomerSegmentationListFailed())
}

export function* handleGetCustomerSegmentationDetails(input) {
  const requestBody = helpers.deepCloneObject(_.get(input, 'payload', {}));

  if (!_.get(requestBody, 'sorts')) {
    requestBody.sorts = [];
  }

  requestBody.sorts.push({
    field: 'customer_id',
    type: 'asc'
  });

  const result = yield call(request, 'ai/customer_segmentation_detail', 'post', requestBody);
  yield put(getCustomerSegmentationDetailsSuccess(_.get(result, 'payload')));
}

export function* onGetCustomerSegmentationDetailsError() {
  yield put(getCustomerSegmentationDetailsFailed())
}

export function* handleGetDistributeRFRequest(input) {
  const requestBody = helpers.deepCloneObject(_.get(input, 'payload', {}));

  const result = yield call(request, '/ai/rfm/distribute_via_recency_and_frequency', 'post', requestBody);
  yield put(getDistributeRFSuccess(_.get(result, 'payload')));
}

export function* onGetDistributeRFError() {
  yield put(getDistributeRFFailed())
}

export function* handleGetDistributeRFStatitisRFMRequest(input) {
  const requestBody = helpers.deepCloneObject(_.get(input, 'payload', {}));

  const result = yield call(request, '/ai/rfm/distribute_monetary_over_rf', 'post', requestBody);
  yield put(getDistributeMonetaryOverRFSuccess(_.get(result, 'payload')));
}

export function* onGetDistributeRFStatitisRFMError() {
  yield put(getDistributeMonetaryOverRFFailed())
}

export default function* watchAuth() {
  yield takeLeading(
    GET_CUSTOMER_RECOMMENDATION_LIST_REQUEST,
    safe(handleGetCustomerRecommendationList, onGetCustomerRecommendationListError),
  );

  yield takeLeading(
    GET_CUSTOMER_RECOMMENDATION_DETAILS_REQUEST,
    safe(handleGetCustomerRecommendationDetails, onGetCustomerRecommendationDetailsError),
  );

  yield takeLeading(
    GET_CUSTOMER_RECOMMENDATION_MENU_LIST_REQUEST,
    safe(handleGetCustomerRecommendationMenuList, onGetRecommendationMenuListError),
  )

  yield takeLeading(
    GET_CUSTOMER_RECOMMENDATION_MENU_DETAILS_REQUEST,
    safe(handleGetCustomerRecommendationMenuDetails, onGetCustomerRecommendationMenuDetailsError),
  )

  yield takeLeading(
    GET_SERVICER_MATCHING_SCORE_LIST_REQUEST,
    safe(handleServicerMatchingScoreList, onGetServicerMatchingScoreListError),
  )

  yield takeLeading (
    GET_MENU_MATCHING_SCORE_LIST_REQUEST,
    safe(handleMenuMatchingScoreList, onGetMenuMatchingScoreError),
  )

  yield takeLeading (
    GET_SERVICER_SEGMENTATION_LIST_REQUEST,
    safe(handleGetServicerSegmentationList, onGetServicerSegmentationListError)
  )

  yield takeLeading (
    GET_SERVICER_SEGMENTATION_DETAILS_REQUEST,
    safe(handleGetServicerSegmentationDetails, onGetServicerSegmentationDetailsError)
  )

  yield takeLeading (
    GET_CUSTOMER_SEGMENTATION_LIST_REQUEST,
    safe(handleGetCustomerSegmentationList, onGetCustomerSegmentationListError)
  )

  yield takeLeading (
    GET_CUSTOMER_SEGMENTATION_DETAILS_REQUEST,
    safe(handleGetCustomerSegmentationDetails, onGetCustomerSegmentationDetailsError)
  )

  yield takeLeading (
    GET_DISTRIBUTE_R_AND_F_REQUEST,
    safe(handleGetDistributeRFRequest, onGetDistributeRFError)
  )

  yield takeLeading (
    GET_DISTRIBUTE_MONETARY_OVER_RF_REQUEST,
    safe(handleGetDistributeRFStatitisRFMRequest, onGetDistributeRFStatitisRFMError)
  )
}
