import { createSelector } from 'reselect';

const selectGeneral = (state) => state.general;

export const makeSelectCollapseMenu = () => createSelector(
  selectGeneral,
  (generalState) => generalState.collapseMenu
);

export const makeSelectModalData = () => createSelector(
  selectGeneral,
  (generalState) => generalState.modal
);

export const makeSelectChangePasswordModal = () => createSelector(
  selectGeneral,
  (generalState) => generalState.changePasswordModal
);

export const makeSelectCreateAgentModal = () => createSelector(
  selectGeneral,
  (generalState) => generalState.createAgentModal
);

export const makeSelectStoreDetailModal = () => createSelector(
  selectGeneral,
  (generalState) => generalState.storeDetailModal
);