export const GET_CUSTOMER_RECOMMENDATION_LIST_REQUEST = '@AISupportProvider/GET_CUSTOMER_RECOMMENDATION_LIST_REQUEST';
export const GET_CUSTOMER_RECOMMENDATION_LIST_SUCCESS = '@AISupportProvider/GET_CUSTOMER_RECOMMENDATION_LIST_SUCCESS';
export const GET_CUSTOMER_RECOMMENDATION_LIST_FAILED = '@AISupportProvider/GET_CUSTOMER_RECOMMENDATION_LIST_FAILED';

export const GET_CUSTOMER_RECOMMENDATION_DETAILS_REQUEST = '@AISupportProvider/GET_CUSTOMER_RECOMMENDATION_DETAILS_REQUEST';
export const GET_CUSTOMER_RECOMMENDATION_DETAILS_SUCCESS = '@AISupportProvider/GET_CUSTOMER_RECOMMENDATION_DETAILS_SUCCESS';
export const GET_CUSTOMER_RECOMMENDATION_DETAILS_FAILED = '@AISupportProvider/GET_CUSTOMER_RECOMMENDATION_DETAILS_FAILED';

export const GET_CUSTOMER_RECOMMENDATION_MENU_LIST_REQUEST = '@AISupportProvider/GET_CUSTOMER_RECOMMENDATION_MENU_REQUEST';
export const GET_CUSTOMER_RECOMMENDATION_MENU_LIST_SUCCESS = '@AISupportProvider/GET_CUSTOMER_RECOMMENDATION_MENU_LIST_SUCCESS';
export const GET_CUSTOMER_RECOMMENDATION_MENU_LIST_FAILED = '@AISupportProvider/GET_CUSTOMER_RECOMMENDATION_MENU_LIST_FAILED';

export const GET_CUSTOMER_RECOMMENDATION_MENU_DETAILS_REQUEST = '@AISupportProvider/GET_CUSTOMER_RECOMMENDATION_MENU_DETAILS_REQUEST';
export const GET_CUSTOMER_RECOMMENDATION_MENU_DETAILS_SUCCESS = '@AISupportProvider/GET_CUSTOMER_RECOMMENDATION_MENU_DETAILS_SUCCESS';
export const GET_CUSTOMER_RECOMMENDATION_MENU_DETAILS_FAILED = '@AISupportProvider/GET_CUSTOMER_RECOMMENDATION_MENU_DETAILS_FAILED';

export const GET_SERVICER_MATCHING_SCORE_LIST_REQUEST = '@AISupportProvider/GET_SERVICER_MATCHING_SCORE_LIST_REQUEST';
export const GET_SERVICER_MATCHING_SCORE_LIST_SUCCESS = '@AISupportProvider/GET_SERVICER_MATCHING_SCORE_LIST_SUCCESS';
export const GET_SERVICER_MATCHING_SCORE_LIST_FAILED = '@AISupportProvider/GET_SERVICER_MATCHING_SCORE_LIST_FAILED';

export const GET_MENU_MATCHING_SCORE_LIST_REQUEST = '@AISupportProvider/GET_MENU_MATCHING_SCORE_LIST_REQUEST';
export const GET_MENU_MATCHING_SCORE_LIST_SUCCESS = '@AISupportProvider/GET_MENU_MATCHING_SCORE_LIST_SUCCESS';
export const GET_MENU_MATCHING_SCORE_LIST_FAILED = '@AISupportProvider/GET_MENU_MATCHING_SCORE_LIST_FAILED';

export const GET_SERVICER_SEGMENTATION_LIST_REQUEST = '@AISupportProvider/GET_SERVICER_SEGMENTATION_lIST_REQUEST';
export const GET_SERVICER_SEGMENTATION_LIST_SUCCESS = '@AISupportProvider/GET_SERVICER_SEGMENTATION_LIST_SUCCESS';
export const GET_SERVICER_SEGMENTATION_LIST_FAILED = '@AISupportProvider/GET_SERVICER_SEGMENTATION_LIST_FAILED';

export const GET_SERVICER_SEGMENTATION_DETAILS_REQUEST = '@AISupportProvider/GET_SERVICER_SEGMENTATION_DETAILS_REQUEST';
export const GET_SERVICER_SEGMENTATION_DETAILS_SUCCESS = '@AISupportProvider/GET_SERVICER_SEGMENTATION_DETAILS_SUCCESS';
export const GET_SERVICER_SEGMENTATION_DETAILS_FAILED = '@AISupportProvider/GET_SERVICER_SEGMENTATION_DETAILS_FAILED';

export const GET_CUSTOMER_SEGMENTATION_LIST_REQUEST = '@AISupportProvider/GET_CUSTOMER_SEGMENTATION_LIST_REQUEST';
export const GET_CUSTOMER_SEGMENTATION_LIST_SUCCESS = '@AISupportProvider/GET_CUSTOMER_SEGMENTATION_LIST_SUCCESS';
export const GET_CUSTOMER_SEGMENTATION_LIST_FAILED = '@AISupportProvider/GET_CUSTOMER_SEGMENTATION_LIST_FAILED';

export const GET_CUSTOMER_SEGMENTATION_DETAILS_REQUEST = '@AISupportProvider/GET_CUSTOMER_SEGMENTATION_DETAILS_REQUEST';
export const GET_CUSTOMER_SEGMENTATION_DETAILS_SUCCESS = '@AISupportProvider/GET_CUSTOMER_SEGMENTATION_DETAILS_SUCCESS';
export const GET_CUSTOMER_SEGMENTATION_DETAILS_FAILED = '@AISupportProvider/GET_CUSTOMER_SEGMENTATION_DETAILS_FAILED';

export const GET_DISTRIBUTE_R_AND_F_REQUEST = '@AISupportProvider/GET_DISTRIBUTE_R_AND_F_REQUEST';
export const GET_DISTRIBUTE_R_AND_F_SUCCESS = '@AISupportProvider/GET_DISTRIBUTE_R_AND_F_SUCCESS';
export const GET_DISTRIBUTE_R_AND_F_FAILED = '@AISupportProvider/GET_DISTRIBUTE_R_AND_F_FAILED';

export const GET_DISTRIBUTE_MONETARY_OVER_RF_REQUEST = '@AISupportProvider/GET_DISTRIBUTE_MONETARY_OVER_RF_REQUEST';
export const GET_DISTRIBUTE_MONETARY_OVER_RF_SUCCESS = '@AISupportProvider/GET_DISTRIBUTE_MONETARY_OVER_RF_SUCCESS';
export const GET_DISTRIBUTE_MONETARY_OVER_RF_FAILED = '@AISupportProvider/GET_DISTRIBUTE_MONETARY_OVER_RF_FAILED';