import produce from 'immer';
import _ from 'lodash';

import {
  CHECK_AUTH0_INFO_REQUEST,
  CHECK_AUTH0_INFO_SUCCESS,
  CHECK_AUTH0_INFO_FAILED,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILED
} from './constants';
import constants from 'utils/constants';

const initialState = {
  isLoading: false,
  isNewUser: false,
  isUserWaitingForApproval: false,
  guestUserInfo: {},
  userLogged: {},
  userServiceDomain: {}
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {

    case CHECK_AUTH0_INFO_REQUEST:
      state.isLoading = true;
      return state;

    case CHECK_AUTH0_INFO_SUCCESS:
      state.isLoading = false;

      state.isUserWaitingForApproval = _.get(action, 'payload.status') === constants.LOGIN_STATUS.PENDING;
      state.isNewUser = [
        constants.LOGIN_STATUS.NOT_EXISTED,
        constants.LOGIN_STATUS.INVITED,
        constants.LOGIN_STATUS.DELETED
      ].includes(_.get(action, 'payload.status'));
      if (_.get(action, 'payload.status') === constants.LOGIN_STATUS.LOGGED) {
        state.userLogged = _.get(action, 'payload.data');
        state.userServiceDomain = _.keyBy(_.get(action, 'payload.data.product', []), 'code');
      } else {
        state.guestUserInfo = _.get(action, 'payload.data');
      }

      return state;

    case CHECK_AUTH0_INFO_FAILED:
      state.isLoading = false;
      return state;

    case LOGOUT_REQUEST:
      state.isLoading = true;
      return state;

    case LOGOUT_SUCCESS:
      state.isLoading = false;
      return state;

    case LOGOUT_FAILED:
      state.isLoading = false;
      return state;

    default:
      return state;
  }
});

export default reducer;