import _ from 'lodash';
import { put, takeLeading, call } from 'redux-saga/effects';

import { safe } from 'providers/GeneralProvider/saga';
import request from 'utils/api';
import {
  GET_ROLE_LIST_REQUEST,
  GET_PRODUCT_LIST_REQUEST
} from './constants';
import {
  getRoleListSuccess,
  getProductListSuccess
} from './actions';


export function* handleGetRoleList() {
  const result = yield call(request, '/role/list', 'get');
  yield put(getRoleListSuccess(_.get(result, 'payload')));
}

export function* handleGetProductList() {
  const result = yield call(request, '/product/list', 'get');
  yield put(getProductListSuccess(_.get(result, 'payload')));
}

export default function* watchAuth() {

  yield takeLeading(
    GET_ROLE_LIST_REQUEST,
    safe(handleGetRoleList),
  );

  yield takeLeading(
    GET_PRODUCT_LIST_REQUEST,
    safe(handleGetProductList),
  );
}