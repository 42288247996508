import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import App from 'containers/App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import configureStore from 'redux/store';
import './styles/global.less';
import LocalizeProvider from 'providers/LocalizeProvider';
import Auth0ProviderWithHistory from './providers/AuthProvider';

const initialState = {};
const {store} = configureStore(initialState);

const baseDom = (
  <LocalizeProvider>
    <Provider store={store}>
      <BrowserRouter>
        <Auth0ProviderWithHistory>
          <ConfigProvider autoInsertSpaceInButton={false}>
            <App />
          </ConfigProvider>
        </Auth0ProviderWithHistory>
      </BrowserRouter>
    </Provider>
  </LocalizeProvider>
);

ReactDOM.render(baseDom, document.getElementById('root'));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
