import React, { memo } from 'react';
import i18n from 'i18n';
import { Layout, Row } from 'antd';

import './styles.less';
const { Footer } = Layout;

const FooterMenu = () => {
  return (
    <Footer style={{ textAlign: 'center'}}>
        <Row justify="center">{i18n.t('global.copyRight.copyRightInfo')}</Row>
        <Row justify="center">{i18n.t('global.copyRight.timezone')}</Row>
    </Footer>
  );
};

export default memo(FooterMenu);