import React, { memo } from 'react';
import { Layout } from 'antd';
import PropTypes from 'prop-types';
import _ from 'lodash';

import NavBar from './Navbar';
import SideMenu from './SideMenu';
import FooterMenu from './FooterMenu';
import './styles.less';

const { Content } = Layout;

const DashboardLayout = ({ collapseMenu, toggleCollapseMenu, userLogged, menu, menuSuperAdmin, children }) => {
  return (
    <Layout className="page-layout">
      <NavBar
        collapseMenu={collapseMenu}
        toggleCollapseMenu={toggleCollapseMenu}
        menu={menu}
        menuSuperAdmin={menuSuperAdmin}
        userLogged={userLogged}/>
      <Layout>
        <SideMenu collapseMenu={collapseMenu} width={300} />
        <Layout>
          {!_.isEmpty(userLogged) && <Content>
            {children}
          </Content>
          }
          <FooterMenu/>
        </Layout>
      </Layout>
    </Layout>
  );
};

DashboardLayout.propTypes = {
  collapseMenu: PropTypes.bool,
  toggleCollapseMenu: PropTypes.func,
  children: PropTypes.any,
  userLogged: PropTypes.object,
  menu: PropTypes.func,
  menuSuperAdmin: PropTypes.func
};
export default memo(DashboardLayout);