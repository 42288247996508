import { Col, Row, Empty } from 'antd';
import { 
  RenderBarChart, 
  RenderSegmentationTreeMap, 
  RenderSegmentationColumnByRF, 
  RenderColumnChart 
} from 'components/common';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import i18n from 'i18n';

const RenderRFMDiagrams = (input) => {
  const { type, segmentList } = input;
  const {
    distributeRF,
    distributeMonetary,
  } = useSelector((state) => state.aiSupport);

  const [segmentationListSource, setDataSource] = useState([]);
  const maxScore = 5;
  const dataChart = _.get(distributeMonetary, 'data') || [];
  let isNoData = false;
  useEffect(() => {
    
    if (!_.isEmpty(segmentList)) {
      let initData = []
      for (let i = 0; i < segmentList.length; i++) {
        initData.push({
          id: i,
          ...segmentList[i]
        });
      }
      setDataSource(initData);
    }
    
  }, [segmentList]);
  if (
    _.isEmpty(segmentList) &&
    _.isEmpty(dataChart) &&
    _.isEmpty(_.get(distributeRF, 'data.recency')) &&
    _.isEmpty(_.get(distributeRF, 'data.frequency'))
  ) {
    isNoData = true;
  }

  // render figure 9
  const figure9 = []
  const scoreBottom = []
  const maxTotal = _.get(_.maxBy(dataChart, (item) => item.total), 'total') || 0;
  const maxHeight = 150;
  for (let i = 1; i<= maxScore; i++) {
    const row = []
    for (let j = 1; j<= maxScore; j++) {
      const dataByRF = _.filter(dataChart, {recency: i, frequency: j});
      const total = _.get(_.maxBy(dataByRF, (item) => item.total), 'total');
      let height = maxHeight;
      if (total < maxTotal) {
        height = Math.ceil(total / maxTotal * maxHeight)
      }
      row.push(<Col span={4} key={`RF_${i}_${j}`}>
        <RenderSegmentationColumnByRF data={dataByRF} height={height+40} />
      </Col>)
    }
    figure9.push(
      <Row gutter={[8, 8]} type="flex" justify="space-around" align="bottom" className="figure9" key={`numberOfR_${i}`}>
        <Col span={1}><div className="numberOfR" style={{marginBottom: (maxHeight/2)+'px'}}>{i === 3 ? (<div>R</div>): <div><br /></div>} {i}</div></Col>
        {row}
      </Row>
    )
    scoreBottom.push(<Col span={4} align="center" key={`scoreBottom_${i}`}>{i} {i === 3 ? (<div>F</div>): <div><br /></div>}</Col>)
  }

  return <>
    { !isNoData ?
      <div>
        <Row gutter={[8, 8]} className="figure1">
          <Col span={24}><RenderSegmentationTreeMap type={type} data={segmentationListSource}/></Col>
        </Row>
        <Row gutter={[8, 8]} type="flex" justify="space-around" align="bottom" className="figure8">
          <Col span={4}></Col>
          <Col span={8}>
            <div align="center"><h3>{i18n.t('content.aiSupportedTools.charts.distributionOfRecency')}</h3></div>
            <div className="charts"><RenderColumnChart data={_.get(distributeRF, 'data.recency')} height={400} /></div>
          </Col>
          <Col span={8}>
            <div align="center"><h3>{i18n.t('content.aiSupportedTools.charts.distributionOfFrequency')}</h3></div>
            <div className="charts"><RenderColumnChart data={_.get(distributeRF, 'data.frequency')} height={400} /></div>
          </Col>
          <Col span={4}></Col>
        </Row>
        <Row>
          <Col span={24} align="center" style={{marginTop: '40px'}}><h3>{i18n.t('content.aiSupportedTools.charts.distributionOfMForeachFAndR')}</h3></Col>
        </Row>
        {figure9}
        <Row gutter={[8, 8]} type="flex" justify="space-around" align="middle">
          <Col span={1}></Col>
          {scoreBottom}
        </Row>
        <Row>
          <Col span={24} align="center" style={{marginTop: '40px'}}>
            <h3>
              { type === 'customer' ? i18n.t('content.aiSupportedTools.charts.distributionOfTheCustomersAmongTheSegments') : i18n.t('content.aiSupportedTools.charts.distributionOfTheServicersAmongTheSegments') }
            </h3>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={24}><RenderBarChart data={segmentationListSource}/></Col>
        </Row>
      </div> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
    }
  </>
};

RenderRFMDiagrams.propTypes = {
  type: PropTypes.string,
  segmentList: PropTypes.array
};

RenderRFMDiagrams.defaultProps = {
  type: 'customer',
  segmentList: []
};

export default RenderRFMDiagrams;
