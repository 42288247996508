export const HIDDEN_COLUMN_PERSONAL = ['id', 'email', 'full_name', 'display_name', 'search'];
export const COUPON_CONDITION_KEY = [
    'min',
    'quantum',
    'limitPerUser',
    'allowServicerIds',
    'allowCustomerIds',
    'issueCouponNumber',
    'availableDays',
    'maxServiceUsage',
    'minServiceUsage',
    'endIssued'
];
export const HOGUGU_APP_ID = '224472413543498';
export const NAILIE_APP_ID = '524472413543498';
export const TANOMEL_APP_ID = '324472413543498';
export const HAIRLIE_APP_ID = '624472413543498';
