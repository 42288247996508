import produce from 'immer';
import _ from 'lodash';

import {
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAILED,
  GET_USER_LIST_REQUEST,
  GET_USER_LIST_SUCCESS,
  GET_USER_LIST_FAILED,
  USER_INVITE_REQUEST,
  USER_INVITE_SUCCESS,
  USER_INVITE_FAILED,
  ACCEPT_INVITE_REQUEST,
  ACCEPT_INVITE_SUCCESS,
  ACCEPT_INVITE_FAILED,
  GET_USER_PROFILE_SUCCESS,
  USER_PROFILE_UPDATE_SUCCESS,
  GET_USER_SETTING_SUCCESS,
  USER_SETTING_UPDATE_SUCCESS, 
  USER_SETTING_UPDATE_REQUEST,
  GET_USER_SETTING_FAIL,
  USER_SETTING_UPDATE_FAILED,
  REGISTER_COUPON_SERVICE_REQUEST,
  REGISTER_COUPON_SERVICE_SUCCESS,
  REGISTER_COUPON_SERVICE_FAILED,
  REGISTER_COUPON_SERVICE_V1_REQUEST,
  REGISTER_COUPON_SERVICE_V1_SUCCESS,
  REGISTER_COUPON_SERVICE_V1_FAILED,
  REGISTER_POINT_SERVICE_REQUEST,
  REGISTER_POINT_SERVICE_FAILED,
  REGISTER_POINT_SERVICE_SUCCESS,
  REGISTER_PRODUCT_REQUEST,
  REGISTER_PRODUCT_SUCCESS,
  REGISTER_PRODUCT_FAILED,
  RESET_REGISTER_PRODUCT,
  RESET_REGISTER_COUPON_SERVICE,
  RESET_REGISTER_COUPON_V1_SERVICE
} from "./constants";

const initialState = {
  isLoading: false,
  isRegisted: false,
  isInvalidInviteToken: false,
  userInvite: {},
  acceptInvite: {},
  userProfile: {},
  userTable: {
    loading: true,
    data: [],
    pagination: {}
  },
  userSetting: {
    loading: true,
    data: []
  },
  registerCouponService: {
    loading: true,
    data: []
  },
  registerCouponServiceV1: {
    loading: true,
    data: []
  },
  registerPointService: {
    loading: true,
    data: [],
  },
  registerProduct: {
    loading: true,
    data: []
  }
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {

    case USER_REGISTER_REQUEST:
      state.isLoading = true;
      return state;

    case USER_REGISTER_SUCCESS:
      state.isLoading = false;
      state.isRegisted = true;
      return state;

    case USER_REGISTER_FAILED:
      state.isLoading = false;
      state.isRegisted = false;
      state.isInvalidInviteToken = true;
      return state;

    case GET_USER_LIST_REQUEST:
      state.userTable = {
        loading: true,
        data: [],
        pagination: {}
      };
      return state;

    case GET_USER_LIST_SUCCESS:
      state.userTable = {
        loading: false,
        data: _.get(action, 'payload.data', []),
        pagination: _.get(action, 'payload.pagination', {})
      };
      return state;

    case GET_USER_LIST_FAILED:
      state.userTable.loading = false;
      return state;

    case USER_INVITE_REQUEST:
      state.isLoading = true;
      state.userInvite = {};
      return state;

    case USER_INVITE_SUCCESS:
      state.isLoading = false;
      state.userInvite = {
        sendSuccess: true
      };
      return state;

    case USER_INVITE_FAILED:
      state.isLoading = false;
      state.userInvite = _.get(action, 'payload', {});
      return state;

    case ACCEPT_INVITE_REQUEST:
      state.isLoading = true;
      state.acceptInvite = {};
      return state;

    case ACCEPT_INVITE_SUCCESS:
      state.isLoading = false;
      state.acceptInvite = {
        acceptSuccess: true,
        inviteLinkClicked: true
      };
      return state;

    case ACCEPT_INVITE_FAILED:
      state.isLoading = false;
      state.acceptInvite = {
        acceptFailed: _.get(action, 'payload.status', false)
      };
      return state;

    case GET_USER_PROFILE_SUCCESS:
      state.isLoading = false;
      state.userProfile = _.get(action, 'payload', {});
      return state;

    case USER_PROFILE_UPDATE_SUCCESS:
      state.isLoading = false;
      state.userProfile = _.get(action, 'payload', {});
      return state;

    case GET_USER_SETTING_SUCCESS:
    case USER_SETTING_UPDATE_SUCCESS:
      state.userSetting.loading = false;
      state.userSetting.data = _.get(action, 'payload', {});
      return state;

    case USER_SETTING_UPDATE_REQUEST:
      state.userSetting.loading = true;
      state.userSetting.data = {};
      return state;
    case GET_USER_SETTING_FAIL:
    case USER_SETTING_UPDATE_FAILED:
      state.userSetting.loading = false;
      return state;
    
    case REGISTER_COUPON_SERVICE_REQUEST:
      state.isLoading = true;
      state.registerCouponService = {
        loading: true,
        data: []
      }
      return state;
    
    case REGISTER_COUPON_SERVICE_SUCCESS:
      state.isLoading = false;
      state.registerCouponService = {
        loading: false,
        data: _.get(action, 'payload', {})
      }
      return state;
    
    case REGISTER_COUPON_SERVICE_FAILED:
      state.isLoading = false;
      state.registerCouponService.loading = false;
      return state;

    case REGISTER_COUPON_SERVICE_V1_REQUEST:
      state.isLoading = true;
      state.registerCouponServiceV1 = {
        loading: true,
        data: []
      };
      return state;

    case REGISTER_COUPON_SERVICE_V1_SUCCESS:
      state.isLoading = false;
      state.registerCouponServiceV1 = {
        loading: false,
        data: _.get(action, "payload", {})
      };
      return state;

    case REGISTER_COUPON_SERVICE_V1_FAILED:
      state.isLoading = false;
      state.registerCouponServiceV1.loading = false;
      return state;

    case RESET_REGISTER_COUPON_SERVICE:
      state.isLoading = false;
      state.registerCouponService = {
        loading: false,
        data: []
      };
      return state;

    case RESET_REGISTER_COUPON_V1_SERVICE:
      state.isLoading = false;
      state.registerCouponServiceV1 = {
        loading: false,
        data: []
      };
      return state;

    case REGISTER_POINT_SERVICE_REQUEST:
      state.isLoading = true;
      state.registerPointService = {
        loading: true,
        data: []
      }
      return state;
    
    case REGISTER_POINT_SERVICE_SUCCESS:
      state.isLoading = false;
      state.registerPointService = {
        loading: false,
        data: _.get(action, 'payload', {})
      }
      return state;

    case REGISTER_POINT_SERVICE_FAILED:
      state.isLoading = false;
      state.registerPointService.loading = false;
      return state;

    case REGISTER_PRODUCT_REQUEST:
      state.isLoading = true;
      state.registerProduct = {
        loading: true,
        data: []
      };
      return state;
  
    case REGISTER_PRODUCT_SUCCESS:
      state.isLoading = false;
      state.registerProduct = {
        loading: false,
        data: _.get(action, 'payload', {})
      }
      return state;

    case REGISTER_PRODUCT_FAILED:
      state.isLoading = false;
      state.registerProduct = false;
      return state;

    case RESET_REGISTER_PRODUCT:
      state.isLoading = false;
      state.registerProduct = {
        loading: false,
        data: []
      };
      return state;

    default:
      return state;
  }
});

export default reducer;