import {
    GET_COUPON_LIST_REQUEST,
    GET_COUPON_LIST_SUCCESS,
    GET_COUPON_LIST_FAILED,
    CREATE_COUPON_REQUEST,
    CREATE_COUPON_SUCCESS,
    CREATE_COUPON_FAILED,
    COUPON_DELETE_REQUEST,
    COUPON_DELETE_SUCCESS,
    COUPON_DELETE_FAILED,
    COUPON_UPDATE_STATUS_REQUEST,
    GET_DETAIL_COUPON_REQUEST,
    GET_DETAIL_COUPON_SUCCESS,
    RESET_DETAIL_COUPON,
    UPDATE_COUPON_REQUEST,
    UPDATE_COUPON_SUCCESS,
    UPDATE_COUPON_FAILED,
    GET_DETAIL_USAGE_COUPON_REQUEST,
    GET_DETAIL_USAGE_COUPON_SUCCESS,
    GET_DETAIL_ISSUE_COUPON_REQUEST,
    GET_DETAIL_ISSUE_COUPON_SUCCESS,
    COUPON_TYPE_SETTING_UPDATE_REQUEST,
    COUPON_TYPE_SETTING_UPDATE_SUCCESS,
    COUPON_TYPE_SETTING_UPDATE_FAILED,
    GET_COUPON_TYPE_SETTING_REQUEST,
    GET_COUPON_TYPE_SETTING_SUCCESS,
    GET_COUPON_TYPE_SETTING_FAILED,
    GET_IMAGE_SIZE_REQUEST,
    GET_IMAGE_SIZE_SUCCESS,
    GET_IMAGE_SIZE_FAILED,
    UPDATE_IMAGE_SIZE_REQUEST,
    UPDATE_IMAGE_SIZE_SUCCESS,
    UPDATE_IMAGE_SIZE_FAILED,
    GET_INVITATION_LIST_REQUEST,
    GET_INVITATION_LIST_SUCCESS,
    GET_INVITATION_LIST_FAILED,
    CREATE_INVITATION_SETTING_REQUEST,
    CREATE_INVITATION_SETTING_SUCCESS,
    CREATE_INVITATION_SETTING_FAILED,
    GET_ISSUED_INVITATION_CODE_LIST_REQUEST,
    GET_ISSUED_INVITATION_CODE_LIST_SUCCESS,
    GET_ISSUED_INVITATION_CODE_LIST_FAILED,
    UPDATE_INVITATION_STATUS_REQUEST,
    UPDATE_INVITATION_STATUS_SUCCESS,
    UPDATE_INVITATION_STATUS_FAILED,
    DELETE_INVITATION_SETTING_REQUEST,
    DELETE_INVITATION_SETTING_SUCCESS,
    DELETE_INVITATION_SETTING_FAILED,
    GET_DETAIL_INVITATION_COUPON_REQUEST,
    GET_DETAIL_INVITATION_COUPON_SUCCESS,
    GET_DETAIL_INVITATION_COUPON_FAILED,
    RESET_DETAIL_INVITATION_COUPON,
    GET_DETAIL_INVITATION_COUPON_SETTING_REQUEST,
    GET_DETAIL_INVITATION_COUPON_SETTING_SUCCESS,
    GET_DETAIL_INVITATION_COUPON_SETTING_FAILED,
    GET_DETAIL_INCENTIVE_COUPON_SETTING_REQUEST,
    GET_DETAIL_INCENTIVE_COUPON_SETTING_SUCCESS,
    GET_DETAIL_INCENTIVE_COUPON_SETTING_FAILED,
    RESET_DETAIL_INVITATION_COUPON_SETTING,
    RESET_DETAIL_INCENTIVE_COUPON_SETTING,
    GET_TAG_LIST_REQUEST,
    GET_TAG_LIST_SUCCESS,
    GET_TAG_LIST_FAILED,
    DELETE_TAG_REQUEST,
    DELETE_TAG_SUCCESS,
    DELETE_TAG_FAILED,
    CREATE_TAG_REQUEST,
    CREATE_TAG_SUCCESS,
    CREATE_TAG_FAILED,
    UPDATE_TAG_REQUEST,
    UPDATE_TAG_SUCCESS,
    UPDATE_TAG_FAILED,
    ASSOCIATE_TAG_WITH_COUPON_REQUEST,
    ASSOCIATE_TAG_WITH_COUPON_SUCCESS,
    ASSOCIATE_TAG_WITH_COUPON_FAILED,
    GET_SECRET_KEY_REQUEST,
    GET_SECRET_KEY_SUCCESS,
    GET_SECRET_KEY_FAILED
} from './constants';

export const getCouponListRequest = (payload) => {
    return {type: GET_COUPON_LIST_REQUEST, payload};
};

export const getCouponListSuccess = (payload) => {
    return {type: GET_COUPON_LIST_SUCCESS, payload};
};

export const getCouponListFailed = () => {
    return {type: GET_COUPON_LIST_FAILED};
};
//Create
export const createCouponRequest = (payload) => {
    return {type: CREATE_COUPON_REQUEST, payload};
};
export const createCouponSuccess = (payload) => {
    return {type: CREATE_COUPON_SUCCESS, payload};
};
export const createCouponFailed = (payload) => {
    return {type: CREATE_COUPON_FAILED, payload};
};
//Update
export const updateCouponRequest = (payload) => {
    return {type: UPDATE_COUPON_REQUEST, payload};
};
export const updateCouponSuccess = (payload) => {
    return {type: UPDATE_COUPON_SUCCESS, payload};
};
export const updateCouponFailed = (payload) => {
    return {type: UPDATE_COUPON_FAILED, payload};
};

export const couponDeleteRequest = (payload) => {
    return { type: COUPON_DELETE_REQUEST, payload };
};
export const couponUpdateStatusRequest = (payload) => {
    return { type: COUPON_UPDATE_STATUS_REQUEST, payload };
};
export const couponDeleteSuccess = (payload) => {
    return { type: COUPON_DELETE_SUCCESS, payload };
};

export const couponDeleteFailed = () => {
    return { type: COUPON_DELETE_FAILED };
};
export const getDetailCouponRequest= (payload) => {
    return {type: GET_DETAIL_COUPON_REQUEST, payload};
};
export const getDetailCouponSuccess= (payload) => {
    return {type: GET_DETAIL_COUPON_SUCCESS, payload};
};
export const resetDetailCoupon = (payload) => {
    return {type: RESET_DETAIL_COUPON, payload};
};

export const getDetailUsageCouponRequest= (payload) => {
    return {type: GET_DETAIL_USAGE_COUPON_REQUEST, payload};
};
export const getDetailUsageCouponSuccess= (payload) => {
    return {type: GET_DETAIL_USAGE_COUPON_SUCCESS, payload};
};

export const getDetailIssueCouponRequest= (payload) => {
    return {type: GET_DETAIL_ISSUE_COUPON_REQUEST, payload};
};
export const getDetailIssueCouponSuccess= (payload) => {
    return {type: GET_DETAIL_ISSUE_COUPON_SUCCESS, payload};
};

// Coupon type
export const getCouponTypeSettingRequest = (payload) => {
  return { type: GET_COUPON_TYPE_SETTING_REQUEST, payload };
};

export const getCouponTypeSettingSuccess = (payload) => {
  return { type: GET_COUPON_TYPE_SETTING_SUCCESS, payload };
};

export const getCouponTypeSettingFailed = (payload) => {
  return { type: GET_COUPON_TYPE_SETTING_FAILED, payload };
};

export const updateCouponTypeSettingRequest = (payload) => {
  return { type: COUPON_TYPE_SETTING_UPDATE_REQUEST, payload };
};

export const updateCouponTypeSettingSuccess = (payload) => {
  return { type: COUPON_TYPE_SETTING_UPDATE_SUCCESS, payload };
};

export const updateCouponTypeSettingFailed = (payload) => {
  return { type: COUPON_TYPE_SETTING_UPDATE_FAILED, payload };
};

// Image Size
export const getImageSizeRequest = (payload) => {
  return {
    type: GET_IMAGE_SIZE_REQUEST,
    payload
  };
};
export const getImageSizeSuccess = (payload) => {
  return {
    type: GET_IMAGE_SIZE_SUCCESS,
    payload
  };
};
export const getImageSizeFailed = (payload) => {
  return {
    type: GET_IMAGE_SIZE_FAILED,
    payload
  };
};
export const updateImageSizeRequest = (payload) => {
  return {
    type: UPDATE_IMAGE_SIZE_REQUEST,
    payload
  };
};
export const updateImageSizeSuccess = (payload) => {
  return {
    type: UPDATE_IMAGE_SIZE_SUCCESS,
    payload
  };
};
export const updateImageSizeFailed = (payload) => {
  return {
    type: UPDATE_IMAGE_SIZE_FAILED,
    payload
  };
};

export const getInvitationsListRequest = (payload) => {
  return {type: GET_INVITATION_LIST_REQUEST, payload};
};

export const getInvitationsListSuccess = (payload) => {
  return {type: GET_INVITATION_LIST_SUCCESS, payload};
};

export const getInvitationsListFailed = (payload) => {
  return {type: GET_INVITATION_LIST_FAILED, payload};
};

export const createInvitationSettingRequest = (payload) => {
  return { type: CREATE_INVITATION_SETTING_REQUEST, payload };
};

export const createInvitationSettingSuccess = (payload) => {
  return { type: CREATE_INVITATION_SETTING_SUCCESS, payload };
};

export const createInvitationSettingFailed = (payload) => {
  return { type: CREATE_INVITATION_SETTING_FAILED, payload };
};

export const getIssuedInvitationCodeListRequest = (payload) => {
  return { type: GET_ISSUED_INVITATION_CODE_LIST_REQUEST, payload };
};

export const getIssuedInvitationCodeListSuccess = (payload) => {
  return { type: GET_ISSUED_INVITATION_CODE_LIST_SUCCESS, payload };
};

export const getIssuedInvitationCodeListFailed = (payload) => {
  return { type: GET_ISSUED_INVITATION_CODE_LIST_FAILED, payload };
};

export const updateInvitationStatusRequest = (payload) => {
  return { type: UPDATE_INVITATION_STATUS_REQUEST, payload };
};

export const updateInvitationStatusSuccess = (payload) => {
  return { type: UPDATE_INVITATION_STATUS_SUCCESS, payload };
};

export const updateInvitationStatusFailed = (payload) => {
  return { type: UPDATE_INVITATION_STATUS_FAILED, payload };
};

export const deleteInvitationSettingRequest = (payload) => {
  return { type: DELETE_INVITATION_SETTING_REQUEST, payload };
};

export const deleteInvitationSettingSuccess = (payload) => {
  return { type: DELETE_INVITATION_SETTING_SUCCESS, payload };
};

export const deleteInvitationSettingFailed = (payload) => {
  return { type: DELETE_INVITATION_SETTING_FAILED, payload };
};

export const getDetailInvitationCouponRequest = (payload) => {
  return { type: GET_DETAIL_INVITATION_COUPON_REQUEST, payload };
};

export const getDetailInvitationCouponSuccess = (payload) => {
  return { type: GET_DETAIL_INVITATION_COUPON_SUCCESS, payload };
};

export const getDetailInvitationCouponFailed = (payload) => {
  return { type: GET_DETAIL_INVITATION_COUPON_FAILED, payload };
};

export const resetDetailInvitationCoupon = (payload) => {
  return { type: RESET_DETAIL_INVITATION_COUPON, payload };
};

export const getDetailInvitationCouponSettingRequest = (payload) => {
  return { type: GET_DETAIL_INVITATION_COUPON_SETTING_REQUEST, payload };
};

export const getDetailInvitationCouponSettingSuccess = (payload) => {
  return { type: GET_DETAIL_INVITATION_COUPON_SETTING_SUCCESS, payload };
};

export const getDetailInvitationCouponSettingFailed = (payload) => {
  return { type: GET_DETAIL_INVITATION_COUPON_SETTING_FAILED, payload };
};

export const getDetailIncentiveCouponSettingRequest = (payload) => {
  return { type: GET_DETAIL_INCENTIVE_COUPON_SETTING_REQUEST, payload };
};

export const getDetailIncentiveCouponSettingSuccess = (payload) => {
  return { type: GET_DETAIL_INCENTIVE_COUPON_SETTING_SUCCESS, payload };
};

export const getDetailIncentiveCouponSettingFailed = (payload) => {
  return { type: GET_DETAIL_INCENTIVE_COUPON_SETTING_FAILED, payload };
};

export const resetDetailInvitationCouponSettingRequest = (payload) => {
  return { type: RESET_DETAIL_INVITATION_COUPON_SETTING, payload };
};

export const resetDetailIncentiveCouponSettingRequest = (payload) => {
  return { type: RESET_DETAIL_INCENTIVE_COUPON_SETTING, payload };
};

// Coupon tag
export const getTagListRequest = (payload) => {
  return { type: GET_TAG_LIST_REQUEST, payload };
};
export const getTagListSuccess = (payload) => {
  return { type: GET_TAG_LIST_SUCCESS, payload };
};
export const getTagListFailed = (payload) => {
  return { type: GET_TAG_LIST_FAILED, payload };
};
export const deleteTagRequest = (payload) => {
  return { type: DELETE_TAG_REQUEST, payload };
};
export const deleteTagSuccess = () => {
  return { type: DELETE_TAG_SUCCESS };
};
export const deleteTagFailed = (payload) => {
  return { type: DELETE_TAG_FAILED, payload };
};
export const createTagRequest = (payload) => {
  return { type: CREATE_TAG_REQUEST, payload };
};
export const createTagSuccess = (payload) => {
  return { type: CREATE_TAG_SUCCESS, payload };
};
export const createTagFailed = (payload) => {
  return { type: CREATE_TAG_FAILED, payload };
};
export const updateTagRequest = (payload) => {
  return { type: UPDATE_TAG_REQUEST, payload };
};
export const updateTagSuccess = (payload) => {
  return { type: UPDATE_TAG_SUCCESS, payload };
};
export const updateTagFailed = (payload) => {
  return { type: UPDATE_TAG_FAILED, payload };
};
export const associateTagWithCouponRequest = (payload) => {
  return { type: ASSOCIATE_TAG_WITH_COUPON_REQUEST, payload };
};
export const associateTagWithCouponSuccess = (payload) => {
  return { type: ASSOCIATE_TAG_WITH_COUPON_SUCCESS, payload };
};
export const associateTagWithCouponFailed = (payload) => {
  return { type: ASSOCIATE_TAG_WITH_COUPON_FAILED, payload };
};

// Secret Key
export const getSecretKeyRequest = () => {
  return { type: GET_SECRET_KEY_REQUEST };
};
export const getSecretKeySuccess = (payload) => {
  return { type: GET_SECRET_KEY_SUCCESS, payload };
};
export const getSecretKeyFailed = (payload) => {
  return { type: GET_SECRET_KEY_FAILED, payload };
};