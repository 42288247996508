import React, { memo } from 'react';
import { Layout } from 'antd';
import PropTypes from 'prop-types';
import NavBar from './Navbar';
import './styles.less';

const Guestlayout = ({ children }) => {

  return (
    <Layout className="layout">
      <NavBar />
      {children}
    </Layout>
  );
};

Guestlayout.propTypes = {
  children: PropTypes.any
};
export default memo(Guestlayout);