import React, { memo } from 'react';
import _ from 'lodash';
import { Layout, Menu, Affix, Select, Divider } from 'antd';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './styles.less';

import helpers from 'utils/helpers';
import { renderSiteMenuBySystemRule } from './SideMenuRoutes';

const { SubMenu } = Menu;
const { Sider } = Layout;

const CustomSideMenu = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { products = [] } = useSelector(state => state.app);
  const { userLogged = {}, userServiceDomain = {} } = useSelector(state => state.auth);
  const { pathname } = location;
  const splitLocationArr = location.pathname.split('/').reduce((splitLocation, route, index) => {
    if (index > 0) {
      const slashLocation = `/${route}`;
      splitLocation.push(slashLocation);
    }
    return splitLocation;
  }, []);

  let menuRoutes = [];

  let productSelectList;
  if (!_.isEmpty(userLogged) && !_.isEmpty(products)) {
    const productCodeSelectedStore = helpers.getProductCode();

    if (!userLogged.is_super_admin) {
      const enableProductCodes = _.keys(userServiceDomain);
      productSelectList = _.filter(products, item => enableProductCodes.includes(item.code));
    } else {
      productSelectList = products;
    }

    if (!helpers.checkProductStored() ||
      !_.find(productSelectList, product => product.code === productCodeSelectedStore)
    ) {
      helpers.storeProduct(productSelectList[0]);
    }
    menuRoutes = renderSiteMenuBySystemRule({
      isSuperAdmin: userLogged.is_super_admin,
      roleCode: _.get(userServiceDomain, `${helpers.getProductCode()}.role.role_code`),
      productCode: helpers.getProductCode(),
      menuSetting: _.get(userLogged, 'setting', {}),
      couponTypeSetting: _.get(userLogged, "coupon_type_setting", {})
    });
  }

  const collapseMenu = props.collapseMenu;
  const renderMenuItems = (menuItems) => {
    const resultJSX = [];
    menuItems.forEach(menu => {
      if (_.has(menu, 'subMenu')) {
        resultJSX.push(
          <SubMenu
            key={menu.key || menu.to}
            disabled={menu.disabled}
            title={menu.text}
            icon={menu.icon}
          >
            {renderMenuItems(menu.subMenu)}
          </SubMenu>
        );
      } else {
        resultJSX.push(
          <Menu.Item
            key={menu.to}
            icon={menu.icon}
            disabled={menu.disabled}>
            {menu.text}
          </Menu.Item>
        );
      }
    });

    return resultJSX;
  };

  const onMenuItemClick = ({ keyPath }) => {
    const revkeyPath = keyPath.reverse();
    const pathString = revkeyPath.join('/');
    if (pathString !== pathname) {
      navigate(pathString);
    }
  };

  const productSelected = (value) => {
    const product = _.find(productSelectList, item => item.code === value);
    helpers.storeProduct(product);
    window.location.reload();
  };

  return (
    <Affix offsetTop={65} style={{ zIndex: 100 ,paddingBottom:65}} className='menu-wrapper'>
      <Sider
        width={props.width}
        className='aside-menu'
        trigger={null}
        collapsible
        collapsed={collapseMenu}
      >
        {!_.isEmpty(productSelectList) && (
          <div className='product-select'>
            <Select
              onChange={productSelected}
              size='large'
              bordered={false}
              defaultValue={helpers.getProductCode()}
            >
              {productSelectList.map(product =>
                <Select.Option key={product.code} value={product.code}>
                  {product.name}
                </Select.Option>
              )}
            </Select>
            <Divider style={{ margin: '10px 0' }} />
          </div>
        )}
        <Menu
          key={menuRoutes}
          mode='inline'
          className='menu'
          selectedKeys={splitLocationArr}
          defaultOpenKeys={[..._.map(menuRoutes, item => item.to), ...splitLocationArr]}
          style={{ borderRight: 0 }}
          onClick={onMenuItemClick}
        >
          {
            renderMenuItems(menuRoutes)
          }
        </Menu>
      </Sider>
    </Affix >
  );
};

CustomSideMenu.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
  collapseMenu: PropTypes.bool,
  toggleCollapseMenu: PropTypes.func,
  width: PropTypes.number
};

export default memo(CustomSideMenu);