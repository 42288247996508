/**
 * Create the store with dynamic reducers
 */

import { createStore, applyMiddleware, compose } from 'redux';
import { createReduxHistoryContext } from 'redux-first-history';
import { middleware as thunkMiddleware } from 'redux-saga-thunk';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';
import rootReducer from './rootReducer';
import rootSaga from './rootSaga';

const sagaMiddleware = createSagaMiddleware();

let store = null;

const {
  routerMiddleware,
  createReduxHistory,
  routerReducer
} = createReduxHistoryContext({ history: createBrowserHistory() });


export default function configureStore(initialState = {}) {
  const middlewares = [thunkMiddleware, sagaMiddleware, routerMiddleware];
  const enhancers = [applyMiddleware(...middlewares)];

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle */
  const composeEnhancers = process.env.NODE_ENV !== 'production' &&
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      shouldHotReload: false
    })
    : compose;
  /* eslint-enable */

  store = createStore(rootReducer(routerReducer), initialState, composeEnhancers(...enhancers));
  
  sagaMiddleware.run(rootSaga);
  const history = createReduxHistory(store);
  return {store, history};
}

//return a function that return redux store current state
export function getStore() {
  return store.getState || {};
}