import React from "react";
import PropTypes from "prop-types";
import helpers from "utils/helpers";
import _ from "lodash";
import segmentationJsonData from "./segmentation.json";
import customerSegments from "./CustomerSegments.png";
import servicerSegments from "./ServicerSegments.png";
import { Table } from "components/common";
import i18n from "i18n";
import { Col, Divider, Row } from "antd";

const RenderSegmentationTreeMap = (props) => {
  const { data, type } = props;
  const lang = helpers.getLocale();
  const segmentationData = _.get(segmentationJsonData, `${type}.${lang}`);
  const dataTable = [];
  const chartImage = type === "customer" ? customerSegments : servicerSegments;

  const segmentationDataLength = Object.keys(segmentationData).length;
  if (!_.isEmpty(data)) {
    _.forEach(data, function (value, key) {
      const val = _.get(segmentationData, _.snakeCase(value.class_name));
      dataTable.push({
        id: value.id,
        class_name: _.get(val, "title", _.startCase(value.class_name)),
        description: _.get(val, "description", ""),
        pos: _.get(val, "pos", segmentationDataLength + 1)
      });
    });
  }

  const listTable = _.sortBy(dataTable, (dataTable) => dataTable.pos);

  const TableHeader = () => {
    const columns = [
      {
        title: i18n.t("global.segmentation.segment"),
        dataIndex: "class_name",
        key: "class_name"
      },
      {
        title: i18n.t("global.segmentation.description"),
        dataIndex: "description",
        key: "description"
      }
    ];
    return columns;
  };

  return (
    <>
      {!_.isEmpty(chartImage) && (
        <>
          <Row>
            <Col span={24}>
              <img src={chartImage} style={{ width: "-webkit-fill-available" }} />
            </Col>
            <Col span={24} align="center" style={{ marginTop: "20px" }}>
              <h3>{i18n.t("content.aiSupportedTools.charts.distributionOfSegmentation")}</h3>
            </Col>
          </Row>
        </>
      )}
      <Divider />
      {!_.isEmpty(dataTable) && (
        <h3>{i18n.t("content.aiSupportedTools.charts.descriptionOfTheSegments")}</h3>
      )}
      {!_.isEmpty(dataTable) && (
        <Table
          bordered
          loading={false}
          dataSource={listTable}
          rowKey={(listTable) => listTable.id}
          columns={TableHeader()}
          pagination={false}
        />
      )}
    </>
  );
};

RenderSegmentationTreeMap.propTypes = {
  data: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired
};

export default RenderSegmentationTreeMap;
