import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { acceptInviteSuccess } from 'providers/UserProvider/actions';
import { useDispatch } from 'react-redux';

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
const cacheLocation = process.env.REACT_APP_AUTH0_CACHE_LOCATION;
const callbackUrl = process.env.REACT_APP_AUTH0_CALLBACK_URL;

// eslint-disable-next-line react/prop-types
const Auth0ProviderWithHistory = ({ children }) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const dispatch = useDispatch();

  const onRedirectCallback = (appState = {}) => {
    if (appState.isClickedInviteLink) {
      dispatch(acceptInviteSuccess());
    }
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={callbackUrl}
      audience={audience}
      useRefreshTokens={true}
      cacheLocation={cacheLocation}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;