import { combineReducers } from 'redux';
import auth from 'providers/AuthProvider/reducer';
import general from 'providers/GeneralProvider/reducer';
import app from 'providers/AppProvider/reducer';
import targetListSpool from 'providers/TargetListSpoolProvider/reducer';
import user from 'providers/UserProvider/reducer';
import aiSupport from 'providers/AISupportProvider/reducer';
import campaign from 'providers/CampaignProvider/reducer';
import bigQuery from 'providers/BigQueryProvider/reducer';
import point from 'providers/PointProvider/reducer';

export default function createReducer(routerReducer) {
  return combineReducers({
    router: routerReducer,
    app,
    auth,
    general,
    targetListSpool,
    user,
    aiSupport,
    campaign,
    bigQuery,
    point
  });
}
