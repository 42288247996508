import React from 'react';
import PropTypes from 'prop-types';
import { Column } from '@ant-design/charts';
import _ from 'lodash';
import helpers from 'utils/helpers';

const RenderColumnChart = (props) => {
  const { data, height } = props;
  let dataChart = [];

  let sum = 0;
  let score = [];
  let count = 5;
  let max = 0;

  if (!_.isEmpty(data)) {
    _.forEach(data, function(value) {
      score.push(value.score);
      const total =  value.total;
      sum += total 
      if (total > max) {
        max = total
      }
      dataChart.push({
        score: value.score.toString(),
        total: value.total,
      });
    });
    
    for (let i = 1; i <= count; i++) {
      if (score.indexOf(i) === -1) {
        const item = {
          score: i.toString(),
          total: 0,
        };
        dataChart.push(item);
      }
    }
  }
  dataChart = _.sortBy(dataChart, (item) => item.score);
  const chartConfig = {
    data: dataChart,
    xField: 'score',
    yField: 'total',
    label: {
      offsetY: 42,
      position: 'top',
      style: {
        fill: 'white'
      },
      content: (item) => {
        if (item.total === 0) {
          return '';
        }   
        const percent = helpers.calculatePercentage(item.total, sum)
        return item.total + '\n(' + percent + '%)';
      },
    },
    color: (item) => {
      const maxItem = _.maxBy(dataChart, 'total');
      if (maxItem.score === item.score) {  
        return '#b22222';
      }
      return '#c0c0c0'; 
    },
    legend: false,
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
        style: {
          fill: 'black'
        }
      },
      line: null,
      tickLine: null
    },
    yAxis: {
      visible: false,
      grid: null
    },
    height: height,
    meta: {
      total: {
        maxLimit: max,
      },
    },
    tooltip: {
      formatter: (item) => {
        const percent = helpers.calculatePercentage(item.total, sum);
        const value =  item.total + ` (${percent}%)`;
        return { name: item.score, value: value };
      },
    }
  };
  
  return (
    <>
      {!_.isEmpty(dataChart) && <Column {...chartConfig} />}
    </>
  );
};

RenderColumnChart.propTypes = {
  data: PropTypes.array.isRequired,
  height: PropTypes.number
};

RenderColumnChart.defaultProps = {
  height: 200
};

export default RenderColumnChart;
