import {
  TOGGLE_COLLAPSE_MENU,
  SET_POPUP, 
  SET_CHANGE_PASSWORD_MODAL, 
  SET_CREATE_AGENT_MODAL,
  SET_EDIT_PROFILE_MODAL,
  SET_STORE_DETAIL_MODAL
} from './constants';

export const toggleCollapseMenu = (payload) => ({
  type: TOGGLE_COLLAPSE_MENU,
  payload
});

export const setPopup = (payload) => ({
  type: SET_POPUP,
  payload
});

export const setChangePasswordModal = (payload) => ({
  type: SET_CHANGE_PASSWORD_MODAL,
  payload
});

export const setCreateAgentModal = (payload) => ({
  type: SET_CREATE_AGENT_MODAL,
  payload
});

export const setEditProfileModal = (payload) => ({
  type: SET_EDIT_PROFILE_MODAL,
  payload
});

export const setStoreDetailModal = (payload) => ({
  type: SET_STORE_DETAIL_MODAL,
  payload
});