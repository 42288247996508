import {
    ERROR,
    GET_TARGER_LIST_TEMPLATE_REQUEST,
    GET_TARGER_LIST_TEMPLATE_SUCCESS,
    GET_TARGER_LIST_TEMPLATE_FAILED,
    RUN_TARGET_TEMPLATE_REQUEST,
    RUN_TARGET_TEMPLATE_SUCCESS,
    RUN_TARGET_TEMPLATE_FAILED,
    GET_FILTER_VALUE_REQUEST,
    GET_FILTER_VALUE_SUCCESS,
    EXPORT_REQUEST,
    EXPORT_SUCCESS,
    EXPORT_FAILED,
    SAVE_TARGET_REQUEST,
    SAVE_TARGET_SUCCESS,
    SAVE_TARGET_FAILED,
    GET_TARGET_LIST_SPOOL_LOG_REQUEST,
    GET_TARGET_LIST_SPOOL_LOG_SUCCESS,
    GET_TARGET_LIST_SPOOLED_REQUEST,
    GET_TARGET_LIST_SPOOLED_SUCCESS,
    GET_TARGET_LIST_SPOOLED_FAILED,
    EXPORT_TARGET_SPOOL_LOG_LIST_REQUEST,
    EXPORT_TARGET_SPOOL_LOG_LIST_SUCCESS,
    EXPORT_TARGET_SPOOL_LOG_LIST_FAILED,
    EXPORT_TARGET_SPOOL_LOG_DETAIL_REQUEST,
    EXPORT_TARGET_SPOOL_LOG_DETAIL_SUCCESS,
    EXPORT_TARGET_SPOOL_LOG_DETAIL_FAILED,
    CREATE_NEW_SENDER_REQUEST,
    CREATE_NEW_SENDER_SUCCESS,
    CREATE_NEW_SENDER_FAILED,
    GET_SENDER_LIST_REQUEST,
    GET_SENDER_LIST_SUCCESS,
    GET_SENDER_LIST_FAILED,
    DELETE_SENDER_REQUEST,
    DELETE_SENDER_SUCCESS,
    DELETE_SENDER_FAILED,
    CREATE_SINGLE_SEND_REQUEST,
    CREATE_SINGLE_SEND_SUCCESS,
    CREATE_SINGLE_SEND_FAILED,
    GET_SENDER_SELECT_LIST_REQUEST,
    GET_SENDER_SELECT_LIST_SUCCESS,
    GET_SENDER_SELECT_LIST_FAILED,
    GET_SINGLE_SEND_LIST_REQUEST,
    GET_SINGLE_SEND_LIST_SUCCESS,
    GET_SINGLE_SEND_LIST_FAILED,
    DELETE_SINGLE_SEND_REQUEST,
    DELETE_SINGLE_SEND_FAILED,
    DELETE_SINGLE_SEND_SUCCESS,
    CREATE_EMAIL_TEMPLATE_REQUEST,
    CREATE_EMAIL_TEMPLATE_SUCCESS,
    CREATE_EMAIL_TEMPLATE_FAILED,
    GET_TARGET_LIST_EMAIL_TEMPLATE_REQUEST,
    GET_TARGET_LIST_EMAIL_TEMPLATE_SUCCESS,
    UPDATE_EMAIL_TEMPLATE_REQUEST,
    UPDATE_EMAIL_TEMPLATE_SUCCESS,
    DELETE_EMAIL_TEMPLATE_REQUEST,
    CANCEL_LOADING_TABLE_EMAIL_TEMPLATE,
    GET_DETAIL_EMAIL_TEMPLATE_REQUEST,
    GET_DETAIL_EMAIL_TEMPLATE_SUCCESS,
    GET_COPY_EMAIL_TEMPLATE_SUCCESS,
    GET_EDIT_EMAIL_TEMPLATE_SUCCESS,
    RESET_EMAIL_DETAIL_TEMPLATE,
    GET_SEND_SCHEDULE_LIST_REQUEST,
    GET_SEND_SCHEDULE_LIST_SUCCESS,
    CREATE_SEND_SCHEDULE_REQUEST,
    CREATE_SEND_SCHEDULE_SUCCESS,
    RESET_TARGER_LIST_TEMPLATE,
    GET_DETAIL_SEND_SCHEDULE_REQUEST,
    GET_COPY_SEND_SCHEDULE_SUCCESS,
    RESET_SEND_SCHEDULE,
    RESET_TARGET_TEMPLATE_RESULT,
    UPDATE_STATUS_SEND_SCHEDULE_REQUEST,
    GET_DETAIL_SEND_SCHEDULE_SUCCESS,
    CREATE_SEND_SCHEDULE_FAILED,
    GET_DETAIL_EMAIL_TEMPLATE_SCHEDULE_SUCCESS, RESET_EMAIL_DETAIL_TEMPLATE_SCHEDULE
} from './constants';

export const error = (payload) => {
    return {type: ERROR, payload};
};

export const getTargetListTemplateRequest = (payload) => {
    return {type: GET_TARGER_LIST_TEMPLATE_REQUEST, payload};
};
export const resetTargetListTemplate = (payload) => {
    return {type: RESET_TARGER_LIST_TEMPLATE, payload};
};
export const getTargetListTemplateSuccess = (payload) => {
    return { type: GET_TARGER_LIST_TEMPLATE_SUCCESS, payload};
};

export const getTargetListTemplateFailed = () => {
    return {type: GET_TARGER_LIST_TEMPLATE_FAILED};
};

export const runTargetTemplateRequest = (payload) => {
    return {type: RUN_TARGET_TEMPLATE_REQUEST, payload};
};

export const runTargetTemplateSuccess = (payload) => {
    return {type: RUN_TARGET_TEMPLATE_SUCCESS, payload};
};

export const runTargetTemplateFailed = () => {
    return {type: RUN_TARGET_TEMPLATE_FAILED};
};

export const getFilterValueRequest = (payload) => {
    return {type: GET_FILTER_VALUE_REQUEST, payload};
};

export const getFilterValueSuccess = (payload) => {
    return {type: GET_FILTER_VALUE_SUCCESS, payload};
};

export const exportRequest = (payload) => {
    return {type: EXPORT_REQUEST, payload};
};

export const exportSuccess = (payload) => {
    return {type: EXPORT_SUCCESS, payload};
};

export const exportFailed = () => {
    return {type: EXPORT_FAILED};
};

export const saveTargetRequest = (payload) => {
    return {type: SAVE_TARGET_REQUEST, payload};
};

export const saveTargetSuccess = (payload) => {
    return {type: SAVE_TARGET_SUCCESS, payload};
};

export const saveTargetFailed = (payload) => {
    return {type: SAVE_TARGET_FAILED, payload};
};

export const getTargetListSpoolLogRequest = (payload) => {
    return {type: GET_TARGET_LIST_SPOOL_LOG_REQUEST, payload};
};

export const getTargetListSpoolLogSuccess = (payload) => {
    return {type: GET_TARGET_LIST_SPOOL_LOG_SUCCESS, payload};
};

export const getTargetListSpooledRequest = (payload) => {
    return {type: GET_TARGET_LIST_SPOOLED_REQUEST, payload};
};

export const getTargetListSpooledSuccess = (payload) => {
    return {type: GET_TARGET_LIST_SPOOLED_SUCCESS, payload};
};

export const getTargetListSpooledFailed = () => {
    return {type: GET_TARGET_LIST_SPOOLED_FAILED};
};

export const exportTargetLogListRequest = (payload) => {
    return {type: EXPORT_TARGET_SPOOL_LOG_LIST_REQUEST, payload};
};

export const exportTargetLogListSuccess = (payload) => {
    return {type: EXPORT_TARGET_SPOOL_LOG_LIST_SUCCESS, payload};
};

export const exportTargetLogListFailed = () => {
    return {type: EXPORT_TARGET_SPOOL_LOG_LIST_FAILED};
};

export const exportTargetLogDetailRequest = (payload) => {
    return {type: EXPORT_TARGET_SPOOL_LOG_DETAIL_REQUEST, payload};
};

export const exportTargetLogDetailSuccess = (payload) => {
    return {type: EXPORT_TARGET_SPOOL_LOG_DETAIL_SUCCESS, payload};
};

export const exportTargetLogDetailFailed = () => {
    return {type: EXPORT_TARGET_SPOOL_LOG_DETAIL_FAILED};
};

export const createNewSenderRequest = (payload) => {
    return {type: CREATE_NEW_SENDER_REQUEST, payload};
};

export const createNewSenderSuccess = (payload) => {
    return {type: CREATE_NEW_SENDER_SUCCESS, payload};
};

export const createNewSenderFailed = (payload) => {
    return {type: CREATE_NEW_SENDER_FAILED, payload};
};

export const getSenderListRequest = (payload) => {
    return {type: GET_SENDER_LIST_REQUEST, payload};
};

export const getSenderListSuccess = (payload) => {
    return {type: GET_SENDER_LIST_SUCCESS, payload};
};

export const getSenderListFailed = () => {
    return {type: GET_SENDER_LIST_FAILED};
};

export const deleteSenderRequest = (payload) => {
    return {type: DELETE_SENDER_REQUEST, payload};
};

export const deleteSenderSuccess = (payload) => {
    return {type: DELETE_SENDER_SUCCESS, payload};
};

export const deleteSenderFailed = () => {
    return {type: DELETE_SENDER_FAILED};
};

export const createSingleSendRequest = (payload) => {
    return {type: CREATE_SINGLE_SEND_REQUEST, payload};
};

export const createSingleSendSuccess = (payload) => {
    return {type: CREATE_SINGLE_SEND_SUCCESS, payload};
};

export const createSingleSendFailed = (payload) => {
    return {type: CREATE_SINGLE_SEND_FAILED, payload};
};

export const getSendScheduleRequest = (payload) => {
    return {type: GET_SEND_SCHEDULE_LIST_REQUEST, payload};
};
export const getSendScheduleSuccess = (payload) => {
    return {type: GET_SEND_SCHEDULE_LIST_SUCCESS, payload};
};
export const getTargetListEmailTemplateRequest = (payload) => {
    return {type: GET_TARGET_LIST_EMAIL_TEMPLATE_REQUEST, payload};
};

export const cancelLoadingTableEmailTemplate = (payload) => {
    return {type: CANCEL_LOADING_TABLE_EMAIL_TEMPLATE, payload};
};

export const getTargetListEmailTemplateSuccess = (payload) => {
    return {type: GET_TARGET_LIST_EMAIL_TEMPLATE_SUCCESS, payload};
};

export const getEditEmailTemplateSuccess = (payload) => {
    return {type: GET_EDIT_EMAIL_TEMPLATE_SUCCESS, payload};
};

export const getDetailEmailTemplateSuccess = (payload) => {
    return {type: GET_DETAIL_EMAIL_TEMPLATE_SUCCESS, payload};
};
export const getEditEmailTemplateScheduleSuccess = (payload) => {
    return {type: GET_DETAIL_EMAIL_TEMPLATE_SCHEDULE_SUCCESS, payload};
};

export const getCopyEmailTemplateSuccess = (payload) => {
    return {type: GET_COPY_EMAIL_TEMPLATE_SUCCESS, payload};
};
export const getCopySendScheduleSuccess = (payload) => {
    return {type: GET_COPY_SEND_SCHEDULE_SUCCESS, payload};
};
export const getDetailSendScheduleSuccess = (payload) => {
    return {type: GET_DETAIL_SEND_SCHEDULE_SUCCESS, payload};
};
export const createEmailTemplateRequest = (payload) => {
    return {type: CREATE_EMAIL_TEMPLATE_REQUEST, payload};
};

export const createEmailTemplateSuccess = (payload) => {
    return {type: CREATE_EMAIL_TEMPLATE_SUCCESS, payload};
};
export const createSendScheduleRequest = (payload) => {
    return {type: CREATE_SEND_SCHEDULE_REQUEST, payload};
};

export const createSendScheduleSuccess = (payload) => {
    return {type: CREATE_SEND_SCHEDULE_SUCCESS, payload};
};

export const getDetailEmailTemplateRequest = (payload) => {
    return {type: GET_DETAIL_EMAIL_TEMPLATE_REQUEST, payload};
};
export const getDetailSendScheduleRequest= (payload) => {
    return {type: GET_DETAIL_SEND_SCHEDULE_REQUEST, payload};
};
export const createEmailTemplateFailed = (payload) => {
    return {type: CREATE_EMAIL_TEMPLATE_FAILED, payload};
};
export const createSendScheduleFailed = (payload) => {
    return {type: CREATE_SEND_SCHEDULE_FAILED, payload};
};

export const updateEmailTemplateRequest = (payload) => {
    return {type: UPDATE_EMAIL_TEMPLATE_REQUEST, payload};
};

export const updateEmailTemplateSuccess = (payload) => {
    return {type: UPDATE_EMAIL_TEMPLATE_SUCCESS, payload};
};

export const deleteEmailTemplateRequest = (payload) => {
    return {type: DELETE_EMAIL_TEMPLATE_REQUEST, payload};
};

export const getSenderSelectListRequest = (payload) => {
    return {type: GET_SENDER_SELECT_LIST_REQUEST, payload};
};

export const getSenderSelectListSuccess = (payload) => {
    return {type: GET_SENDER_SELECT_LIST_SUCCESS, payload};
};

export const getSenderSelectListFailed = () => {
    return {type: GET_SENDER_SELECT_LIST_FAILED};
};

export const getSingleSendListRequest = (payload) => {
    return {type: GET_SINGLE_SEND_LIST_REQUEST, payload};
};

export const getSingleSendListSuccess = (payload) => {
    return {type: GET_SINGLE_SEND_LIST_SUCCESS, payload};
};

export const getSingleSendListFailed = (payload) => {
    return {type: GET_SINGLE_SEND_LIST_FAILED, payload};
};

export const deleteSingleSendRequest = (payload) => {
    return {type: DELETE_SINGLE_SEND_REQUEST, payload};
};

export const deleteSingleSendSuccess = (payload) => {
    return {type: DELETE_SINGLE_SEND_SUCCESS, payload};
};

export const deleteSingleSendFailed = (payload) => {
    return {type: DELETE_SINGLE_SEND_FAILED, payload};
};
export const resetDetailEmailTemplate = (payload) => {
    return {type: RESET_EMAIL_DETAIL_TEMPLATE, payload};
};
export const resetDetailEmailTemplateSchedule = (payload) => {
    return {type: RESET_EMAIL_DETAIL_TEMPLATE_SCHEDULE, payload};
};
export const resetSendSchedule = (payload) => {
    return {type: RESET_SEND_SCHEDULE, payload};
};
export const resetTargetTemplateResult = (payload) => {
    return {type: RESET_TARGET_TEMPLATE_RESULT, payload};
};
export const updateStatusSendSchedule = (payload) => {
    return {type: UPDATE_STATUS_SEND_SCHEDULE_REQUEST, payload};
};