import {notification} from 'antd';
import i18n from 'i18n';
import _ from 'lodash';
import moment from 'moment-timezone';

import constants from 'utils/constants';
import crypto from 'utils/crypto';
import request from "./api";

const Helper = {
  canUseCouponBySetting: (userLogged)  => {
    const {setting, is_super_admin} = userLogged
    return _.get(setting, 'campaign.coupon') === 'on' || is_super_admin == 1
  },

  getGuestToken: () => {
    return localStorage.getItem('guest_token');
  },

  storeGuestToken: (token) => {
    return localStorage.setItem('guest_token', token);
  },

  removeGuestToken: (token) => {
    return localStorage.removeItem('guest_token');
  },

  getAuthToken: () => {
    return localStorage.getItem('access_token');
  },

  storeAuthToken: (token) => {
    return localStorage.setItem('access_token', token);
  },

  getRefreshToken: () => {
    return localStorage.getItem('refresh_token');
  },

  storeRefreshToken: (refreshToken) => {
    return localStorage.setItem('refresh_token', refreshToken);
  },
  getUserId: () => {
    const userId = crypto.decryptAES(localStorage.getItem('sign') || '');
    if (!userId) {
      return null;
    }
    return parseInt(userId);
  },

  storeUserId: (id) => {
    const encryptId = crypto.encryptAES(id);
    return localStorage.setItem('sign', encryptId);
  },

  storeProduct: (product = {}) => {
    const encryptCode = crypto.encryptAES(product.code);
    const encryptId = crypto.encryptAES(product.id);
    localStorage.setItem("p_code", encryptCode);
    localStorage.setItem("p_id", encryptId);
    localStorage.setItem("p_name", product.name);
    if (product.services) {
      localStorage.setItem("p_services", JSON.stringify(product.services));
    }
    return true;
  },

  storeProductCode: (code) => {
    const encryptId = crypto.encryptAES(code);
    return localStorage.setItem('p_code', encryptId);
  },

  storeProductId: (id) => {
    const encryptId = crypto.encryptAES(id);
    return localStorage.setItem('p_id', encryptId);
  },

  getProductCode: () => {
    const productCode = crypto.decryptAES(localStorage.getItem('p_code') || '');
    return productCode;
  },

  getProductId: () => {
    const productId = crypto.decryptAES(localStorage.getItem('p_id') || '');

    if (!productId) {
      return null;
    }
    return parseInt(productId);
  },

  getProductName: () => {
    return localStorage.getItem('p_name');
  },

  checkProductStored: () => {
    const isStored = localStorage.getItem('p_name') && localStorage.getItem('p_code') && localStorage.getItem('p_id');
    return isStored;
  },

  getClickInviteLink: () => {
    return localStorage.getItem('invite_link_clicked');
  },

  storeClickInviteLink: () => {
    return localStorage.setItem('invite_link_clicked', true);
  },

  removeClickInviteLink: () => {
    return localStorage.removeItem('invite_link_clicked');
  },

  getLocale: () => {
    return localStorage.getItem('locale') || 'en';
  },

  setLocale: (locale) => {
    return localStorage.setItem('locale', locale);
  },

  isGuest: () => {
    return !localStorage.getItem('access_token') || !localStorage.getItem('refresh_token') || !localStorage.getItem('sign');
  },

  clearLocalStorage: (clearAll = true) => {
    const locale = localStorage.getItem('locale');
    const seletedProductCode = localStorage.getItem('p_code');
    const seletedProductId = localStorage.getItem('p_id');
    const seletedProductName = localStorage.getItem('p_name');

    localStorage.clear();

    if (!clearAll) {
      if (locale) {
        localStorage.setItem('locale', locale);
      }
      if (seletedProductCode) {
        localStorage.setItem('p_code', seletedProductCode);
      }
      if (seletedProductId) {
        localStorage.setItem('p_id', seletedProductId);
      }
      if (seletedProductName) {
        localStorage.setItem('p_name', seletedProductName);
      }
    }
    
    return true;
  },

  notify: (message) => {
    notification.success({
      message,
      duration: 2
    });
  },

  uploadFile: async (resData) => {
    if (resData) {
      try {
        const requestBody = Helper.requestBodyCoupon('post', 'image/upload', resData)
        return await request( '/coupons', 'post', requestBody);
      } catch (e) {
        console.log(e);
        return null;
      }
    }
    return null;
  },
  toastr: (description, message, type = 'success') => {
    const content = {
      message: message,
      description: description
    };
    if (type === 'success') {
      notification.success(content);
    } else if (type === 'error') {
      notification.error(content);
    }
  },
  arrayToString: (array = []) => {
    return array.map(item => item).join(', ');
  },
  convertColumnTitle: (columnName = '') => {
    const words = columnName.split('_');
    let title = '';
    words.forEach(word => {
      title += `${word.charAt(0).toUpperCase()}${word.slice(1)} `;
    });

    return title;
  },
  getApiResponseErrorMessage: (code) => {
    if (!code) {
      return null;
    }
    return i18n.t(`apiResponseMessage.${code}`, { defaultValue: constants.DEFAULT_VALIDATE_FAIL_MESSAGE });
  },
  convertQueryParamToObj: (location) => {
    const search = location.search.substring(1);
    const queryObj = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');
    return queryObj;
  },
  convertCountryCode: (code) => {
    let countryCode;
    switch (code) {
      case 'en':
        countryCode = 'GB';
        break;
      case 'ja':
        countryCode = 'JP';
        break;
      default:
        countryCode = 'GB';
        break;
    }

    return countryCode;
  },
  checkDisableRoute: (input) => {
    const { isSuperAdmin, roleCode, productCode, path } = input;

    const adminRoles = [constants.ROLE.CODE.ADMINISTRATOR];
    if (!isSuperAdmin && !adminRoles.includes(roleCode) && path.includes(constants.PATH.USER)) {
      return true;
    }

    return false;
  },
  deepCloneObject: (input) => {
    return JSON.parse(JSON.stringify(input));
  },
  formatSearchValues: (input) => {
    const { searchValues = {}, searchOp = null, searchField = {} } = input;
    const searchConditions = [];
    const filterConditions = [];
    for (const key in searchValues.search) {
      let searchType = searchOp;
      if (searchField[key]) {
        searchType = searchField[key];
      }
      if (searchValues.search[key]) {
        searchConditions.push({
          field: key,
          op: searchType || 'like',
          val: searchValues.search[key]?.trim()
        });
      }
    }

    for (const key in searchValues.filter) {
      if (searchValues.filter[key]) {
        filterConditions.push({
          field: key,
          val: searchValues.filter[key]
        });
      }
    }
    return { searchConditions, filterConditions };
  },
  formatSearchValuesCustom: (search) => {
    const searchConditions = [];
    const filterConditions = [];
    for (const key in search.search) {
      if (search.search[key]) {
        searchConditions.push({
          field: key,
          op: 'like',
          val: search.search[key]?.trim()
        });
      }
    }

    for (const key in search.filter) {
      if (search.filter[key]) {
        filterConditions.push({
          field: key,
          val: search.filter[key]
        });
      }
    }
    return { searchConditions, filterConditions };
  },
  defaultSearchValue: (input) => {
    const {rowData = {}, searchList = {}} = input;
    rowData.search={};
    for (const key in searchList.search) {
      if(searchList.search[key]) {
        rowData.search[key] = searchList.search[key];
      }
    }
    for (const key in searchList.filter) {
      if(searchList.filter[key]) {
        rowData.search[key] = searchList.filter[key];
      }
    }

    return rowData;
  },
  formatSortPagination: (input) => {
    const { sorter = {}, currentSort = {} } = input;
    let { page } = input;
    let order;
    let newSort = [];

    if (_.get(sorter, 'order')) {
      order = sorter.order.replace('end', '');
      newSort = [
        {
          field: sorter.field,
          type: order
        }
      ];
    }

    if (_.get(currentSort, 'type') !== order ||
      (_.get(currentSort, 'field') && (_.get(currentSort, 'field') !== sorter.field))
    ) {
      page = 1;
    }

    return { page, sorter: newSort };
  },
  formatSortPaginationForPoint(input) {
    const { page, sorter } = this.formatSortPagination(input);
    let sorterPoint = '';
    if (!_.isEmpty(sorter)) {
      sorterPoint = `${sorter[0].field}:${sorter[0].type}`;
    }
    return { page , sorterPoint};
  },
  checkIsCustomer: (text) => {
    if (text == constants.NAME.customer_en || text == constants.NAME.customer_jp) {
      return true;
    }
    return false;
  },
  convertEnToJaTargetType: (text) => {
    if (localStorage.getItem('locale') == 'ja') {

      const typeArr = {'customer': 'カスタマー', 'servicer': 'サービサー'}
      return typeArr[text];
    }
    return text;
  },
  filterEmptyArray(arrData) {
    arrData = arrData.map(s => s.trim());
    arrData = arrData.filter(function (el) {
      return el != ''
    });
    return arrData;
  },
  requestBodyCoupon(method, url, data={}) {
    return {
      method: method,
      path: url,
      data: data,
      app_id: constants.COUPON_SERVICE_APP_ID[Helper.getProductNameEN()]
    }
  },
  requestBodyPoint(method, url, data = {}, isDownload = false) {
    return {
      method: method,
      path: url,
      data: data,
      isDownload: isDownload
    }
  },
  requestBodyImageSize(method, url, data = {}) {
    return {
      method: method,
      path: url,
      data: data
    }
  },
  getProductNameEN() {
    const productName=localStorage.getItem('p_name');
    switch (productName) {
      case 'ホググ':
        return 'Hogugu'
      case 'ネイリー':
        return 'Nailie'
      case 'タノメル': 
        return 'Tanomel'  
      default:
        return productName
    }
  },
  parseDataDateTime (data) {
    const requestData = JSON.parse(JSON.stringify(data))
    const dateTypeList = [constants.DATA_TYPE.DATETIME, constants.DATA_TYPE.DATETIME_START, constants.DATA_TYPE.DATETIME_END]
    _.forEach(_.get(requestData, 'conditions.properties', []), (item, index) => {
      const type = _.get(item, 'type')
      let val = ''
      if (dateTypeList.includes(type)) {
        val = moment(item.val).format(constants.FORMAT.CAMPAIGN_DATETIME_FORMAT);
        item['val'] = this.getMomentTimeZonetoUTC(val)
        requestData['conditions']['properties'][index] = item
      }
    })

    _.forEach(_.get(requestData, 'extra_conditions', []), (extras, extraKey) => {
      _.forEach(_.get(extras, 'properties', []), (item, index) => {
        const type = _.get(item, 'type')
        let valExtra = ''
        if (dateTypeList.includes(type)) {
          valExtra = moment(item.val).format(constants.FORMAT.CAMPAIGN_DATETIME_FORMAT);
          item['val'] = this.getMomentTimeZonetoUTC(valExtra)
          requestData['extra_conditions'][extraKey]['properties'][index] = item
        }
      })
    })
    return requestData
  },

  getMomentUTC (dateTime, format = null) {
    return dateTime
        ? moment(dateTime)
            .utc()
            .format(format || constants.FORMAT.CAMPAIGN_DATETIME_FORMAT)
        : moment()
            .utc()
          .format(format || constants.FORMAT.CAMPAIGN_DATETIME_FORMAT);
  },
  
  convertUTCtoZone(date, zone = constants.TIME_ZONE_JP) {
    return moment.tz(moment.utc(date), zone);
  },

  getMomentTimeZonetoUTC(
    data,
    format = constants.FORMAT.CAMPAIGN_DATETIME_FORMAT,
    timezone = constants.TIME_ZONE_JP
  ) {
    return moment.tz(data, timezone).utc().format(format);
  },

  formatSearchRegisterDayValue(searchConditions, valRegisterDate, includeParamKey = false) {
    searchConditions = searchConditions.filter(item => item.field !== 'register_date')
    searchConditions.push({
      field: 'register_date',
      op: '>=',
      val: this.getMomentTimeZonetoUTC(valRegisterDate + ' 00:00:00')
    }, {
      field: 'register_date',
      op: '<=',
      val: this.getMomentTimeZonetoUTC(valRegisterDate + ' 23:59:59')
    });

    if (includeParamKey) {
      _.map(searchConditions, (item, index) => {
        if (_.get(item, 'field') === 'register_date') {
          searchConditions[index] = {
            ...item,
            key: 'search'
          }
        }
      })
    }
    return searchConditions;
  },
  getCouponConditionType: () => {
    const result = {};
    _.forEach(constants.PUBLIC_COUPON_CONDITION, (item) => {
      result[item.key] = item.key;
    });
    _.forEach(constants.PRIVATE_COUPON_CONDITION, (item) => {
      result[item.key] = item.key;
    });
    _.forEach(constants.ADD_ON_COUPON_CONDITION, (item) => {
      result[item.key] = item.key;
    });

    return result;
  },
  getCouponConditionName: () => {
    const result = {};
    _.forEach(constants.PUBLIC_COUPON_CONDITION, (item) => {
      result[item.key] = item.name;
    });
    _.forEach(constants.PRIVATE_COUPON_CONDITION, (item) => {
      result[item.key] = item.name;
    });
    _.forEach(constants.ADD_ON_COUPON_CONDITION, (item) => {
      result[item.key] = item.name;
    });

    return result;
  },
  getInvitationCouponType: () => {
    const result = {};
    _.forEach(constants.INVITATION_COUPON_CONDITION, (item) => {
      result[item.key] = item.key;
    });
    _.forEach(constants.ADD_ON_COUPON_CONDITION, (item) => {
      result[item.key] = item.name;
    });
    return result;
  },
  getInvitationCouponName: () => {
    const result = {}
    _.forEach(constants.INVITATION_COUPON_CONDITION, (item) => {
      result[item.key] = item.name;
    });
    _.forEach(constants.ADD_ON_COUPON_CONDITION, (item) => {
      result[item.key] = item.name;
     });
    return result;
  },
  calculatePercentage: (val, sum, fixed = 0) => {
    const percentage = ((val / sum) * 100).toFixed(fixed);
    return percentage;
  },
  getPointPath(path) {
    const code = this.getProductCode();
    const storage = localStorage.getItem("p_services") || '[]';
    let services = []
    try {
      services = JSON.parse(storage)
    } catch(e) {}
    const service = _.find(services, (item) => _.get(item, 'service_id') === constants.REGISTER_SERVICE.POINT)
    return path.replace(":product", _.get(service, 'code') || code);
  },
  getPointProductCode(products) {
    const code = this.getProductCode();
    const productItem = _.find(products, (item) => item.code === code);
    const service = _.find(productItem, (item) => _.get(item, 'service_id') === constants.REGISTER_SERVICE.POINT)
    return _.get(service, 'code') || code;
  },
  getProductNameByLang(products) {
    let productNameByLang = '';
    const productCode = this.getProductCode();
    const lang = this.getLocale();
    const productItem = _.find(products, item => item.code === productCode);
    if (!_.isUndefined(productItem)) {
      productNameByLang = lang === 'en' ? productItem.code : productItem.name;
    }
    return productNameByLang;
  },
  useBigQuery() {
    return process.env.REACT_APP_USE_BIG_QUERY == 1 
  },
  customDataCoupon(dataCoupon, conditionArr, type) {
    const newDataCoupon = {...dataCoupon};
    const INVITATION_COUPON_CONDITION_NAME = this.getInvitationCouponName();
    const INVITATION_COUPON_CONDITION_TYPE = this.getInvitationCouponType();
    for (const key in conditionArr) {
      let value = _.isString(conditionArr[key]) ? conditionArr[key]?.trim() : conditionArr[key];
      if (
        [
          "min",
          "quantumIssue",
          "limitPerUser",
          "issueCouponNumber",
          "availableDays",
          "maxServiceUsageForIssue",
          "minServiceUsageForIssue",
          "maxDiscountAmount"
      ].includes(key)
      ) {
        if (isNaN(value)) {
          notification.error({
            message: INVITATION_COUPON_CONDITION_NAME[key] + ' is not a number',
            duration: 3
          });
          return false;
        }
      }
      switch (key) {
        case INVITATION_COUPON_CONDITION_TYPE.maxDiscountAmount:
          if (dataCoupon.coupon.currency === '%') {
            _.set(dataCoupon.coupon.rules, key, value);
          }
          break;  
        case INVITATION_COUPON_CONDITION_TYPE.allowServicerIds:
          newDataCoupon[type]['rules']['allowServicerIds'] = value ? value?.split(',') : value;
          if (_.isArray(newDataCoupon[type]['rules']['allowServicerIds'])) {
            newDataCoupon[type]['rules']['allowServicerIds'] = this.filterEmptyArray(newDataCoupon[type]['rules']['allowServicerIds']);
          }
          break;
        case INVITATION_COUPON_CONDITION_TYPE.allowCustomerIds:
          newDataCoupon[type]['rules']['allowCustomerIds'] = value ? value?.split(',') : value;
          if (_.isArray(newDataCoupon[type]['rules']['allowCustomerIds'])) {
            newDataCoupon[type]['rules']['allowCustomerIds'] = this.filterEmptyArray(newDataCoupon[type]['rules']['allowCustomerIds']);
          }
          break;
        case INVITATION_COUPON_CONDITION_TYPE.endIssued:
          newDataCoupon[type]['rules']['endIssued'] = moment.tz(value.format(constants.FORMAT.COUPON_DATETIME_END_FORMAT), constants.TIME_ZONE_JP).toISOString();
          break;
        case INVITATION_COUPON_CONDITION_TYPE.startIssue:
          newDataCoupon[type]['rules']['startIssue'] = moment.tz(value.format(constants.FORMAT.COUPON_DATETIME_START_FORMAT), constants.TIME_ZONE_JP).toISOString();
          break;
        default:
          if (_.get(INVITATION_COUPON_CONDITION_TYPE, key, '')) {
            _.set(newDataCoupon[type]['rules'], key, value);
          }
          break;
      }
    }
    return newDataCoupon;
  }
};

export default Helper;
