import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import constants from 'utils/constants';
import helpers from 'utils/helpers';

const RenderItemUrl = (props) => {
  const {
    value,
    servicerOriginalId,
    itemOriginalId
  } = props;
  const productCode = helpers.getProductCode();
  let servicerDetailUrl = _.get(constants.SERVICER_DETAIL_URL, productCode, '');
  const renderValue = () => {
    if (!_.isEmpty(servicerDetailUrl) && (itemOriginalId.length > 0)) {
      servicerDetailUrl += `/${servicerOriginalId}/posts/${itemOriginalId}`;
      return (
        <a href={servicerDetailUrl} target="_blank" rel="noopener noreferrer">
          {value}
        </a>
      );
    } else {
      return (
        <span>{value}</span>
      );
    }
  };
  return (
    <>
      {renderValue()}
    </>
  );
};

RenderItemUrl.propTypes = {
  value: PropTypes.string.isRequired,
  servicerOriginalId: PropTypes.string,
  itemOriginalId: PropTypes.string
};

RenderItemUrl.defaultProps = {
  servicerOriginalId: '',
  itemOriginalId: ''
};

export default RenderItemUrl;
