import {call, put, takeLeading} from 'redux-saga/effects';
import _ from 'lodash';

import helpers from 'utils/helpers';
import {safe} from 'providers/GeneralProvider/saga';
import {
  COUPON_DELETE_REQUEST,
  COUPON_UPDATE_STATUS_REQUEST,
  CREATE_COUPON_REQUEST,
  GET_COUPON_LIST_REQUEST,
  GET_DETAIL_COUPON_REQUEST,
  GET_DETAIL_ISSUE_COUPON_REQUEST,
  GET_DETAIL_USAGE_COUPON_REQUEST,
  UPDATE_COUPON_REQUEST,
  COUPON_TYPE_SETTING_UPDATE_REQUEST,
  GET_COUPON_TYPE_SETTING_REQUEST,
  GET_IMAGE_SIZE_REQUEST,
  UPDATE_IMAGE_SIZE_REQUEST,
  GET_INVITATION_LIST_REQUEST,
  CREATE_INVITATION_SETTING_REQUEST,
  GET_ISSUED_INVITATION_CODE_LIST_REQUEST,
  UPDATE_INVITATION_STATUS_REQUEST,
  DELETE_INVITATION_SETTING_REQUEST,
  GET_DETAIL_INVITATION_COUPON_REQUEST,
  GET_DETAIL_INVITATION_COUPON_SETTING_REQUEST,
  GET_DETAIL_INCENTIVE_COUPON_SETTING_REQUEST,
  GET_TAG_LIST_REQUEST,
  DELETE_TAG_REQUEST,
  CREATE_TAG_REQUEST,
  UPDATE_TAG_REQUEST,
  ASSOCIATE_TAG_WITH_COUPON_REQUEST,
  GET_SECRET_KEY_REQUEST
} from './constants';
import {
  createCouponFailed,
  createCouponSuccess,
  getCouponListRequest,
  getCouponListSuccess,
  getDetailCouponSuccess,
  getDetailIssueCouponSuccess,
  getDetailUsageCouponSuccess,
  updateCouponTypeSettingSuccess,
  updateCouponTypeSettingFailed,
  getCouponTypeSettingSuccess,
  getCouponTypeSettingFailed,
  getImageSizeSuccess,
  getImageSizeFailed,
  updateImageSizeSuccess,
  updateImageSizeFailed,
  getInvitationsListFailed,
  getInvitationsListSuccess,
  createInvitationSettingFailed,
  createInvitationSettingSuccess,
  getIssuedInvitationCodeListFailed,
  getIssuedInvitationCodeListSuccess,
  updateInvitationStatusFailed,
  updateInvitationStatusSuccess,
  deleteInvitationSettingSuccess,
  deleteInvitationSettingFailed,
  getDetailInvitationCouponSuccess,
  getDetailInvitationCouponFailed,
  getDetailInvitationCouponSettingSuccess,
  getDetailInvitationCouponSettingFailed,
  getDetailIncentiveCouponSettingSuccess,
  getDetailIncentiveCouponSettingFailed,
  getTagListSuccess,
  getTagListFailed,
  deleteTagSuccess,
  deleteTagFailed,
  createTagSuccess,
  createTagFailed,
  updateTagSuccess,
  updateTagFailed,
  associateTagWithCouponSuccess,
  associateTagWithCouponFailed,
  getSecretKeySuccess,
  getSecretKeyFailed
} from './actions';

import {cancelLoadingTableEmailTemplate} from '../TargetListSpoolProvider/actions';
import i18n from '../../i18n';
import {notification} from 'antd';
import request from '../../utils/api';


export function* handleGetCouponList(input) {
  const dataCoupon = helpers.deepCloneObject(_.get(input, 'payload', {}));
  const requestBody = helpers.requestBodyCoupon('get', 'coupons', dataCoupon);
  if (!_.get(requestBody.data, 'sort') || !_.get(requestBody.data, 'order')) {
    requestBody.data.sort = 'id';
    requestBody.data.order = 'desc';
  }
  const result = yield call(request, '/coupons', 'post', requestBody);

  yield put(getCouponListSuccess(result));
}

export function* onCreateCouponError(e) {
  if (!_.isEmpty(e.data) && e?.data[0]?.message) {
    notification.error({
      message: e.data[0].message,
      duration: 5
    });
  }
  yield put(createCouponFailed());
}

export function* handleCreateCoupon(input) {
  const dataCoupon = helpers.deepCloneObject(_.get(input, 'payload', {}));
  const requestBody = helpers.requestBodyCoupon('post', 'coupons', dataCoupon);
  const result = yield call(request, '/coupons', 'post', requestBody);

  if (result.code == 200) {
    yield put(createCouponSuccess(result));
    notification.success({
      message: i18n.t('message.saveSuccess'),
      duration: 2
    });
  }
}

export function* handleUpdateCoupon(input) {
  const dataCoupon = helpers.deepCloneObject(_.get(input, 'payload', {}));
  const couponId = dataCoupon.coupon_id;
  delete dataCoupon.coupon_id;
  const requestBody = helpers.requestBodyCoupon('put', 'coupons/' + couponId, dataCoupon);
  const result = yield call(request, '/coupons', 'post', requestBody);

  if (result.code == 200) {
    notification.success({
      message: i18n.t('message.saveSuccess'),
      duration: 2
    });
    yield put(createCouponSuccess(result));
  }
  yield put(createCouponFailed(result));
}

export function* handleCouponDelete(input) {

  const infoPayload = {..._.get(input, 'payload.couponDeleteInfoPayload')};
  const requestBody = helpers.requestBodyCoupon('delete', 'coupons/' + infoPayload.coupon_id);
  const result = yield call(request, '/coupons', 'post', requestBody);

  if (_.get(result, 'code') == 200) {
    helpers.notify(i18n.t('content.campaignManagement.coupon.couponDeleteSuccess'));
    yield put(getCouponListRequest((_.get(input, 'payload.couponInfoPayload'))));
  }
  yield put(cancelLoadingTableEmailTemplate());
}

export function* handleCouponUpdateStatus(input) {
  const infoPayload = {..._.get(input, 'payload.couponUpdateStatusInfoPayload')};
  const dataCoupon = {status: infoPayload.status};
  const requestBody = helpers.requestBodyCoupon('patch', 'coupons/' + infoPayload.coupon_id + '/status', dataCoupon);
  const result = yield call(request, '/coupons', 'post', requestBody);

  if (_.get(result, 'code') == 200) {
    helpers.notify(i18n.t('content.campaignManagement.coupon.couponChangeStatusSuccess'));
    yield put(getCouponListRequest((_.get(input, 'payload.couponInfoPayload'))));
  }
  yield put(cancelLoadingTableEmailTemplate());
}

export function* handleGetDetailCoupon(input) {

  const infoPayload = helpers.deepCloneObject(_.get(input, 'payload', {}));
  const requestBody = helpers.requestBodyCoupon('get', 'coupons/' + infoPayload.coupon_id);
  const result = yield call(request, '/coupons', 'post', requestBody);

  if (result.code == 200) {
    yield put(getDetailCouponSuccess(_.get(result, 'payload')));
  }
}

export function* handleGetDetailUsageCoupon(input) {
  const dataCoupon = helpers.deepCloneObject(_.get(input, 'payload', {}));
  const requestBody = helpers.requestBodyCoupon('get', 'transactions', dataCoupon);
  if (!_.get(requestBody.data, 'sort') || !_.get(requestBody.data, 'order')) {
    requestBody.data.sort = 'id';
    requestBody.data.order = 'desc';
  }
  const result = yield call(request, '/coupons', 'post', requestBody);
  result.couponId = _.get(dataCoupon, 'couponId', '');
  yield put(getDetailUsageCouponSuccess(result));
}

export function* handleGetDetailIssueCoupon(input) {
  const dataCoupon = helpers.deepCloneObject(_.get(input, 'payload', {}));
  const requestBody = helpers.requestBodyCoupon('get', 'coupons/issued', dataCoupon);
  if (!_.get(requestBody.data, 'sort') || !_.get(requestBody.data, 'order')) {
    requestBody.data.sort = 'id';
    requestBody.data.order = 'desc';
  }
  requestBody.data.includes = 'coupon';
  const result = yield call(request, '/coupons', 'post', requestBody);
  result.couponId = _.get(dataCoupon, 'couponId', '');
  yield put(getDetailIssueCouponSuccess(result));
}

// Coupon type
export function* handleGetCouponTypeSettingRequest() {
  const result = yield call(request, 'setting/coupon-type', 'get');
  if (_.get(result, 'payload.id')) {
    yield put(getCouponTypeSettingSuccess(_.get(result, 'payload')));
    return;
  }
}

export function* handleGetCouponTypeSettingRequestError(error) {
  yield put(getCouponTypeSettingFailed(error));
}

export function* handleUpdateCouponTypeSettingRequest(input) {
  const requestBody = {
    coupon_type_setting: JSON.stringify(_.get(input, 'payload'))
  };
  const result = yield call(request, 'setting/coupon-type', 'post', requestBody);
  if (_.get(result, 'payload.id')) {
    helpers.notify(
      i18n.t('content.campaignManagement.couponSetting.updateCouponTypeSettingSuccess')
    );
    yield put(updateCouponTypeSettingSuccess(_.get(result, 'payload')));
  }
}

export function* handleUpdateCouponTypeSettingRequestError(error) {
  yield put(updateCouponTypeSettingFailed(error));
}

// Image Size
export function* handleGetImageSize(input) {
  const infoPayload = helpers.deepCloneObject(_.get(input, "payload", {}));
  const requestBody = helpers.requestBodyCoupon("get", "coupon_image_size");
  const result = yield call(request, "/coupon_image_size", "get", requestBody);
  if (result.code === "200") {
    yield put(getImageSizeSuccess(_.get(result, "payload")));
  }
}

export function* handleGetImageSizeError(error) {
  yield put(getImageSizeFailed(error));
}

export function* handleUpdateImageSize(input) {
  const infoPayload = helpers.deepCloneObject(_.get(input, "payload", {}));
  const requestBody = helpers.requestBodyCoupon("post", "coupon_image_size/update", infoPayload);
  const result = yield call(request, "/coupon_image_size/update", "post", requestBody.data);
  if (result.code === "200") {
    yield put(updateImageSizeSuccess(_.get(result, "payload")));
    notification.success({
      message: i18n.t("message.saveSuccess"),
      duration: 2
    });
  }
}

export function* handleUpdateImageSizeError(error) {
  yield put(updateImageSizeFailed(error));
}

export function* handlerGetInvitationList(input) {
  const dataCoupon = helpers.deepCloneObject(_.get(input, 'payload', {}));
  const requestBody = helpers.requestBodyCoupon('get', 'invitations', dataCoupon);

  if (!_.get(requestBody.data, 'sort') || !_.get(requestBody.data, 'order')) {
    requestBody.data.sort = 'updatedAt';
    requestBody.data.order = 'desc';
  }

  const result = yield call(request, '/coupons', 'post', requestBody);
  yield put(getInvitationsListSuccess(result));
}

export function* handlerGetInvitationListError(error) {
  yield put(getInvitationsListFailed(error));
}

export function* handleCreateInvitationSetting(input) {
  const dataRequest = helpers.deepCloneObject(_.get(input, "payload", {}));
  const requestBody = helpers.requestBodyCoupon("post", "invitations", dataRequest);
  const result = yield call(request, "/coupons", "post", requestBody);
  if (result.code === "200") {
    yield put(createInvitationSettingSuccess(result));
    notification.success({
      message: i18n.t("message.saveSuccess"),
      duration: 2
    });
  }
}

export function* handleCreateInvitationSettingError(error) {
  yield put(createInvitationSettingFailed(error));
}

export function* handleGetIssuedInvitationCodeList(input) {
  const dataCoupon = helpers.deepCloneObject(_.get(input, "payload", {}));
  const requestBody = helpers.requestBodyCoupon("get", "invitations/issued", dataCoupon);
  if (!_.get(requestBody.data, "sort") || !_.get(requestBody.data, "order")) {
    requestBody.data.sort = "updatedAt";
    requestBody.data.order = "desc";
  }
  const result = yield call(request, "/coupons", "post", requestBody);

  yield put(getIssuedInvitationCodeListSuccess(result));
}

export function* handleGetIssuedInvitationCodeListError(error) {
  yield put(getIssuedInvitationCodeListFailed(error));
}

export function* handleUpdateInvitationStatus(input) {
  const dataCoupon = helpers.deepCloneObject(_.get(input, "payload", {}));
  const { invitationId, dataRequest } = dataCoupon;
  const requestBody = helpers.requestBodyCoupon("put", `invitations/${invitationId}`, dataRequest);
  const result = yield call(request, "/coupons", "post", requestBody);
  if (result.code === "200") {
    yield put(updateInvitationStatusSuccess(result));
    notification.success({
      message: i18n.t("message.saveSuccess"),
      duration: 2
    });
  }
}

export function* handleUpdateInvitationStatusError(error) {
  yield put(updateInvitationStatusFailed(error));
}

export function* handleDeleteInvitationSetting(input) {
  const dataCoupon = helpers.deepCloneObject(_.get(input, "payload", {}));
  const { invitationId } = dataCoupon;
  const requestBody = helpers.requestBodyCoupon("delete", `invitations/${invitationId}`);
  const result = yield call(request, "/coupons", "post", requestBody);

  if (result.code === "200") {
    yield put(deleteInvitationSettingSuccess(result));
    notification.success({
      message: i18n.t("message.deleteSuccess"),
      duration: 2
    });
  }
}

export function* handleDeleteInvitationSettingError(error) {
  yield put(deleteInvitationSettingFailed(error));
}

export function* handleGetDetailInvitationCoupon(input) {
  const infoPayload = helpers.deepCloneObject(_.get(input, "payload", {}));
  const requestBody = helpers.requestBodyCoupon("get", "coupons/" + infoPayload.coupon_id);
  const result = yield call(request, "/coupons", "post", requestBody);

  if (result.code === "200") {
    yield put(getDetailInvitationCouponSuccess(_.get(result, "payload")));
  }
}

export function* handleGetDetailInvitationCouponError(error) {
  yield put(getDetailInvitationCouponFailed(error));
}

export function* handleGetDetailInvitationCouponSetting(input) {
  const infoPayload = helpers.deepCloneObject(_.get(input, "payload", {}));
  const requestBody = helpers.requestBodyCoupon("get", "coupons/" + infoPayload.coupon_id);
  const result = yield call(request, "/coupons", "post", requestBody);

  if (result.code === "200") {
    yield put(getDetailInvitationCouponSettingSuccess(_.get(result, "payload")));
  }
}

export function* handleGetDetailInvitationCouponSettingError(error) {
  yield put(getDetailInvitationCouponSettingFailed(error));
}

export function* handleGetDetailIncentiveCouponSetting(input) {
  const infoPayload = helpers.deepCloneObject(_.get(input, "payload", {}));
  const requestBody = helpers.requestBodyCoupon("get", "coupons/" + infoPayload.coupon_id);
  const result = yield call(request, "/coupons", "post", requestBody);

  if (result.code === "200") {
    yield put(getDetailIncentiveCouponSettingSuccess(_.get(result, "payload")));
  }
}

export function* handleGetDetailIncentiveCouponSettingError(error) {
  yield put(getDetailIncentiveCouponSettingFailed(error));
}

export function* handleGetTagListRequest(input) {
  const infoPayload = helpers.deepCloneObject(_.get(input, "payload", {}));
  const requestBody = helpers.requestBodyCoupon("get", "tags", infoPayload);
  if (!_.get(requestBody.data, "sort") || !_.get(requestBody.data, "order")) {
    requestBody.data.sort = "id";
    requestBody.data.order = "desc";
  }
  const result = yield call(request, "coupons", "post", requestBody);
  if (result.code === "200") {
    yield put(getTagListSuccess(_.get(result, "payload")));
  }
}
export function* handleGetTagListRequestError(error) {
  yield put(getTagListFailed(error));
}
export function* handleDeleteTagRequest(input) {
  const infoPayload = { ..._.get(input, "payload.deleteTagPayload") };
  const requestBody = helpers.requestBodyCoupon("delete", "tags/" + infoPayload.tagId);
  const result = yield call(request, "/coupons", "post", requestBody);
  if (_.get(result, "code") === "200") {
    helpers.notify(i18n.t("message.deleteSuccess"));
    yield put(deleteTagSuccess());
  }
}
export function* handleDeleteTagRequestError(error) {
  yield put(deleteTagFailed(error));
}
export function* handleCreateTagRequest(input) {
  const dataCoupon = helpers.deepCloneObject(_.get(input, "payload", {}));
  const requestBody = helpers.requestBodyCoupon("post", "tags", dataCoupon);
  const result = yield call(request, "/coupons", "post", requestBody);
  if (result.code === "200") {
    yield put(createTagSuccess());
    notification.success({
      message: i18n.t("message.createTagSuccess"),
      duration: 2
    });
  }
}
export function* handleCreateTagRequestError(error) {
  yield put(createTagFailed(error));
}
export function* handleUpdateTagRequest(input) {
  const tagData = helpers.deepCloneObject(_.get(input, "payload", {}));
  const tagId = tagData.tagId;
  delete tagData.tagId;
  const requestBody = helpers.requestBodyCoupon("put", "tags/" + tagId, tagData);
  const result = yield call(request, "/coupons", "post", requestBody);
  if (result.code === "200") {
    notification.success({
      message: i18n.t("message.updateTagSuccess"),
      duration: 2
    });
    yield put(updateTagSuccess());
  }
}
export function* handleUpdateTagRequestError(error) {
  yield put(updateTagFailed(error));
}
export function* handleAssociateTagWithCouponRequest(input) {
  const payloadInfo = helpers.deepCloneObject(_.get(input, "payload", {}));
  const couponId = payloadInfo.couponId;
  delete payloadInfo.couponId;
  const requestBody = helpers.requestBodyCoupon("put", `coupons/${couponId}/tags`, payloadInfo);
  const result = yield call(request, "/coupons", "post", requestBody);
  if (result.code === "200") {
    yield put(associateTagWithCouponSuccess());
    notification.success({
      message: i18n.t("message.associateTagWithCouponSuccess"),
      duration: 2
    });
  }
}
export function* handleAssociateTagWithCouponRequestError(error) {
  yield put(associateTagWithCouponFailed(error));
}

// Secret Key
export function* handleGetSecretKey() {
  const requestBody = helpers.requestBodyCoupon("get", "coupons/product-secret-key");
  const result = yield call(request, "/coupons/product-secret-key", "get", requestBody);
  if ("200" === result.code) {
    yield put(getSecretKeySuccess(_.get(result, "payload")));
  }
}
export function* handleGetSecretKeyError(error) {
  yield put(getSecretKeyFailed(error));
}

export default function* watchAuthCampaign() {

  yield takeLeading(
    GET_COUPON_LIST_REQUEST,
    safe(handleGetCouponList),
  );
  yield takeLeading(
    CREATE_COUPON_REQUEST,
    safe(handleCreateCoupon, onCreateCouponError),
  );
  yield takeLeading(
    UPDATE_COUPON_REQUEST,
    safe(handleUpdateCoupon, onCreateCouponError),
  );
  yield takeLeading(
    COUPON_DELETE_REQUEST,
    safe(handleCouponDelete),
  );
  yield takeLeading(
    COUPON_UPDATE_STATUS_REQUEST,
    safe(handleCouponUpdateStatus),
  );
  yield takeLeading(
    GET_DETAIL_COUPON_REQUEST,
    safe(handleGetDetailCoupon),
  );
  yield takeLeading(
    GET_DETAIL_USAGE_COUPON_REQUEST,
    safe(handleGetDetailUsageCoupon),
  );
  yield takeLeading(
    GET_DETAIL_ISSUE_COUPON_REQUEST,
    safe(handleGetDetailIssueCoupon),
  );
  // Coupon type
  yield takeLeading(
    GET_COUPON_TYPE_SETTING_REQUEST,
    safe(handleGetCouponTypeSettingRequest, handleGetCouponTypeSettingRequestError)
  );
  yield takeLeading(
    COUPON_TYPE_SETTING_UPDATE_REQUEST,
    safe(handleUpdateCouponTypeSettingRequest, handleUpdateCouponTypeSettingRequestError)
  );
  yield takeLeading(GET_IMAGE_SIZE_REQUEST, safe(handleGetImageSize, handleGetImageSizeError));
  yield takeLeading(
    UPDATE_IMAGE_SIZE_REQUEST,
    safe(handleUpdateImageSize, handleUpdateImageSizeError)
  );
  yield takeLeading(
    GET_INVITATION_LIST_REQUEST,
    safe(handlerGetInvitationList, handlerGetInvitationListError),
  );
  yield takeLeading(
    CREATE_INVITATION_SETTING_REQUEST,
    safe(handleCreateInvitationSetting, handleCreateInvitationSettingError)
  );
  yield takeLeading(
    GET_ISSUED_INVITATION_CODE_LIST_REQUEST,
    safe(handleGetIssuedInvitationCodeList, handleGetIssuedInvitationCodeListError)
  );
  yield takeLeading(
    UPDATE_INVITATION_STATUS_REQUEST,
    safe(handleUpdateInvitationStatus, handleUpdateInvitationStatusError)
  );
  yield takeLeading(
    DELETE_INVITATION_SETTING_REQUEST,
    safe(handleDeleteInvitationSetting, handleDeleteInvitationSettingError)
  );
  yield takeLeading(
    GET_DETAIL_INVITATION_COUPON_REQUEST,
    safe(handleGetDetailInvitationCoupon, handleGetDetailInvitationCouponError)
  );
  yield takeLeading(
    GET_DETAIL_INVITATION_COUPON_SETTING_REQUEST,
    safe(handleGetDetailInvitationCouponSetting, handleGetDetailInvitationCouponSettingError)
  );
  yield takeLeading(
    GET_DETAIL_INCENTIVE_COUPON_SETTING_REQUEST,
    safe(handleGetDetailIncentiveCouponSetting, handleGetDetailIncentiveCouponSettingError)
  );
  // Coupon tag
  yield takeLeading(
    GET_TAG_LIST_REQUEST,
    safe(handleGetTagListRequest, handleGetTagListRequestError)
  );
  yield takeLeading(
    DELETE_TAG_REQUEST,
    safe(handleDeleteTagRequest, handleDeleteTagRequestError)
  );
  yield takeLeading(
    CREATE_TAG_REQUEST,
    safe(handleCreateTagRequest, handleCreateTagRequestError)
  );
  yield takeLeading(
    UPDATE_TAG_REQUEST,
    safe(handleUpdateTagRequest, handleUpdateTagRequestError)
  );
  yield takeLeading(
    ASSOCIATE_TAG_WITH_COUPON_REQUEST,
    safe(handleAssociateTagWithCouponRequest, handleAssociateTagWithCouponRequestError)
  );
  // Secret Key
  yield takeLeading(GET_SECRET_KEY_REQUEST, safe(handleGetSecretKey, handleGetSecretKeyError));
}