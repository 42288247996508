import produce from 'immer';
import _ from 'lodash';

import {
  GET_POINT_API_CONFIG_SECRET_KEY_REQUEST,
  GET_POINT_API_CONFIG_SECRET_KEY_SUCCESS,
  GET_POINT_API_CONFIG_SECRET_KEY_FAILED,
  GET_POINT_API_EXPIRY_DURATION_REQUEST,
  GET_POINT_API_EXPIRY_DURATION_SUCCESS,
  GET_POINT_API_EXPIRY_DURATION_FAILED,
  UPDATE_POINT_API_EXPIRY_DURATION_REQUEST,
  UPDATE_POINT_API_EXPIRY_DURATION_SUCCESS,
  UPDATE_POINT_API_EXPIRY_DURATION_FAILED,
  GET_POINT_API_EXCHANGE_RATE_REQUEST,
  GET_POINT_API_EXCHANGE_RATE_SUCCESS,
  GET_POINT_API_EXCHANGE_RATE_FAILED,
  UPDATE_POINT_API_EXCHANGE_RATE_REQUEST,
  UPDATE_POINT_API_EXCHANGE_RATE_FAILED,
  UPDATE_POINT_API_EXCHANGE_RATE_SUCCESS,
  GET_POINT_API_TRANSFER_RATE_REQUEST,
  GET_POINT_API_TRANSFER_RATE_SUCCESS,
  GET_POINT_API_TRANSFER_RATE_FAILED,
  UPDATE_POINT_API_TRANSFER_RATE_REQUEST,
  UPDATE_POINT_API_TRANSFER_RATE_SUCCESS,
  UPDATE_POINT_API_TRANSFER_RATE_FAILED,
  GET_POINT_API_TOTAL_POINT_SEARCH_REQUEST,
  GET_POINT_API_TOTAL_POINT_SEARCH_SUCCESS,
  GET_POINT_API_TOTAL_POINT_SEARCH_FAILED,
  GET_POINT_API_POINT_HISTORY_SEARCH_REQUEST,
  GET_POINT_API_POINT_HISTORY_SEACRH_SUCCESS,
  GET_POINT_API_POINT_HISTORY_SEARCH_FAILED,
  GET_POINT_API_CSV_TOTAL_POINT_DAILY_REPORT_REQUEST,
  GET_POINT_API_CSV_TOTAL_POINT_DAILY_REPORT_SUCCESS,
  GET_POINT_API_CSV_TOTAL_POINT_DAILY_REPORT_FAILED,
  GET_POINT_API_CSV_TOTAL_POINT_DAILY_DOWNLOAD_REQUEST,
  GET_POINT_API_CSV_TOTAL_POINT_DAILY_DOWNLOAD_SUCCESS,
  GET_POINT_API_CSV_TOTAL_POINT_DAILY_DOWNLOAD_FAILED
} from './constants';

const initialState = {
  secret: {
    loading: false,
    data: []
  },
  expiry_duration: {
    loading: false,
    data: []
  },
  update_expiry_duration: {
    loading: false,
    data: []
  },
  exchangeRate: {
    loading: false,
    data: []
  },
  updateExchangeRate: {
    loading: false,
    data: []
  },
  transferRate: {
    loading: false,
    data: []
  },
  updateTransferRate: {
    loading: false,
    data: []
  },
  totalPointSearchList: {
    loading: false,
    data: []
  },
  getPointHistorySearchList: {
    loading: false,
    data: []
  },
  csvTotalPointDailyReport: {
    loading: false,
    data: []
  },
  csvTotalPointDailyDownload: {
    loading: false,
    downloaded: false
  }
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {

    case GET_POINT_API_CONFIG_SECRET_KEY_REQUEST:
      state.secret = {
        loading: true,
        data: [],
        pagination: {}
      };
      return state;

    case GET_POINT_API_CONFIG_SECRET_KEY_SUCCESS:
      state.secret = {
        loading: false,
        data: _.get(action, 'payload', []),
      };
      return state;

    case GET_POINT_API_CONFIG_SECRET_KEY_FAILED:
      state.secret.loading = false;
      state.secret.error = _.get(action, 'payload', {});
      return state;

    case GET_POINT_API_EXPIRY_DURATION_REQUEST:
      state.expiry_duration = {
        loading: true,
        data: [],
        pagination: {}
      }
      return state;
      
    case GET_POINT_API_EXPIRY_DURATION_SUCCESS:
      state.expiry_duration = {
        loading: false,
        data: _.get(action, 'payload', []),
      }
      return state;

    case GET_POINT_API_EXPIRY_DURATION_FAILED:
      state.expiry_duration.loading = false;
      state.expiry_duration.error = _.get(action, 'payload', {});
      return state;

    case UPDATE_POINT_API_EXPIRY_DURATION_REQUEST:
      state.update_expiry_duration = {
        loading: true,
        data: [],
        pagination: {}
      }
      return state;

    case UPDATE_POINT_API_EXPIRY_DURATION_SUCCESS:
      state.update_expiry_duration.loading = false;
      return state;

    case UPDATE_POINT_API_EXPIRY_DURATION_FAILED:
      state.update_expiry_duration.loading = false;
      return state;

    case GET_POINT_API_EXCHANGE_RATE_REQUEST:
      state.exchangeRate = {
        loading: true,
        data: []
      }
      return state;
    
    case GET_POINT_API_EXCHANGE_RATE_SUCCESS:
      state.exchangeRate = {
        loading: false,
        data: _.get(action, 'payload', []),
      }
      return state;
    
    case GET_POINT_API_EXCHANGE_RATE_FAILED:
      state.exchangeRate.loading = false;
      state.exchangeRate.error = _.get(action, 'payload', {});
      return state;
    
    case UPDATE_POINT_API_EXCHANGE_RATE_REQUEST:
      state.updateExchangeRate = {
        loading: true,
        data: []
      }
      return state;
    
    case UPDATE_POINT_API_EXCHANGE_RATE_SUCCESS:
      state.updateExchangeRate = {
        loading: false,
        data: _.get(action, 'payload', []),
      }
      return state;

    case UPDATE_POINT_API_EXCHANGE_RATE_FAILED:
      state.updateExchangeRate.loading = false;
      state.updateExchangeRate.error = _.get(action, 'payload', {});
      return state;

    case GET_POINT_API_TRANSFER_RATE_REQUEST:
      state.transferRate = {
        loading: true,
        data: []
      }
      return state;

    case GET_POINT_API_TRANSFER_RATE_SUCCESS:
      state.transferRate = {
        loading: false,
        data: _.get(action, 'payload', [])
      }
      return state;

    case GET_POINT_API_TRANSFER_RATE_FAILED:
      state.transferRate.loading = false;
      state.transferRate.error = _.get(action, 'payload', []);
      return state;

    case UPDATE_POINT_API_TRANSFER_RATE_REQUEST:
      state.updateTransferRate = {
        loading: true,
        data: []
      }
      return state;

    case UPDATE_POINT_API_TRANSFER_RATE_SUCCESS:
      state.updateTransferRate = {
        loading: false,
        data: _.get(action, 'payload', [])
      }
      return state;

    case UPDATE_POINT_API_TRANSFER_RATE_FAILED:
      state.updateTransferRate.loading = false;
      state.updateTransferRate.error = _.get(action, 'payload', []);
      return state;

    case GET_POINT_API_TOTAL_POINT_SEARCH_REQUEST:
      state.totalPointSearchList = {
        loading: true,
        data: []
      }
      return state;
    
    case GET_POINT_API_TOTAL_POINT_SEARCH_SUCCESS:
      state.totalPointSearchList = {
        loading: false,
        data: _.get(action, 'payload', [])
      }
      return state;

    case GET_POINT_API_TOTAL_POINT_SEARCH_FAILED:
      state.totalPointSearchList.loading = false;
      state.totalPointSearchList.error = _.get(action, 'payload', []);
      return state;

    case GET_POINT_API_POINT_HISTORY_SEARCH_REQUEST:
      state.getPointHistorySearchList = {
        loading: true,
        data: []
      }
      return state;

    case GET_POINT_API_POINT_HISTORY_SEACRH_SUCCESS:
      state.getPointHistorySearchList = {
        loading: false,
        data: _.get(action, 'payload', [])
      }
      return state;

    case GET_POINT_API_POINT_HISTORY_SEARCH_FAILED:
      state.getPointHistorySearchList.loading = false;
      state.getPointHistorySearchList.error = _.get(action, 'payload', []);
      return state;

      case GET_POINT_API_CSV_TOTAL_POINT_DAILY_REPORT_REQUEST:
        state.csvTotalPointDailyReport = {
          loading: true,
          data: []
        }
        return state;
      
      case GET_POINT_API_CSV_TOTAL_POINT_DAILY_REPORT_SUCCESS:
        state.csvTotalPointDailyReport = {
          loading: false,
          data: _.get(action, 'payload', [])
        }
        return state;
  
      case GET_POINT_API_CSV_TOTAL_POINT_DAILY_REPORT_FAILED:
        state.csvTotalPointDailyReport.loading = false;
        state.csvTotalPointDailyReport.error = _.get(action, 'payload', []);
        return state;
  
      case GET_POINT_API_CSV_TOTAL_POINT_DAILY_DOWNLOAD_REQUEST:
        state.csvTotalPointDailyDownload = {
          loading: true,
          downloaded: false
        }
        return state;
  
      case GET_POINT_API_CSV_TOTAL_POINT_DAILY_DOWNLOAD_SUCCESS:
        state.csvTotalPointDailyDownload = {
          loading: false,
          downloaded: true
        }
        return state;
  
      case GET_POINT_API_CSV_TOTAL_POINT_DAILY_DOWNLOAD_FAILED:
        state.csvTotalPointDailyDownload = {
          loading: false,
          downloaded: false,
          error: _.get(action, 'payload', [])
        }
        return state;
      
    default:
      return state;
  }
});

export default reducer;
