import React from 'react';
import './style.less';

const LoadingIndicator = () => (
  <div className="loader-background">
    <div className="loader">
      <div className="loader__bar"></div>
      <div className="loader__bar"></div>
      <div className="loader__bar"></div>
      <div className="loader__bar"></div>
      <div className="loader__bar"></div>
      <div className="loader__ball"></div>
    </div>
  </div>
);

export default LoadingIndicator;
