import React, { forwardRef, memo } from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import { TimePicker as AntTimePicker, Form } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import moment from "moment";

import "./style.less";
import constants from "utils/constants";

const TimePicker = forwardRef(
  (
    { label, className = "", placeholder = "", format = constants.FORMAT.TIME_FORMAT, ...props },
    ref
  ) => {
    const [field, meta, helper] = useField(props);
    const { value } = field;
    const isError = meta.touched && meta.error;
    return (
      <div className={`input-container select-field-container ${className}`}>
        <Form.Item
          label={label}
          validateStatus={isError ? "error" : ""}
          help={
            <div className="helper-wrapper">
              <div className="error-text">
                {isError && (
                  <>
                    <InfoCircleOutlined className="info-icon" type="info-circle" />
                    {meta.error}
                  </>
                )}
              </div>
            </div>
          }
        >
          <AntTimePicker
            {...field}
            {...props}
            ref={ref}
            placeholder={placeholder}
            value={typeof value === "string" ? moment(value, format) : value}
            onChange={(data) => helper.setValue(data)}
            format={format}
          ></AntTimePicker>
        </Form.Item>
      </div>
    );
  }
);
TimePicker.displayName = "TimePicker";
TimePicker.propTypes = {
  label: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  format: PropTypes.string
};
export default memo(TimePicker);