import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';

import helper from 'utils/helpers';

const PrivateRoute = ({ Component, ...props }) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  if (helper.isGuest()) {
    return <Navigate to='/login' />;
  }

  return (
    <Component {...props} />
  );
};

PrivateRoute.propTypes = {
  Component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired
};

export default PrivateRoute;
