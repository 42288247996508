import produce from 'immer';
import _ from 'lodash';

import {
  GET_CUSTOMER_RECOMMENDATION_LIST_REQUEST,
  GET_CUSTOMER_RECOMMENDATION_LIST_SUCCESS,
  GET_CUSTOMER_RECOMMENDATION_LIST_FAILED,
  GET_CUSTOMER_RECOMMENDATION_DETAILS_REQUEST,
  GET_CUSTOMER_RECOMMENDATION_DETAILS_SUCCESS,
  GET_CUSTOMER_RECOMMENDATION_DETAILS_FAILED,
  GET_CUSTOMER_RECOMMENDATION_MENU_LIST_REQUEST,
  GET_CUSTOMER_RECOMMENDATION_MENU_LIST_SUCCESS,
  GET_CUSTOMER_RECOMMENDATION_MENU_LIST_FAILED,
  GET_CUSTOMER_RECOMMENDATION_MENU_DETAILS_REQUEST,
  GET_CUSTOMER_RECOMMENDATION_MENU_DETAILS_SUCCESS,
  GET_CUSTOMER_RECOMMENDATION_MENU_DETAILS_FAILED,
  GET_SERVICER_MATCHING_SCORE_LIST_REQUEST,
  GET_SERVICER_MATCHING_SCORE_LIST_SUCCESS,
  GET_SERVICER_MATCHING_SCORE_LIST_FAILED,
  GET_MENU_MATCHING_SCORE_LIST_REQUEST,
  GET_MENU_MATCHING_SCORE_LIST_SUCCESS,
  GET_MENU_MATCHING_SCORE_LIST_FAILED,
  GET_SERVICER_SEGMENTATION_LIST_REQUEST,
  GET_SERVICER_SEGMENTATION_LIST_SUCCESS,
  GET_SERVICER_SEGMENTATION_LIST_FAILED,
  GET_SERVICER_SEGMENTATION_DETAILS_REQUEST,
  GET_SERVICER_SEGMENTATION_DETAILS_SUCCESS,
  GET_SERVICER_SEGMENTATION_DETAILS_FAILED,
  GET_CUSTOMER_SEGMENTATION_LIST_REQUEST,
  GET_CUSTOMER_SEGMENTATION_LIST_SUCCESS,
  GET_CUSTOMER_SEGMENTATION_LIST_FAILED,
  GET_CUSTOMER_SEGMENTATION_DETAILS_REQUEST,
  GET_CUSTOMER_SEGMENTATION_DETAILS_SUCCESS,
  GET_CUSTOMER_SEGMENTATION_DETAILS_FAILED,
  GET_DISTRIBUTE_R_AND_F_REQUEST,
  GET_DISTRIBUTE_R_AND_F_SUCCESS,
  GET_DISTRIBUTE_R_AND_F_FAILED,
  GET_DISTRIBUTE_MONETARY_OVER_RF_REQUEST,
  GET_DISTRIBUTE_MONETARY_OVER_RF_SUCCESS,
  GET_DISTRIBUTE_MONETARY_OVER_RF_FAILED
} from './constants';

const initialState = {
  customerRecommendationList: {
    loading: false,
    pagination: {},
    data: []
  },
  customerRecommendationDetails: {
    loading: false,
    pagination: {},
    data: []
  },
  customerRecommendationMenuList: {
    loading: false,
    pagination: {},
    data: []
  },
  customerRecommendationMenuDetails: {
    loading: false,
    pagination: {},
    data: []
  },
  servicerMatchingScoreList: {
    loading: false,
    pagination: {},
    data: []
  },
  menuMatchingScoreList: {
    loading: false,
    pagination: {},
    data: []
  },
  servicerSegmentationList: {
    loading: false,
    pagination: {},
    data: []
  },
  servicerSegmentationDetails: {
    loading: false,
    pagination: {},
    data: []
  },
  customerSegmentationList: {
    loading: false,
    pagination: {},
    data: []
  },
  customerSegmentationDetails: {
    loading: false,
    pagination: {},
    data: []
  },
  distributeRF: {
    loading: false,
    pagination: {},
    data: []
  },
  distributeMonetary: {
    loading: false,
    pagination: {},
    data: []
  }
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {

    case GET_CUSTOMER_RECOMMENDATION_LIST_REQUEST:
      state.customerRecommendationList = {
        loading: true,
        data: [],
        pagination: {}
      };
      return state;

    case GET_CUSTOMER_RECOMMENDATION_LIST_SUCCESS:
      state.customerRecommendationList = {
        loading: false,
        data: _.get(action, 'payload.data', []),
        pagination: _.get(action, 'payload.pagination', {})
      };
      return state;

    case GET_CUSTOMER_RECOMMENDATION_LIST_FAILED:
      state.customerRecommendationList.loading = false;
      return state;

    case GET_CUSTOMER_RECOMMENDATION_DETAILS_REQUEST:
      state.customerRecommendationDetails = {
        loading: true,
        data: [],
        pagination: {}
      };
      return state;

    case GET_CUSTOMER_RECOMMENDATION_DETAILS_SUCCESS:
      state.customerRecommendationDetails = {
        loading: false,
        data: _.get(action, 'payload.data', []),
        pagination: _.get(action, 'payload.pagination', {})
      };
      return state;

    case GET_CUSTOMER_RECOMMENDATION_DETAILS_FAILED:
      state.customerRecommendationDetails.loading = false;
      return state;

    case GET_CUSTOMER_RECOMMENDATION_MENU_LIST_REQUEST:
      state.customerRecommendationMenuList = {
        loading: true,
        data: [],
        pagination: {}
      };
      return state;
    case GET_CUSTOMER_RECOMMENDATION_MENU_LIST_SUCCESS:
      state.customerRecommendationMenuList = {
        loading: false,
        data: _.get(action, 'payload.data', []),
        pagination: _.get(action, 'payload.pagination', {})
      };
      return state;
    case GET_CUSTOMER_RECOMMENDATION_MENU_LIST_FAILED:
      state.customerRecommendationMenuList.loading = false;
      return state;
    case GET_CUSTOMER_RECOMMENDATION_MENU_DETAILS_REQUEST:
      state.customerRecommendationMenuDetails = {
        loading: true,
        data: [],
        pagination: {}
      };
      return state;
    case GET_CUSTOMER_RECOMMENDATION_MENU_DETAILS_SUCCESS:
      state.customerRecommendationMenuDetails = {
        loading: false,
        data: _.get(action, 'payload.data', []),
        pagination: _.get(action, 'payload.pagination', {})
      };
      return state;
    case GET_CUSTOMER_RECOMMENDATION_MENU_DETAILS_FAILED:
      state.customerRecommendationMenuDetails.loading = false;
      return state;
    case GET_SERVICER_MATCHING_SCORE_LIST_REQUEST:
      state.servicerMatchingScoreList = {
        loading: true,
        data: [],
        pagination: {}
      };
      return state;
    case GET_SERVICER_MATCHING_SCORE_LIST_SUCCESS:
      state.servicerMatchingScoreList = {
        loading: false,
        data: _.get(action, 'payload.data', []),
        pagination: _.get(action, 'payload.pagination', {})
      };
      return state;
    case GET_SERVICER_MATCHING_SCORE_LIST_FAILED:
      state.servicerMatchingScoreList.loading = false;
      return state;
    case GET_MENU_MATCHING_SCORE_LIST_REQUEST:
      state.menuMatchingScoreList = {
        loading: true,
        data: [],
        pagination: {}
      };
      return state;
    case GET_MENU_MATCHING_SCORE_LIST_SUCCESS:
      state.menuMatchingScoreList = {
        loading: false,
        data: _.get(action, 'payload.data', []),
        pagination: _.get(action, 'payload.pagination', {})
      };
      return state;
    case GET_MENU_MATCHING_SCORE_LIST_FAILED:
      state.menuMatchingScoreList.loading = false;
      return state;
    case GET_SERVICER_SEGMENTATION_LIST_REQUEST:
      state.servicerSegmentationList.loading = true;
      return state;
    case GET_SERVICER_SEGMENTATION_LIST_SUCCESS:
      state.servicerSegmentationList = {
        loading: false,
        data: _.get(action, 'payload.data', []),
        pagination: _.get(action, 'payload.pagination', {})
      };
      return state;
    case GET_SERVICER_SEGMENTATION_LIST_FAILED:
      state.servicerSegmentationList.loading = false;
      return state;
    case GET_SERVICER_SEGMENTATION_DETAILS_REQUEST:
      state.servicerSegmentationDetails.loading = true;
      return state;
    case GET_SERVICER_SEGMENTATION_DETAILS_SUCCESS:
      state.servicerSegmentationDetails = {
        loading: false,
        data: _.get(action, 'payload.data', []),
        pagination: _.get(action, 'payload.pagination', {})
      };
      return state;
    case GET_SERVICER_SEGMENTATION_DETAILS_FAILED:
      state.servicerSegmentationDetails.loading = false;
      return state;
    case GET_CUSTOMER_SEGMENTATION_LIST_REQUEST:
      state.customerSegmentationList.loading = true;
      return state;
    case GET_CUSTOMER_SEGMENTATION_LIST_SUCCESS:
      state.customerSegmentationList = {
        loading: false,
        data: _.get(action, 'payload.data', []),
        pagination: _.get(action, 'payload.pagination', {})
      };
      return state;
    case GET_CUSTOMER_SEGMENTATION_LIST_FAILED:
      state.customerSegmentationList.loading = false;
      return state;
    case GET_CUSTOMER_SEGMENTATION_DETAILS_REQUEST:
      state.customerSegmentationDetails.loading = true;
      return state;
    case GET_CUSTOMER_SEGMENTATION_DETAILS_SUCCESS:
      state.customerSegmentationDetails = {
        loading: false,
        data: _.get(action, 'payload.data', []),
        pagination: _.get(action, 'payload.pagination', {})
      };
      return state;
    case GET_CUSTOMER_SEGMENTATION_DETAILS_FAILED:
      state.customerSegmentationDetails.loading = false;
      return state;
    case GET_DISTRIBUTE_R_AND_F_REQUEST:
      state.distributeRF.loading = true;
      return state;
    case GET_DISTRIBUTE_R_AND_F_SUCCESS:
      state.distributeRF = {
        loading: false,
        data: _.get(action, 'payload'),
      };
      return state;
    case GET_DISTRIBUTE_R_AND_F_FAILED:
      state.distributeRF.loading = false;
      return state;
    case GET_DISTRIBUTE_MONETARY_OVER_RF_REQUEST:
      state.distributeMonetary.loading = true;
      return state;
    case GET_DISTRIBUTE_MONETARY_OVER_RF_SUCCESS:
      state.distributeMonetary = {
        loading: false,
        data: _.get(action, 'payload'),
      };
      return state;
    case GET_DISTRIBUTE_MONETARY_OVER_RF_FAILED:
      state.distributeMonetary.loading = false;
      return state;  
    default:
      return state;
  }
});

export default reducer;