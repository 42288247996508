import { saveTargetTemplate } from 'containers/Dashboard/TargetListSpool/Customer/validate';
import produce from 'immer';
import _ from 'lodash';

import {
  GET_COUPON_LIST_REQUEST,
  GET_COUPON_LIST_SUCCESS,
  GET_COUPON_LIST_FAILED,
  CREATE_COUPON_REQUEST,
  CREATE_COUPON_FAILED,
  CREATE_COUPON_SUCCESS,
  COUPON_DELETE_REQUEST,
  COUPON_UPDATE_STATUS_REQUEST,
  GET_DETAIL_COUPON_REQUEST,
  GET_DETAIL_COUPON_SUCCESS,
  RESET_DETAIL_COUPON,
  UPDATE_COUPON_REQUEST,
  UPDATE_COUPON_SUCCESS,
  UPDATE_COUPON_FAILED,
  GET_DETAIL_USAGE_COUPON_SUCCESS,
  GET_DETAIL_USAGE_COUPON_REQUEST,
  GET_DETAIL_ISSUE_COUPON_REQUEST,
  GET_DETAIL_ISSUE_COUPON_SUCCESS,
  COUPON_TYPE_SETTING_UPDATE_REQUEST,
  COUPON_TYPE_SETTING_UPDATE_SUCCESS,
  COUPON_TYPE_SETTING_UPDATE_FAILED,
  GET_COUPON_TYPE_SETTING_REQUEST,
  GET_COUPON_TYPE_SETTING_SUCCESS,
  GET_COUPON_TYPE_SETTING_FAILED,
  GET_IMAGE_SIZE_REQUEST,
  GET_IMAGE_SIZE_SUCCESS,
  GET_IMAGE_SIZE_FAILED,
  UPDATE_IMAGE_SIZE_REQUEST,
  UPDATE_IMAGE_SIZE_SUCCESS,
  UPDATE_IMAGE_SIZE_FAILED,
  GET_INVITATION_LIST_REQUEST,
  GET_INVITATION_LIST_SUCCESS,
  GET_INVITATION_LIST_FAILED,
  CREATE_INVITATION_SETTING_REQUEST,
  CREATE_INVITATION_SETTING_SUCCESS,
  CREATE_INVITATION_SETTING_FAILED,
  GET_ISSUED_INVITATION_CODE_LIST_REQUEST,
  GET_ISSUED_INVITATION_CODE_LIST_SUCCESS,
  GET_ISSUED_INVITATION_CODE_LIST_FAILED,
  UPDATE_INVITATION_STATUS_REQUEST,
  UPDATE_INVITATION_STATUS_SUCCESS,
  UPDATE_INVITATION_STATUS_FAILED,
  DELETE_INVITATION_SETTING_REQUEST,
  DELETE_INVITATION_SETTING_SUCCESS,
  DELETE_INVITATION_SETTING_FAILED,
  GET_DETAIL_INVITATION_COUPON_REQUEST,
  GET_DETAIL_INVITATION_COUPON_SUCCESS,
  GET_DETAIL_INVITATION_COUPON_FAILED,
  RESET_DETAIL_INVITATION_COUPON,
  GET_DETAIL_INVITATION_COUPON_SETTING_REQUEST,
  GET_DETAIL_INVITATION_COUPON_SETTING_SUCCESS,
  GET_DETAIL_INVITATION_COUPON_SETTING_FAILED,
  GET_DETAIL_INCENTIVE_COUPON_SETTING_REQUEST,
  GET_DETAIL_INCENTIVE_COUPON_SETTING_SUCCESS,
  GET_DETAIL_INCENTIVE_COUPON_SETTING_FAILED,
  RESET_DETAIL_INVITATION_COUPON_SETTING,
  RESET_DETAIL_INCENTIVE_COUPON_SETTING,
  GET_TAG_LIST_REQUEST,
  GET_TAG_LIST_SUCCESS,
  GET_TAG_LIST_FAILED,
  DELETE_TAG_REQUEST,
  DELETE_TAG_SUCCESS,
  DELETE_TAG_FAILED,
  CREATE_TAG_REQUEST,
  CREATE_TAG_SUCCESS,
  CREATE_TAG_FAILED,
  UPDATE_TAG_REQUEST,
  UPDATE_TAG_SUCCESS,
  UPDATE_TAG_FAILED,
  ASSOCIATE_TAG_WITH_COUPON_REQUEST,
  ASSOCIATE_TAG_WITH_COUPON_SUCCESS,
  ASSOCIATE_TAG_WITH_COUPON_FAILED,
  GET_SECRET_KEY_REQUEST,
  GET_SECRET_KEY_SUCCESS,
  GET_SECRET_KEY_FAILED
} from './constants';

const initialState = {
  isLoading: false,
  couponList: {
    loading: true,
    data: [],
    pagination: {}
  },
  createCoupon: {
    loading: false,
    created: false,
    data: {},
    error: {}
  },
  detailCoupon: {
    data: {}
  },
  usageCouponList: {
    loading: true,
    couponId: '',
    data: [],
    pagination: {}
  },
  issueCouponList: {
    loading: true,
    couponId: '',
    data: [],
    pagination: {}
  },
  getCouponTypeSetting: {
    loading: false,
    data: {},
    error: {}
  },
  updateCouponTypeSetting: {
    updated: false,
    loading: false,
    data: {},
    error: {}
  },
  detailImageSize: {
    data: {}
  },
  updateImageSize: {
    loading: false,
    updated: false
  },
  invitationList: {
    loading: true,
    data: {},
    loaded: false
  },
  createInvitationSetting: {
    loading: false,
    data: {},
    created: false
  },
  getIssuedInvitationCodeList: {
    loading: false,
    data: {},
    loaded: false
  },
  updateInvitationStatus: {
    loading: false,
    updated: false
  },
  deleteInvitationSetting: {
    loading: false,
    deleted: false
  },
  detailInvitationCoupon: {
    data: {}
  },
  detailInvitationCouponSetting: {
    data: {}
  },
  detailIncentiveCouponSetting: {
    data: {}
  },
  getTagList: {
    loading: false,
    data: [],
    error: {}
  },
  deleteTag: {
    loading: false,
    deleted: false
  },
  createTag: {
    loading: false,
    created: false,
    error: {}
  },
  updateTag: {
    loading: false,
    updated: false,
    error: {}
  },
  associateTag: {
    loading: false,
    associated: false,
    error: {}
  },
  detailSecretKey: {
    data: {}
  }
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {

    case GET_COUPON_LIST_REQUEST:
      state.couponList = {
        loading: true,
        data: [],
        pagination: {}
      };
      return state;

    case GET_COUPON_LIST_SUCCESS:
      state.couponList = {
        loading: false,
        data: _.get(action, 'payload.payload', [])
      };
      return state;

    case GET_COUPON_LIST_FAILED:
      state.couponList.loading = false;
      return state;
    case CREATE_COUPON_REQUEST:
      state.createCoupon = {
        loading: true,
        created: false
      };
      return state;
    case CREATE_COUPON_SUCCESS:
      state.createCoupon = {
        loading: false,
        created: true
      };
      return state;
    case CREATE_COUPON_FAILED:
      state.createCoupon = {
        loading: false,
        created: false
      };
      return state;
    case UPDATE_COUPON_REQUEST:
      state.createCoupon = {
        loading: true,
        created: false
      };
      return state;
    case UPDATE_COUPON_SUCCESS:
      state.createCoupon = {
        loading: false,
        created: true
      };
      return state;
    case UPDATE_COUPON_FAILED:
      state.createCoupon = {
        loading: false,
        created: false
      };
      return state;
    case COUPON_DELETE_REQUEST:
      state.actionEvent = {loading: true};
      return state;
    case COUPON_UPDATE_STATUS_REQUEST:
      state.actionEvent = {loading: true};
      return state;
    case GET_DETAIL_COUPON_REQUEST:
      state.actionEvent = {loading: true};
      return state;
    case GET_DETAIL_COUPON_SUCCESS:
      state.detailCoupon.data = action.payload;
      state.actionEvent = {loading: false};
      return state;
    case RESET_DETAIL_COUPON:
      state.detailCoupon.data = {};
      return state;
    case GET_DETAIL_USAGE_COUPON_REQUEST:
      state.usageCouponList = {
        loading: true,
        couponId: '',
        data: [],
        pagination: {}
      };
      return state;
    case GET_DETAIL_USAGE_COUPON_SUCCESS:
      state.usageCouponList = {
        loading: false,
        couponId: _.get(action, 'payload.couponId', ''),
        data: _.get(action, 'payload.payload', {})
      };
      return state;
    case GET_DETAIL_ISSUE_COUPON_REQUEST:
      state.issueCouponList = {
        loading: true,
        couponId: '',
        data: [],
        pagination: {}
      };
      return state;
    case GET_DETAIL_ISSUE_COUPON_SUCCESS:
      state.issueCouponList = {
        loading: false,
        couponId: _.get(action, 'payload.couponId', ''),
        data: _.get(action, 'payload.payload', {})
      };
      return state;
    case COUPON_TYPE_SETTING_UPDATE_REQUEST:
      state.updateCouponTypeSetting = {
        loading: true,
        updated: false
      };
      return state;
    case COUPON_TYPE_SETTING_UPDATE_SUCCESS:
      state.updateCouponTypeSetting = {
        loading: false,
        updated: true
      };
      return state;
    case COUPON_TYPE_SETTING_UPDATE_FAILED:
      state.updateCouponTypeSetting = {
        loading: false,
        updated: false,
        error: _.get(action, "payload")
      };
      return state;
    case GET_COUPON_TYPE_SETTING_REQUEST:
      state.getCouponTypeSetting = {
        loading: true
      };
      return state;
    case GET_COUPON_TYPE_SETTING_SUCCESS:
      state.getCouponTypeSetting = {
        loading: false,
        data: _.get(action, "payload")
      };
      return state;
    case GET_COUPON_TYPE_SETTING_FAILED:
      state.getCouponTypeSetting = {
        loading: false,
        error: _.get(action, "payload")
      };
      return state;

    case GET_IMAGE_SIZE_REQUEST:
      state.actionEvent = { loading: true };
      return state;

    case GET_IMAGE_SIZE_SUCCESS:
      state.detailImageSize.data = action.payload;
      state.actionEvent = { loading: false };
      return state;

    case GET_IMAGE_SIZE_FAILED:
      state.actionEvent = { loading: true };
      state.detailImageSize.error = action.playload;
      return state;

    case UPDATE_IMAGE_SIZE_REQUEST:
      state.updateImageSize.loading = true;
      state.updateImageSize.updated = false;
      return state;

    case UPDATE_IMAGE_SIZE_SUCCESS:
      state.updateImageSize.loading = false;
      state.updateImageSize.updated = true;
      return state;

    case UPDATE_IMAGE_SIZE_FAILED:
      state.updateImageSize.loading = false;
      state.updateImageSize.error = _.get(action, 'payload');
      state.updateImageSize.updated = false;
      return state;

    case GET_INVITATION_LIST_REQUEST:
      state.invitationList = {
        loading: true,
        data: {},
        loaded: false
      };
      return state;

    case GET_INVITATION_LIST_SUCCESS:
      state.invitationList = {
        loading: false,
        data: _.get(action, 'payload', {}),
        loaded: true
      };
      return state;

    case GET_INVITATION_LIST_FAILED:
      state.invitationList.loading = false;
      state.invitationList.error = _.get(action, 'payload', {});
      state.invitationList.loaded = true;
      return state;

    case CREATE_INVITATION_SETTING_REQUEST:
      state.createInvitationSetting = {
        loading: true,
        data: {},
        created: false
      };
      state.isCreatedInvitationCouponSetting = false;
      return state;

    case CREATE_INVITATION_SETTING_SUCCESS:
      state.createInvitationSetting = {
        loading: false,
        data: _.get(action, "payload", {}),
        created: true
      };
      state.isCreatedInvitationCouponSetting = true;
      return state;

    case CREATE_INVITATION_SETTING_FAILED:
      state.createInvitationSetting.loading = false;
      state.createInvitationSetting.error = _.get(action, "payload");
      state.createInvitationSetting.created = false;
      state.isCreatedInvitationCouponSetting = false;
      return state;

    case GET_ISSUED_INVITATION_CODE_LIST_REQUEST:
      state.getIssuedInvitationCodeList = {
        loading: true,
        data: {},
        loaded: false
      };
      return state;

    case GET_ISSUED_INVITATION_CODE_LIST_SUCCESS:
      state.getIssuedInvitationCodeList = {
        loading: false,
        data: _.get(action, "payload", {}),
        loaded: true
      };
      return state;

    case GET_ISSUED_INVITATION_CODE_LIST_FAILED:
      state.getIssuedInvitationCodeList.loading = false;
      state.getIssuedInvitationCodeList.error = _.get(action, "payload");
      state.getIssuedInvitationCodeList.loaded = true;
      return state;

    case UPDATE_INVITATION_STATUS_REQUEST:
      state.updateInvitationStatus.loading = true;
      state.updateInvitationStatus.updated = false;
      return state;

    case UPDATE_INVITATION_STATUS_SUCCESS:
      state.updateInvitationStatus.loading = false;
      state.updateInvitationStatus.updated = true;
      return state;

    case UPDATE_INVITATION_STATUS_FAILED:
      state.updateInvitationStatus.loading = false;
      state.updateInvitationStatus.error = _.get(action, "payload");
      state.updateInvitationStatus.updated = false;
      return state;

    case DELETE_INVITATION_SETTING_REQUEST:
      state.deleteInvitationSetting.loading = true;
      state.deleteInvitationSetting.deleted = false;
      return state;

    case DELETE_INVITATION_SETTING_SUCCESS:
      state.deleteInvitationSetting.loading = false;
      state.deleteInvitationSetting.deleted = true;
      return state;

    case DELETE_INVITATION_SETTING_FAILED:
      state.deleteInvitationSetting.loading = false;
      state.deleteInvitationSetting.error = _.get(action, "payload");
      state.deleteInvitationSetting.deleted = false;
      return state;

    case GET_DETAIL_INVITATION_COUPON_REQUEST:
      state.actionEvent = { loading: true };
      return state;

    case GET_DETAIL_INVITATION_COUPON_SUCCESS:
      state.detailInvitationCoupon.data = action.payload;
      state.actionEvent = { loading: false };
      return state;

    case GET_DETAIL_INVITATION_COUPON_FAILED:
      state.actionEvent = { loading: true };
      state.detailInvitationCoupon.error = action.playload;
      return state;

    case RESET_DETAIL_INVITATION_COUPON:
      state.detailInvitationCoupon.data = {};
      return state;
    
    case GET_DETAIL_INVITATION_COUPON_SETTING_REQUEST:
      state.actionEvent = { loading: true };
      return state;

    case GET_DETAIL_INVITATION_COUPON_SETTING_SUCCESS:
      state.detailInvitationCouponSetting.data = _.get(action, "payload");
      state.actionEvent = { loading: false };
      return state;

    case GET_DETAIL_INVITATION_COUPON_SETTING_FAILED:
      state.detailInvitationCouponSetting.error = _.get(action, "payload");
      state.actionEvent = { loading: false };
      return state;

    case GET_DETAIL_INCENTIVE_COUPON_SETTING_REQUEST:
      state.actionEvent = { loading: true };
      return state;

    case GET_DETAIL_INCENTIVE_COUPON_SETTING_SUCCESS:
      state.detailIncentiveCouponSetting.data = _.get(action, "payload");
      state.actionEvent = { loading: false };
      return state;

    case GET_DETAIL_INCENTIVE_COUPON_SETTING_FAILED:
      state.detailIncentiveCouponSetting.error = _.get(action, "payload");
      state.actionEvent = { loading: false };
      return state;

    case RESET_DETAIL_INVITATION_COUPON_SETTING:
      state.detailInvitationCouponSetting.data = {};
      return state;

    case RESET_DETAIL_INCENTIVE_COUPON_SETTING:
      state.detailIncentiveCouponSetting.data = {};
      return state;

    case GET_TAG_LIST_REQUEST:
      state.getTagList = {
        loading: true,
        data: []
      };
      return state;

    case GET_TAG_LIST_SUCCESS:
      state.getTagList = {
        loading: false,
        data: _.get(action, "payload", [])
      };
      return state;

    case GET_TAG_LIST_FAILED:
      state.getTagList = {
        loading: false,
        error: _.get(action, "payload", [])
      };
      return state;

    case DELETE_TAG_REQUEST:
      state.deleteTag = {
        loading: true,
        deleted: false
      };
      return state;

    case DELETE_TAG_SUCCESS:
      state.deleteTag = {
        loading: false,
        deleted: true
      };
      return state;

    case DELETE_TAG_FAILED:
      state.deleteTag.loading = false;
      state.deleteTag.error = _.get(action, "payload");
      state.deleteTag.deleted = false;
      return state;

    case CREATE_TAG_REQUEST:
      state.createTag = {
        loading: false,
        created: true
      };
      return state;

    case CREATE_TAG_SUCCESS:
      state.createTag = {
        loading: false,
        created: true
      };
      return state;

    case CREATE_TAG_FAILED:
      state.createTag.loading = false;
      state.createTag.error = _.get(action, "payload");
      state.createTag.created = false;
      return state;

    case UPDATE_TAG_REQUEST:
      state.updateTag = {
        loading: true,
        updated: false
      };
      return state;

    case UPDATE_TAG_SUCCESS:
      state.updateTag = {
        loading: false,
        updated: true
      };
      return state;

    case UPDATE_TAG_FAILED:
      state.updateTag.loading = false;
      state.updateTag.error = _.get(action, "payload");
      state.updateTag.updated = false;
      return state;

    case ASSOCIATE_TAG_WITH_COUPON_REQUEST:
      state.associateTag = {
        loading: true,
        associated: false
      };
      return state;

    case ASSOCIATE_TAG_WITH_COUPON_SUCCESS:
      state.associateTag.loading = {
        loading: false,
        associated: true
      };
      return state;

    case ASSOCIATE_TAG_WITH_COUPON_FAILED:
      state.associateTag.loading = false;
      state.associatedTag.error = _.get(action, "payload");
      state.associatedTag.associated = false;
      return state;
    
    case GET_SECRET_KEY_REQUEST:
      state.actionEvent = { loading: true };
      return state;

    case GET_SECRET_KEY_SUCCESS:
      state.detailSecretKey.data = action.payload;
      state.actionEvent = { loading: false };
      return state;

    case GET_SECRET_KEY_FAILED:
      state.actionEvent = { loading: false };
      state.detailSecretKey.error = action.payload;
      return state;

    default:
      return state;
  }
});

export default reducer;