import produce from 'immer';
import _ from 'lodash';

import {
  GET_ROLE_LIST_REQUEST,
  GET_ROLE_LIST_SUCCESS,
  GET_PRODUCT_LIST_REQUEST,
  GET_PRODUCT_LIST_SUCCESS,
  SET_ENABLE_SELECT_ROLE
} from './constants';

const initialState = {
  isLoading: false,
  roles: [],
  products: [],
  enableSelectRoles: []
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case GET_ROLE_LIST_REQUEST:
      state.isLoading = true;
      return state;

    case GET_ROLE_LIST_SUCCESS:
      state.isLoading = false;
      state.roles = _.get(action, 'payload', []);
      return state;

    case GET_PRODUCT_LIST_REQUEST:
      state.isLoading = true;
      return state;

    case GET_PRODUCT_LIST_SUCCESS:
      state.isLoading = false;
      state.products = _.get(action, 'payload', []);
      return state;

    case SET_ENABLE_SELECT_ROLE:
      state.enableSelectRoles = _.get(action, 'payload', []);
      return state;

    default:
      return state;
  }
});

export default reducer;