import { createSelector } from 'reselect';

const selectAuth = (state) => state.auth;

export const makeSelectUserLogged = () => createSelector(
  selectAuth,
  (authState) => authState.userLogged
);

export const makeSelectIsLoading = () => createSelector(
  selectAuth,
  (authState) => authState.isLoading
);
