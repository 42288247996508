import { notification } from 'antd';
import { call } from 'redux-saga/effects';
import _get from 'lodash/get';
import i18n from 'i18n';

notification.config({
  placement: 'bottomRight',
  duration: 3
});

const errorNotification = (error) => {
  notification.error({
    message: i18n.t('message.error'),
    description: _get(error, 'error') || error
  });
};

export const safe = (
  saga,
  errorHandler,
  customAlert,
  showAlert = true,
  ...args
) =>
  function* (action) {
    try {
      yield call(saga, ...args, action);
    } catch (err) {
      if (errorHandler) {
        yield call(errorHandler, err);
      }

      if (showAlert) {
        if (err === i18n.t('message.noNetwork')) {
          yield call(errorNotification, err);
        } else {
          yield call(errorNotification, customAlert || err);
        }
      }
    } finally {
      /**/
    }
  };
