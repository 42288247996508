//GET LIST COUPON
export const GET_COUPON_LIST_REQUEST = '@CampaignProvider/GET_COUPON_LIST_REQUEST';
export const GET_COUPON_LIST_SUCCESS = '@CampaignProvider/GET_COUPON_LIST_SUCCESS';
export const GET_COUPON_LIST_FAILED = '@CampaignProvider/GET_COUPON_LIST_FAILED';
//CREATE COUPON
export const CREATE_COUPON_REQUEST = '@CampaignProvider/CREATE_COUPON_REQUEST';
export const CREATE_COUPON_SUCCESS = '@CampaignProvider/CREATE_COUPON_SUCCESS';
export const CREATE_COUPON_FAILED = '@CampaignProvider/CREATE_COUPON_FAILED';
//UPDATE COUPON
export const UPDATE_COUPON_REQUEST = '@CampaignProvider/UPDATE_COUPON_REQUEST';
export const UPDATE_COUPON_SUCCESS = '@CampaignProvider/UPDATE_COUPON_SUCCESS';
export const UPDATE_COUPON_FAILED = '@CampaignProvider/UPDATE_COUPON_FAILED';
//DELETE COUPON
export const COUPON_DELETE_REQUEST = '@CampaignProvider/COUPON_DELETE_REQUEST';
export const COUPON_DELETE_SUCCESS = '@CampaignProvider/COUPON_DELETE_SUCCESS';
export const COUPON_DELETE_FAILED = '@CampaignProvider/COUPON_DELETE_FAILED';
//UPDATE STATUS COUPON
export const COUPON_UPDATE_STATUS_REQUEST = '@CampaignProvider/COUPON_UPDATE_STATUS_REQUEST';
export const COUPON_UPDATE_STATUS_SUCCESS = '@CampaignProvider/COUPON_UPDATE_STATUS_SUCCESS';
export const COUPON_UPDATE_STATUS_FAILED = '@CampaignProvider/COUPON_UPDATE_STATUS_FAILED';

//UPDATE STATUS COUPON
export const GET_DETAIL_COUPON_REQUEST = '@CampaignProvider/GET_DETAIL_COUPON_REQUEST';
export const GET_DETAIL_COUPON_SUCCESS = '@CampaignProvider/GET_DETAIL_COUPON_SUCCESS';
export const RESET_DETAIL_COUPON = '@CampaignProvider/RESET_DETAIL_COUPON';

// TRANSACTION
export const GET_DETAIL_USAGE_COUPON_REQUEST = '@CampaignProvider/GET_DETAIL_USAGE_COUPON_REQUEST';
export const GET_DETAIL_USAGE_COUPON_SUCCESS = '@CampaignProvider/GET_DETAIL_USAGE_COUPON_SUCCESS';

// ISSUE COUPON
export const GET_DETAIL_ISSUE_COUPON_REQUEST = '@CampaignProvider/GET_DETAIL_ISSUE_COUPON_REQUEST';
export const GET_DETAIL_ISSUE_COUPON_SUCCESS = '@CampaignProvider/GET_DETAIL_ISSUE_COUPON_SUCCESS';

// COUPON TYPE
export const GET_COUPON_TYPE_SETTING_REQUEST = '@CampaignProvider/GET_COUPON_TYPE_SETTING_REQUEST';
export const GET_COUPON_TYPE_SETTING_SUCCESS = '@CampaignProvider/GET_COUPON_TYPE_SETTING_SUCCESS';
export const GET_COUPON_TYPE_SETTING_FAILED = '@CampaignProvider/GET_COUPON_TYPE_SETTING_FAILED';

export const COUPON_TYPE_SETTING_UPDATE_REQUEST =
  '@CampaignProvider/COUPON_TYPE_SETTING_UPDATE_REQUEST';
export const COUPON_TYPE_SETTING_UPDATE_SUCCESS =
  '@CampaignProvider/COUPON_TYPE_SETTING_UPDATE_SUCCESS';
export const COUPON_TYPE_SETTING_UPDATE_FAILED =
  '@CampaignProvider/COUPON_TYPE_SETTING_UPDATE_FAILED';

// IMAGE SIZE
export const GET_IMAGE_SIZE_REQUEST = '@CampaignProvider/GET_IMAGE_SIZE_REQUEST';
export const GET_IMAGE_SIZE_SUCCESS = '@CampaignProvider/GET_IMAGE_SIZE_SUCCESS';
export const GET_IMAGE_SIZE_FAILED = '@CampaignProvider/GET_IMAGE_SIZE_FAILED';
export const UPDATE_IMAGE_SIZE_REQUEST = '@CampaignProvider/UPDATE_IMAGE_SIZE_REQUEST';
export const UPDATE_IMAGE_SIZE_SUCCESS = '@CampaignProvider/UPDATE_IMAGE_SIZE_SUCCESS';
export const UPDATE_IMAGE_SIZE_FAILED = '@CampaignProvider/UPDATE_IMAGE_SIZE_FAILED';

// INVITATIONS
export const GET_INVITATION_LIST_REQUEST = '@CampaignProvider/GET_INVITATION_LIST_REQUEST';
export const GET_INVITATION_LIST_SUCCESS = '@CampaignProvider/GET_INVITATION_LIST_SUCCESS';
export const GET_INVITATION_LIST_FAILED = '@CampaignProvider/GET_INVITATION_LIST_FAILED';
export const CREATE_INVITATION_SETTING_REQUEST =
  "@CampaignProvider/CREATE_INVITATION_SETTING_REQUEST";
export const CREATE_INVITATION_SETTING_SUCCESS =
  "@CampaignProvider/CREATE_INVITATION_SETTING_SUCCESS";
export const CREATE_INVITATION_SETTING_FAILED =
  "@CampaignProvider/CREATE_INVITATION_SETTING_FAILED";

export const GET_ISSUED_INVITATION_CODE_LIST_REQUEST =
  "@CampaignProvider/GET_ISSUED_INVITATION_CODE_LIST_REQUEST";
export const GET_ISSUED_INVITATION_CODE_LIST_SUCCESS =
  "@CampaignProvider/GET_ISSUED_INVITATION_CODE_LIST_SUCCESS";
export const GET_ISSUED_INVITATION_CODE_LIST_FAILED =
  "@CampaignProvider/GET_ISSUED_INVITATION_CODE_LIST_FAILED";

export const UPDATE_INVITATION_STATUS_REQUEST =
  "@CampaignProvider/UPDATE_INVITATION_STATUS_REQUEST";
export const UPDATE_INVITATION_STATUS_SUCCESS =
  "@CampaignProvider/UPDATE_INVITATION_STATUS_SUCCESS";
export const UPDATE_INVITATION_STATUS_FAILED = "@CampaignProvider/UPDATE_INVITATION_STATUS_FAILED";

export const DELETE_INVITATION_SETTING_REQUEST =
  "@CampaignProvider/DELETE_INVITATION_SETTING_REQUEST";
export const DELETE_INVITATION_SETTING_SUCCESS =
  "@CampaignProvider/DELETE_INVITATION_SETTING_SUCCESS";
export const DELETE_INVITATION_SETTING_FAILED =
  "@CampaignProvider/DELETE_INVITATION_SETTING_FAILED";

export const GET_DETAIL_INVITATION_COUPON_REQUEST =
  "@CampaignProvider/GET_DETAIL_INVITATION_COUPON_REQUEST";
export const GET_DETAIL_INVITATION_COUPON_SUCCESS =
  "@CampaignProvider/GET_DETAIL_INVITATION_COUPON_SUCCESS";
export const GET_DETAIL_INVITATION_COUPON_FAILED =
  "@CampaignProvider/GET_DETAIL_INVITATION_COUPON_FAILED";

export const RESET_DETAIL_INVITATION_COUPON = "@CampaignProvider/RESET_DETAIL_INVITATION_COUPON";

export const GET_DETAIL_INVITATION_COUPON_SETTING_REQUEST =
  "@CampaignProvider/GET_DETAIL_INVITATION_SETTING_COUPON_REQUEST";
export const GET_DETAIL_INVITATION_COUPON_SETTING_SUCCESS =
  "@CampaignProvider/GET_DETAIL_INVITATION_COUPON_SETTING_SUCCESS";
export const GET_DETAIL_INVITATION_COUPON_SETTING_FAILED =
  "@CampaignProvider/GET_DETAIL_INVITATION_COUPON_SETTING_FAILED";

export const GET_DETAIL_INCENTIVE_COUPON_SETTING_REQUEST =
  "@CampaignProvider/GET_DETAIL_INCENTIVE_COUPON_SETTING_REQUEST";
export const GET_DETAIL_INCENTIVE_COUPON_SETTING_SUCCESS =
  "@CampaignProvider/GET_DETAIL_INCENTIVE_COUPON_SETTING_SUCCESS";
export const GET_DETAIL_INCENTIVE_COUPON_SETTING_FAILED =
  "@CampaignProvider/GET_DETAIL_INCENTIVE_COUPON_SETTING_FAILED";

export const RESET_DETAIL_INVITATION_COUPON_SETTING =
  "@CampaignProvider/RESET_DETAIL_INVITATION_COUPON_SETTING";

export const RESET_DETAIL_INCENTIVE_COUPON_SETTING =
  "@CampaignProvider/RESET_DETAIL_INCENTIVE_COUPON_SETTING";

// COUPON TAG
export const GET_TAG_LIST_REQUEST = "@CampaignProvider/GET_TAG_LIST_REQUEST";
export const GET_TAG_LIST_SUCCESS = "@CampaignProvider/GET_TAG_LIST_SUCCESS";
export const GET_TAG_LIST_FAILED = "@CampaignProvider/GET_TAG_LIST_FAILED";

export const DELETE_TAG_REQUEST = "@CampaignProvider/DELETE_TAG_REQUEST";
export const DELETE_TAG_SUCCESS = "@CampaignProvider/DELETE_TAG_SUCCESS";
export const DELETE_TAG_FAILED = "@CampaignProvider/DELETE_TAG_FAILED";

export const CREATE_TAG_REQUEST = "@CampaignProvider/CREATE_TAG_REQUEST";
export const CREATE_TAG_SUCCESS = "@CampaignProvider/CREATE_TAG_SUCCESS";
export const CREATE_TAG_FAILED = "@CampaignProvider/CREATE_TAG_FAILED";

export const UPDATE_TAG_REQUEST = "@CampaignProvider/UPDATE_TAG_REQUEST";
export const UPDATE_TAG_SUCCESS = "@CampaignProvider/UPDATE_TAG_SUCCESS";
export const UPDATE_TAG_FAILED = "@CampaignProvider/UPDATE_TAG_FAILED";

export const ASSOCIATE_TAG_WITH_COUPON_REQUEST = "@CampaignProvider/ASSOCIATE_TAG_WITH_COUPON_REQUEST";
export const ASSOCIATE_TAG_WITH_COUPON_SUCCESS = "@CampaignProvider/ASSOCIATE_TAG_WITH_COUPON_SUCCESS";
export const ASSOCIATE_TAG_WITH_COUPON_FAILED = "@CampaignProvider/ASSOCIATE_TAG_WITH_COUPON_FAILED";

// SECRET KEY
export const GET_SECRET_KEY_REQUEST = "@CampaignProvider/GET_SECRET_KEY_REQUEST";
export const GET_SECRET_KEY_SUCCESS = "@CampaignProvider/GET_SECRET_KEY_SUCCESS";
export const GET_SECRET_KEY_FAILED = "@CampaignProvider/GET_SECRET_KEY_FAILED";