import produce from 'immer';
import {
  TOGGLE_COLLAPSE_MENU, 
  SET_POPUP, SET_CHANGE_PASSWORD_MODAL,
  SET_CREATE_AGENT_MODAL,
  SET_EDIT_PROFILE_MODAL,
  SET_STORE_DETAIL_MODAL
} from './constants';

const initialState = {
  isLoading: false,
  collapseMenu: false,
  modal: {
    isOpen: false,
    data: {}
  },
  changePasswordModal: false,
  createAgentModal: {
    isOpen: false,
    data: {}
  },
  storeDetailModal: {
    isOpen: false,
    data: {}
  },
  editProfileModal: false
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_COLLAPSE_MENU:
      state.collapseMenu = action.payload;
      return state;

    case SET_POPUP:
      state.modal.isOpen = action.payload.isOpen;
      if (action.payload.data) {
        state.modal.data = action.payload.data;
      }
      return state;

    case SET_CHANGE_PASSWORD_MODAL:
      state.changePasswordModal = action.payload;
      return state;

    case SET_CREATE_AGENT_MODAL: {
      const { payload = {}} = action;
      const { isOpen, data } = payload;
      if (data) {
        state.createAgentModal.data = data;
      }
      state.createAgentModal.isOpen = isOpen;
      return state;
    }

    case SET_EDIT_PROFILE_MODAL:
      state.editProfileModal = action.payload;
      return state;
      
    case SET_STORE_DETAIL_MODAL:
      const { payload = {}} = action;
      const { isOpen, data, index } = payload;
      if (data) {
        state.storeDetailModal.data = data;
      }
      state.storeDetailModal.index = index;
      state.storeDetailModal.isOpen = isOpen;
      return state;

    default:
      return state;
  }
});

export default reducer;