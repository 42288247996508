'use strict'
module.exports = {
    privateKey : '-----BEGIN RSA PRIVATE KEY-----\n' +
        process.env.REACT_APP_COUPON_PRIVATE_KEY+
        '-----END RSA PRIVATE KEY-----\n',
    urlServer: process.env.REACT_APP_COUPON_URL_SERVER,
    appId: process.env.REACT_APP_COUPON_APP_ID,
    passphrase: process.env.REACT_APP_COUPON_PASS_PHRASE,
    expiresIn: process.env.REACT_APP_COUPON_EXPIRES_IN
}
