import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _get from 'lodash/get';
import { Modal } from 'antd';
import { makeSelectModalData } from 'providers/GeneralProvider/selectors';
import { setPopup } from 'providers/GeneralProvider/actions';


const ConfirmModal = () => {
  const dispatch = useDispatch();
  const modalData = useSelector(makeSelectModalData());
  const isOpen = _get(modalData, 'isOpen', false);
  const modalProps = _get(modalData, 'data', {});
  const content = _get(modalData, 'content');
  const { 
    title, okText, cancelText, okType, okButtonProps, onOk, onCancel, closeAfterConfirm = true, ...props 
  } = modalProps;

  const handleCancel = () => {
    if (typeof onCancel === 'function') {
      onCancel();
    }
    dispatch(setPopup({isOpen: false}));
  };

  const handleConfirm = () => {
    if (typeof onOk === 'function') {
      onOk();
    }
    if (closeAfterConfirm) {
      //Close popup first
      dispatch(setPopup({isOpen: false}));
    }
  };

  return (
    <Modal
      title={title}
      visible={isOpen}
      afterClose={() => dispatch(setPopup({ data: {}}))} //Remove popup data when popup is close
      onCancel={handleCancel}
      onOk={handleConfirm}
      cancelText={cancelText}
      okText={okText}
      okType={okType}
      okButtonProps={okButtonProps}
      centered
      {...props}
    >
      {content && content}
    </Modal>
  );
};

export default ConfirmModal;