import React, { createContext } from 'react';

import i18n from 'i18n';

export const LocalizationContext = createContext({
  locale: 'jp',
  setLocale: () => null,
  t: () => ''
});

const LocalizeProvider = ({ children }) => {
  const [locale, setLocale] = React.useState('jp');

  const localizationContext = React.useMemo(
    () => ({
      t: (scope, options) =>
        i18n.t(scope, { locale, ...options }),
      locale,
      setLocale
    }),
    [locale],
  );

  return (
    <LocalizationContext.Provider value={localizationContext}>
      {children}
    </LocalizationContext.Provider>
  );
};

export default LocalizeProvider;
