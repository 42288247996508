import React from 'react';
import { Navigate } from 'react-router-dom';

import Dashboard from 'containers/Dashboard';
import Guest from 'containers/Guest';
import PrivateRoute from './PrivateRoutes';
import PublicRoute from './PublicRoute';
import helper from 'utils/helpers';
import Home from 'containers/Dashboard/Home/Loadable';
import { TargetListSpoolCustomer, TargetListSpoolLog, EmailTemplate, SendSchedule } from 'containers/Dashboard/TargetListSpool';
import { Coupon, PointConfigSecretKey, PointConfigExpiryDuration, PointExchangeRate, PointTransferRate, SearchTotalPoint, SearchPointHistory, TotalPointDaily, CouponTypeSetting, ImageSize, InvitationCoupon, Tag, CouponSettingSecretKey} from 'containers/Dashboard/CampaignManagement';
import { Login, Logout } from 'containers/Auth';
import { UserList, UserApproval, UserInvite, UserProfile, UserSetting, RegisterCouponService, RegisterPointService, RegisterProduct } from 'containers/Dashboard/User';
import { UserRegister, UserUnavailable, AcceptInvite } from 'containers/Guest/User';
import {
  CustomerRecommendation,
  CustomerRecommendationMenu,
  ServicerMatchingScore,
  MenuMatchingScore,
  ServicerSegmentation,
  CustomerSegmentation
} from "containers/Dashboard/AISupportedTool";
import {
  RegisterCouponServiceV1
} from "containers/Dashboard/User";

const RedirectToDashboard = () => {
  return <Navigate to={helper.isGuest() ? '/login' : '/'} replace />;
};

const dashboardRoutes = [
  {
    path: '/', element: <Home />
  },
  {
    path: '/target-list-spool/email-template', element: <EmailTemplate />
  },
  {
    path: '/target-list-spool/:type', element: <TargetListSpoolCustomer />
  },
  {
    path: '/target-list-spool/log', element: <TargetListSpoolLog />
  },
  {
    path: '/target-list-spool/send-schedule', element: <SendSchedule />
  },
  {
    path: '/user/list', element: <UserList />
  },
  {
    path: '/user/approval', element: <UserApproval />
  },
  {
    path: '/user/invite', element: <UserInvite />
  },
  {
    path: '/my-profile', element: <UserProfile />
  },
  {
    path: '/ai-support-tool/personal/servicer-matching-score', element: <ServicerMatchingScore />
  },
  {
    path: '/ai-support-tool/personal/menu-matching-score', element: <MenuMatchingScore />
  },
  {
    path: '/ai-support-tool/personal/customer-recommendation-servicer', element: <CustomerRecommendation />
  },
  {
    path: '/ai-support-tool/personal/customer-recommendation-menu', element: <CustomerRecommendationMenu />
  },
  {
    path: '/ai-support-tool/customer-segmentation', element: <CustomerSegmentation />
  },
  {
    path: '/ai-support-tool/service-segmentation', element: <ServicerSegmentation />
  },
  {
    path: '/logout', element: <Logout />
  },
  {
    path: '/campaign-management/coupon', element: <Coupon />
  },
  {
    path: "/campaign-management/coupon-setting/product-secret-key",
    element: <CouponSettingSecretKey />
  },
  {
    path: '/campaign-management/coupon-setting/image-size',
    element: <ImageSize />
  },
  {
    path: '/campaign-management/coupon-setting/coupon-type',
    element: <CouponTypeSetting />
  },
  {
    path: "/campaign-management/coupon-setting/tag",
    element: <Tag />
  },
  {
    path: '/campaign-management/invitation-coupon', element: <InvitationCoupon />
  },
  {
    path: '/setting', element: <UserSetting />
  },
  {
    path: '/campaign-management/point/point-config/secret-key', element : <PointConfigSecretKey />
  },
  {
    path: 'campaign-management/point/point-config/expiry-duration', element : <PointConfigExpiryDuration />
  },
  {
    path: '/campaign-management/point/point-config/point-rate', element : <PointExchangeRate />
  },
  {
    path: 'campaign-management/point/point-config/transfer-rate', element : <PointTransferRate />
  },
  {
    path: '/campaign-management/point/point-total-search', element : <SearchTotalPoint />
  },
  {
    path: '/campaign-management/point/point-history', element : <SearchPointHistory />
  },
  {
    path: 'campaign-management/point/total-point', element : <TotalPointDaily />
  },
  {
    path: '/register-coupon-service', element: <RegisterCouponService />
  },
  {
    path: '/register-point-service', element: <RegisterPointService />
  },
  {
    path: "/register-coupon-service-v1/:id",
    element: <RegisterCouponServiceV1 />
  },
  {
    path: "/register-point-service",
    element: <RegisterPointService />
  },
  {
    path: "/register-product",
    element: <RegisterProduct />
  }
];

const guestRoutes = [
  {
    path: '/user/register', element: <UserRegister />
  },
  {
    path: '/user/unavailable', element: <UserUnavailable />
  },
  {
    path: '/user/invite', element: <AcceptInvite />
  }
];

const indexRoutes = [
  {
    path: '/login', element: <PublicRoute Component={Login} />
  },
  {
    path: '/login/callback', element: <PublicRoute Component={Login} isAuth0Callback={true} />
  },
  {
    path: '/guest', element: <PublicRoute Component={Guest} />, children: guestRoutes
  },
  {
    path: '/', element: <PrivateRoute Component={Dashboard} />, children: dashboardRoutes
  },
  {
    path: '*', element: <RedirectToDashboard />
  }
];

export default indexRoutes;
