import produce from 'immer';
import _ from 'lodash';

import {
  GET_BIG_QUERY_CREATE_JOB_REQUEST,
  GET_BIG_QUERY_CREATE_JOB_SUCCESS,
  GET_BIG_QUERY_CREATE_JOB_FAILED,
  GET_BIG_QUERY_VIEWS_LIST_REQUEST,
  GET_BIG_QUERY_VIEWS_LIST_SUCCESS,
  GET_BIG_QUERY_VIEWS_LIST_FAILED
} from './constants';

const initialState = {
  createJob: {
    loading: false,
    data: []
  },
  views: {
    loading: false,
    data: []
  },
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {

    case GET_BIG_QUERY_CREATE_JOB_REQUEST:
      state.createJob = {
        loading: true,
        data: [],
        pagination: {}
      };
      return state;

    case GET_BIG_QUERY_CREATE_JOB_SUCCESS:
      state.createJob = {
        loading: false,
        data: _.get(action, 'payload', []),
      };
      return state;

    case GET_BIG_QUERY_CREATE_JOB_FAILED:
      state.createJob.loading = false;
      state.createJob.error = _.get(action, 'payload', {});
      return state;

    case GET_BIG_QUERY_VIEWS_LIST_REQUEST:
      state.views = {
        loading: true,
        data: [],
        pagination: {}
      };
      return state;

    case GET_BIG_QUERY_VIEWS_LIST_SUCCESS:
      state.views = {
        loading: false,
        data: _.get(action, 'payload', []),
      };
      return state;

    case GET_BIG_QUERY_VIEWS_LIST_FAILED:
      state.views.loading = false;
      state.views.error = _.get(action, 'payload', {});
      return state;  
    
    default:
      return state;
  }
});

export default reducer;





