import React from 'react';
import { UserOutlined, SettingOutlined, ToolOutlined } from '@ant-design/icons';
import i18n from 'i18n';
import _ from 'lodash';

import constants from 'utils/constants';

const userMenu = () => {
  return {
    to: '/user',
    text: i18n.t('global.userManager'),
    icon: <UserOutlined />,
    subMenu: [
      {
        to: '/list',
        text: i18n.t('global.userList')
      },
      {
        to: '/invite',
        text: i18n.t('global.inviteUser')
      },
      {
        to: '/approval',
        text: i18n.t('global.userApproval')
      }
    ]
  };
};

const targetListSpoolMenu = (input) => {
  const { productCode, roleCode } = input;
  const subMenu = [
    {
      to: '/email-template',
      text: i18n.t('content.targetListSpool.mailTemplateTab.tabTitle'),
    },
    {
      to: '/send-schedule',
      text: i18n.t('content.targetListSpool.sendSchedule.tab1Title'),
    },
    {
      to: '/customer',
      text: i18n.t('global.customer')
    },
    
  ];

    subMenu.push({
      to: '/servicer',
      text: i18n.t('global.servicer')
    });


  if (roleCode !== constants.ROLE.CODE.VIEWER) {
    subMenu.push({
      to: '/log',
      text: i18n.t('global.targetListSpoolLog')
    });
  }

  return {
    to: '/target-list-spool',
    text: i18n.t('global.targetListSpool'),
    icon: <ToolOutlined />,
    subMenu
  };
};

const aiSupportedToolMenu = () => {
  return {
    to: '/ai-support-tool',
    text: i18n.t('global.aiSupportedTools'),
    icon: <SettingOutlined />,
    subMenu: [
      {
        to: '/similar-image-search',
        text: i18n.t('global.similarImageSearch')
      },
      {
        to: '/personal',
        text: i18n.t('global.personalRecommendation'),
        subMenu: [
          {
            to: '/servicer-matching-score',
            text: i18n.t('global.servicerMatchingScore')
          },
          {
            to: '/menu-matching-score',
            text: i18n.t('global.menuMatchingScore')
          },
          {
            to: '/customer-recommendation-servicer',
            text: i18n.t('global.customerRecommendation')
          },
          {
            to: '/customer-recommendation-menu',
            text: i18n.t('global.customerRecommendationMenu')
          }
        ]
      },
      {
        to: '/customer-segmentation',
        text: i18n.t('global.customerSegmentation')
      },
      {
        to: '/service-segmentation',
        text: i18n.t('global.servicerSegmentation')
      }
    ]
  };
};
const campaignManagementToolMenu = (input) => {
  const { menuSetting, couponTypeSetting, isSuperAdmin, roleCode } = input;
  const subMenu = [];
  const couponMenu = {
    to: '/coupon',
    text: i18n.t('global.coupon')
  };

  const couponSettingMenu = {
    to: "/coupon-setting",
    text: i18n.t("global.couponSetting"),
    subMenu: [
      {
        to: "/product-secret-key",
        text: i18n.t("global.couponSettingSecretKey")
      },
      {
        to: "/image-size",
        text: i18n.t("global.imageSize")
      }
    ]
  };

  if (isSuperAdmin || roleCode === constants.ROLE.CODE.ADMINISTRATOR) {
    couponSettingMenu.subMenu.push(
      {
        to: "/coupon-type",
        text: i18n.t("global.couponType")
      }, 
      {
        to: "/tag",
        text: i18n.t("global.tag")
      }
    );
  }

  const invitationCoupon = {
    to: '/invitation-coupon',
    text: i18n.t('global.invitationCoupon')
  };

  const pointMenu = {
    to: '/point',
    text: i18n.t('global.point'),
    subMenu: [
      {
        to: '/point-config',
        text: i18n.t('global.pointConfig'),
        subMenu: [
          {
            to: '/secret-key',
            text: i18n.t('global.pointConfigSecretKey')
          },
          {
            to: '/expiry-duration',
            text: i18n.t('global.pointConfigExpiryDuration')
          },
          {
            to: '/point-rate',
            text: i18n.t('global.pointExchangeRate')
          },
          {
            to: '/transfer-rate',
            text: i18n.t('global.pointTransferRate')
          }
        ]
      },
      {
        to: '/point-total-search',
        text: i18n.t('global.searchTotalPoint'),
      },
      {
        to: '/point-history',
        text: i18n.t('global.pointHistorySearch')
      },
      {
        to: '/total-point',
        text: i18n.t('global.totalPointDaily')
      }
    ]
  }
  if (_.get(menuSetting, constants.USER_MENU_SETTING.CAMPAIGN + '.coupon', 'off') === 'on' || isSuperAdmin) {
    subMenu.push(couponMenu);
    subMenu.push(couponSettingMenu);
    if (
      _.get(couponTypeSetting, "invitationIncentive", "on") === "on" ||
      isSuperAdmin ||
      roleCode === constants.ROLE.CODE.ADMINISTRATOR
    ) {
      subMenu.push(invitationCoupon);
    }
  }
  if (_.get(menuSetting, constants.USER_MENU_SETTING.CAMPAIGN + '.point', 'off') === 'on' || isSuperAdmin) {
    subMenu.push(pointMenu);
  }
  return {
    to: '/campaign-management',
    text: i18n.t('global.campaignManagement'),
    icon: <SettingOutlined />,
    subMenu: subMenu
  };
};
export const renderSiteMenuBySystemRule = (input) => {

  const { roleCode, productCode, isSuperAdmin, menuSetting, couponTypeSetting } = input;

  const siteMenu = [];
  const adminRoles = [constants.ROLE.CODE.ADMINISTRATOR];  
  const showUserManager = _.get(menuSetting, constants.USER_MENU_SETTING.USER_MANAGER, 'off') === 'on' || isSuperAdmin;
  const showTargetSpool = _.get(menuSetting, constants.USER_MENU_SETTING.TARGET_SPOOL, 'off') === 'on' || isSuperAdmin;
  const showAISupport = _.get(menuSetting, constants.USER_MENU_SETTING.AI_SUPPORT_TOOL, 'off') === 'on' || isSuperAdmin;
  const showCampaign = _.get(menuSetting, constants.USER_MENU_SETTING.CAMPAIGN + '.coupon', 'off') === 'on' 
  || _.get(menuSetting, constants.USER_MENU_SETTING.CAMPAIGN + '.point', 'off') === 'on' 
  || isSuperAdmin;

  if ( (isSuperAdmin || adminRoles.includes(roleCode)) && showUserManager) {
    siteMenu.push(userMenu());
  }

  if (showTargetSpool) {
    siteMenu.push(targetListSpoolMenu({ productCode, roleCode }));
  }

  if (showAISupport) {
    siteMenu.push(aiSupportedToolMenu());
  }

  if (showCampaign) {
    siteMenu.push(campaignManagementToolMenu({ menuSetting, couponTypeSetting, isSuperAdmin, roleCode }));
  }

  return siteMenu;
};