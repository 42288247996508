import {
  GET_ROLE_LIST_REQUEST,
  GET_ROLE_LIST_SUCCESS,
  GET_PRODUCT_LIST_REQUEST,
  GET_PRODUCT_LIST_SUCCESS,
  SET_ENABLE_SELECT_ROLE
} from './constants';

export const getRoleListRequest = () => {
  return { type: GET_ROLE_LIST_REQUEST };
};

export const getRoleListSuccess = (payload) => {
  return { type: GET_ROLE_LIST_SUCCESS, payload };
};

export const getProductListRequest = () => {
  return { type: GET_PRODUCT_LIST_REQUEST };
};

export const getProductListSuccess = (payload) => {
  return { type: GET_PRODUCT_LIST_SUCCESS, payload };
};

export const setEnableSelectRole = (payload) => {
  return { type: SET_ENABLE_SELECT_ROLE, payload };
};