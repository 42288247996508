import React, { forwardRef, memo } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { DatePicker as AntDatePicker, Form } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

import './style.less';

const DatePicker = forwardRef(
  ({
    label,
    size = 'large',
    className = '',
    placeholder = '',
    format = 'YYYY-MM-DD',
    ...props
  }, ref) => {
    const [field, meta, helper] = useField(props);
    const { value } = field;
    const isError = meta.touched && meta.error;
    return (
      <div className={`input-container select-field-container ${className}`}>
        <Form.Item
          label={label}
          validateStatus={isError ? 'error' : ''}
          help={(
            <div className="helper-wrapper">
              <div className="error-text">
                {isError && <><InfoCircleOutlined className="info-icon" type="info-circle" />{meta.error}</>}
              </div>
            </div>
          )}
        >
          <AntDatePicker {...field} {...props}
            size={size}
            ref={ref}
            placeholder={placeholder}
            value={typeof value === 'string' ? moment(value, format) : value}
            onChange={data => helper.setValue(data)}
            format={format}>
          </AntDatePicker>
        </Form.Item>
      </div>
    );
  },
);
DatePicker.displayName = 'DatePicker';
DatePicker.propTypes = {
  label: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  format: PropTypes.string
};
export default memo(DatePicker);