import React from 'react';
import { useRoutes, useLocation, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import i18n from 'i18n';
import indexRoutes from './routes/indexRoutes';
import Modal from 'components/Modal';
import helpers from 'utils/helpers';

const App = () => {
  const element = useRoutes(indexRoutes);
  const location = useLocation();
  const { userLogged, userServiceDomain } = useSelector(state => state.auth);
  document.title = i18n.t('global.dashBoardTitle');

  if (!_.isEmpty(userLogged)) {
    const isDisableRoute = helpers.checkDisableRoute({
      isSuperAdmin: userLogged.is_super_admin,
      roleCode: _.get(userServiceDomain, `${helpers.getProductCode()}.role.role_code`),
      productCode: helpers.getProductCode(),
      path: location.pathname
    });
    if (isDisableRoute) {
      return <Navigate to='/' />;
    }
  }

  return (
    <div className="app-container">
      <Modal />
      {element}
    </div>
  );
};

export default App;