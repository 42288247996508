import _ from 'lodash';
import { put, takeLeading, call } from 'redux-saga/effects';
const Config = require('utils/couponConnection')

import { safe } from 'providers/GeneralProvider/saga';
import request from 'utils/api';
import helper from 'utils/helpers';
import constants from 'utils/constants';
import {
  CHECK_AUTH0_INFO_REQUEST,
  LOGOUT_REQUEST
} from './constants';
import {
  checkAuth0InfoSuccess,
  logoutSuccess,
  logoutFailed
} from './actions';
import helpers from "../../utils/helpers";

export function* handleCheckAuth0Info() {
  const result = yield call(request, 'auth0/login', 'post');

  if (_.get(result, 'payload.status')) {
    if (_.get(result, 'payload.status') === constants.LOGIN_STATUS.LOGGED) {
      helper.removeGuestToken();
      helper.storeAuthToken(_.get(result, 'payload.data.token'));
      helper.storeRefreshToken(_.get(result, 'payload.data.refresh_token'));
      helper.storeUserId(_.get(result, 'payload.data.id'));
    }

    yield put(checkAuth0InfoSuccess(_.get(result, 'payload')));
  }
}

export function* handleLogout(input) {
  const auth0Logout = _.get(input, 'payload.auth0Logout');

  try {
    yield call(request, 'auth0/logout', 'get');
    yield put(logoutSuccess());
  } catch (e) {
    yield put(logoutFailed);
  } finally {
    if (auth0Logout) {
      auth0Logout();
    }
    helper.clearLocalStorage(true);
  }
}

export default function* watchAuth() {

  yield takeLeading(
    CHECK_AUTH0_INFO_REQUEST,
    safe(handleCheckAuth0Info),
  );

  yield takeLeading(
    LOGOUT_REQUEST,
    safe(handleLogout),
  );
}