import i18n from 'i18n-js';
import en from './en';
import ja from './jp';

i18n.defaultLocale = 'en';
i18n.locale = localStorage.getItem('locale') || 'en';
i18n.fallbacks = true;
i18n.translations = { ja, en };
i18n.missingTranslation = (value) => value;
i18n.missingPlaceholder = (value) => value;

export { en, ja };
export default i18n;
